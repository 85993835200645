<template>
  <div class="modal" @click.self="$router.push({name: $router.currentRoute._rawValue.path === '/iniciar-pedido' ? 'home' : 'checkout'})">
    <div class="modal-dialog">
      <span class="close-modal" @click="$router.push({name: $router.currentRoute._rawValue.path === '/iniciar-pedido' ? 'home' : 'checkout'})">X</span>
      <div class="modal-content">
        <h2>Modifica tu hora de entrega</h2>
        <OrderTime
          :availableRestaurants="availableRestaurants" 
          :chosenLocation="0" 
          :receiveOrderTime="receiveOrderTime"
          :scheduledTime="scheduledTime"
          v-model:receiveOrderTime="receiveOrderTime" 
          v-model:scheduledTime="scheduledTime" />
        <span class="continue">
          <button @click="validateHourAndContinue" class="btn btn-br">Modificar hora</button>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import Header from './Header.vue'
import IsAdult from './StartModal/IsAdult.vue'
import OrderTime from './StartModal/OrderTime.vue'
import { apiClient } from '@/services/ApiClient'

export default {
  name: 'TimeChange',
  components: {
    Header,
    IsAdult,
    OrderTime
  },
  data() {
    return {
      availableRestaurants: [],
      chosenLocation: null,
      receiveOrderTime: JSON.parse(localStorage.getItem('orderTime')) === null ? 'now' : 'scheduled',
      scheduledTime: null,
      deliveryAddress: JSON.parse(localStorage.getItem('deliveryAddress')),
      shippingMethod: JSON.parse(localStorage.getItem('shippingMethod'))
    }
  },
  methods: {
    ...mapActions('Cart', ['saveConfig', 'getTimeToChange']),
    validateHourAndContinue() {
      this.saveConfig(['orderTime', this.scheduledTime])
      if (this.scheduledTime) {
        this.getTimeToChange(this.scheduledTime)
      }
      this.$router.push({
        name: 'checkout',
        hashbag: true
      })
    },
    getAvailableRestaurants([shippingMethod, lat, lng ]) {
      return new Promise((resolve, reject) => {
        apiClient.post(`/restaurant/search-${shippingMethod}`, {
          "latitude": lat,
          "longitude": lng
        })
        .then(response => {
          resolve(response.data)
        })
        .catch(err => {
          reject(err.response.data.errors)
        })
      })
    },
  },
  mounted() {
    this.getAvailableRestaurants([this.shippingMethod, this.deliveryAddress.coordinates.lat, this.deliveryAddress.coordinates.lng])
      .then(data => this.shippingMethod === 'delivery' ? this.availableRestaurants.push(data) : this.availableRestaurants = data)
      .catch(err => { throw err })
  }
}
</script>

<style scoped lang="scss">

.modal {
  h2 {
    padding: 0 30px 30px 0;
    margin-top: 60px;
    margin-bottom: 0;
    font-family: $font__headings;
    text-transform: uppercase;
    text-align: unset;
  }

  h3 {
    font-size: $fs-xs-medium;
    font-weight: $fw-bold;
    position: relative;
    margin: 0;

    span {
      font-weight: normal;
    }
  }

  .modal-dialog {
    min-width: 50vw;
    .modal-header {
      height: 60px;
    }
    .modal-content {
      background-color: $c-white;
      padding: 30px;
      padding-bottom: 80px;
      height: calc(100vh - 110px);
      overflow-y: auto;
      overflow-x: hidden;

      .continue {
        display: flex;
        justify-content: flex-end;
      }
    }
  }

  @include tablet {
    padding: 1rem;

    h2 {
      margin-top: 0;
    }

    .modal-dialog {
      .modal-header {
        display: none;
      }

      .modal-content {
        height: 340px;
        padding-bottom: 30px;

        .location__wrapper {
          flex-direction: row;
          flex-wrap: nowrap;

          input {
            width: 65%;
            margin-right: 20px;
          }
        }
      }
    }
  }
}
</style>

<style lang="scss">
.select2-container {
  padding-left: 15px;
  margin: 5px;
  width: 160px !important;

  .select2-dropdown {
    margin-left: 10px;
  }

  .select2-selection {
    width: 160px;
    
  }
  .select2-selection__arrow {
    width: 0 !important;
  }

  @include tablet {
    width: 250px !important;

    .select2-selection {
    width: 250px;
  }
  }
}
</style>
