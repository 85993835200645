<template>
  <div class="footer-modal" @click.self="closeModal">
    <div class="footer-modal-dialog">
		<Cart></Cart>
    </div>
  </div>
</template>

<script>
import Cart from "./Cart.vue";

export default {
	name: 'CartModal',
	components: {
		Cart,
	},
	methods: {
		closeModal() {
			this.$router.push({name: 'catalog'})
		},
	},
}
</script>

<style scoped lang="scss">

.footer-modal {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 80px;
	background-color: rgba(0, 0, 0, 0.6);
	-webkit-transition: all .7s;
	transition: all .7s;
	display: block;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	transition: all 0.3s ease-out;
	z-index: 300;
	overflow: hidden;
}
.footer-modal-dialog {
	position: absolute;
	bottom: 0;
	cursor: default;
	width: 100%;
	background-color: $c-white;
	animation: showModal .5s ease-out;

	@include tablet {
		max-width: 400px;
		right: 80px;
	}
}
@keyframes showModal {
	0%{
		transform: translateY(100%)
	}
	100% {
		transform: translateY(0%);
	}
}
</style>