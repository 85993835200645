<template>
  <div class="modal" @click.self="closeModal" :class="{'close_modal': isProductSelected}">
    <div class="modal-dialog">
      <span class="close-modal" @click="closeModal" :class="{'close_modal': isProductSelected}">X</span>   
      <div class="header-wrapper">
      <h2>Selecciona un producto</h2>
      </div>  
      <div v-for="(products, idx) in productsToChoose" :key="idx" class="catalog-product">
        <div v-for="product in products" :key="product.plu" class="catalog-product-box">
          <div class="text">
            <h3>{{product.name}}</h3>
            <button @click="getBackToCheckout(product.id)" class="btn">Aplicar</button>
          </div>
        </div>
      </div>
      <div @click="closeModal" class="link">
				<span>Cancelar</span>
		</div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
export default {
  name: 'ProductsToChoose',
  props: {
    successCouponMsg: {
      type: String,
      default: 'Código válido'
    }
  },
  data() {
    return {
      isProductSelected: false,
    }
  },
  computed: {
    ...mapState('Cart', ['productsToChoose', 'coupons', 'currentCoupon', 'wasChoosed'])
  },
  methods: {
    ...mapMutations('Cart', ['WAS_PRODUCT_CHOOSED']),
    ...mapActions('Cart', ['getProductPluToDiscount', 'addCouponToCart', 'checkProductsCart']),
    getBackToCheckout(id) {
      this.getProductPluToDiscount(id)
      this.addCouponToCart({ code: this.currentCoupon})
      this.coupons.forEach((coupon) => {
        this.$gtag.event('select_promotion', { promotion_id: coupon.code })
      })
      this.$emit('hasProductsToChoose')
      this.isProductSelected = true
      // this.$emit('successCouponMsg', this.successCouponMsg = 'Código válido')
    },
    closeModal() {
      this.$emit('hasProductsToChoose')
      this.isProductSelected = true
      this.WAS_PRODUCT_CHOOSED(false)
      this.checkProductsCart()
    }
  },
}
</script>

<style scoped lang="scss">
.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  -webkit-transition: all .7s;
  transition: all .7s;
  display: block;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.35s ease-in;
  z-index: 300;
  overflow: hidden;

  .modal-dialog {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: default;
    height: 100vh;
    width: 100%;
    background-image: url("../assets/bg-texture.jpg");
    overflow: unset;

    .header-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 120px;
      padding: 0 20px;
    }


    h2 {
      font-family: $font__headings;
      font-size: $fs-l-meduim;
      text-align: center;
      text-transform: uppercase;
      margin-top: 50px;
      margin-bottom: 40px;
    }

    .catalog-product {
      cursor: default;
      display: flex;
      flex-direction: row;
      justify-content: center;
      flex-wrap: wrap;
      // gap: 40px;
      font-size: 0;
      margin: 0;
      padding: 0 20px;
    }

    .catalog-product-box {
      background-color: $c-white;
      border-radius: 0;
      display: flex;
      flex-direction: column;
      column-gap: 1rem;
      display: flex;
      margin: 0 20px;
      max-width: 550px;
      width: 100%;
      
      img {
        border-radius: 8px;
        object-fit: cover;
        width: 150px;
      }

      .text {
        display: flex;
        align-items: center;
        padding: 15px 15px 12px;
        border-bottom: 1px solid #f2f2f2;
        width: 100%;
      }

      p {
        font-size: $fs-m-small;
        font-family: $font__main;
        margin-top: 10px;
        margin-right: 10px;
        flex-grow: 2;
        max-height: 4em;
        overflow: hidden;
      }

      h3 {
        font-family: $font__main_bold;
        text-transform: uppercase;
        margin: 0;
        font-size: $fs-l-small;
        width: 100%;
      }
      
      .btn {
        background-color: $c-black;
        cursor: pointer;
        display: block;
        font-size: $fs-m-small;
        margin-left: 10px;
        padding: 5px 10px;
        width: fit-content;
      }
    }
    .link{
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 15px 0;
  
      span {
        font-size: $fs-s-small;
        color: $c-black;
        margin-left: 5px;
        text-decoration: underline;
      }
    }
  }

   @include tablet {
    .modal-dialog {
      min-width: 50vw;
      max-width: 30vw;
      height: unset;
      width: unset;
      position: relative;

    .close-modal {
      display: inline-block;
      font-family: $font__headings;
      font-size: $fs-l-large;
      color: $c-white;
      cursor: pointer;
      position: absolute;
      top: $fs-l-large * -1.5;
      right: 0;
    }

    .header-wrapper {
      flex-direction: row;
      justify-content: space-around;
      margin: 0;
      margin-top: 50px;
      margin-bottom: 10px;

      h2 {
        margin: 0;
      }
    }

    .catalog-product {
      flex-direction: column;
      align-items: center;
      padding: 30px 40px;
      padding-bottom: 30px;
    }

    .catalog-product-box {
      margin: 0 auto;
      background-color: $c-white;
      max-width: 500px;
      .text {
        p {
          text-align: start;
        }
      }
    }

    .btn {
      &.footer {
        max-width: 280px;
      }
    }
    }
  }
}
.close_modal {
    display: none !important;
  }
</style>