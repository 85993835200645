<template>
  <div class="container">
    <h1>Política de cookies</h1>
    <p>
      Una cookie es un fichero que se descarga en tu ordenador al acceder a determinadas páginas web. Las cookies permiten a una página web, entre otras cosas, 
      almacenar y recuperar información sobre los hábitos de navegación de un usuario o de su equipo y, dependiendo de la información que contengan y de 
      la forma en que utilice tu equipo, pueden utilizarse para reconocer al usuario.
    </p>
    <p>
      GOIKO utiliza cookies para facilitar la navegación por su web, por motivos de seguridad y para obtener una mayor eficacia y personalización de los servicios 
      ofrecidos a los usuarios. La información que obtiene está relacionada con el número de páginas visitas, el idioma, red social en la que se publican nuestras 
      noticias, la ubicación geográfica asignada a la dirección IP desde la que accede, el número de nuevos usuarios, la frecuencia y reincidencia de las visitas, 
      el tiempo de visita, el navegador y el operador o tipo de terminal desde el que se realiza la visita
    </p>
    <p>
      Esta información la utilizamos para mejorar nuestras páginas, detectar nuevas necesidades y valorar las mejoras a introducir con la finalidad de prestar un mejor 
      servicio a los usuarios que nos visitan adaptándolas, por ejemplo, a los navegadores o terminales más utilizados.
    </p>
    <p>
      Permiten mejorar la calidad y seguridad de la página web. Poseen una fecha de caducidad, que puede oscilar desde el tiempo que dure la sesión, hasta una fecha futura 
      especificada a partir de la cual dejan de ser operativas.
    </p>
    <p>
      GOIKO hace uso de cookies propias, así como de terceros. Algunas estrictamente necesarias, para el correcto funcionamiento de esta página y analíticas, 
      para mostrarte información de tu interés. 
    </p>
    <p>En cualquier momento puedes cambiar o retirar tu consentimiento desde la Declaración de cookies, por medio del enlace “Cambiar su consentimiento” en nuestro sitio web.</p>
    <p>Para más información relacionada con el tratamiento de sus datos de carácter personal, puede consultar nuestra Política de <a href="#">Política de Cookies</a>.</p>
    <p>Esta página web usa cookies.</p>
    <p>Utilizamos cookies propias y de terceros para fines analíticos y para mostrarte información de tu interés. Pincha en <a href="#">Política de Cookies</a> para más información.</p>
    <p>
      Puedes aceptar todas las cookies pulsando el botón “Aceptar” o rechazar su uso pulsando el botón "Rechazar todas las cookies". Si quieres configurarlas, en la <a href="#">Política de Cookies</a> 
      te indicamos cómo hacerlo en diferentes navegadores.
    </p>
    <p>
      Una cookie es un fichero que se descarga en tu ordenador al acceder a determinadas páginas web. Las cookies permiten a una página web, entre otras cosas, almacenar y recuperar información 
      sobre los hábitos de navegación de un usuario o de su equipo y, dependiendo de la información que contengan y de la forma en que utilice tu equipo, pueden utilizarse para reconocer 
      al usuario.
    </p>
    <p>
      GOIKO utiliza cookies para facilitar la navegación por su web, por motivos de seguridad y para obtener una mayor eficacia y personalización de los servicios ofrecidos a los usuarios. 
      La información que obtiene está relacionada con el número de páginas visitas, el idioma, red social en la que se publican nuestras noticias, la ubicación geográfica asignada a la dirección 
      IP desde la que accede, el número de nuevos usuarios, la frecuencia y reincidencia de las visitas, el tiempo de visita, el navegador y el operador o tipo de terminal desde el que se realiza 
      la visita.
    </p>
    <p>
      Esta información la utilizamos para mejorar nuestras páginas, detectar nuevas necesidades y valorar las mejoras a introducir con la finalidad de prestar un mejor servicio a los usuarios que nos visitan adaptándolas, por ejemplo, 
      a los navegadores o terminales más utilizados.
    </p>
    <p>
      Permiten mejorar la calidad y seguridad de la página web. Poseen una fecha de caducidad, que puede oscilar desde el tiempo que dure la sesión, hasta una fecha futura especificada a partir 
      de la cual dejan de ser operativas.
    </p>
    <p>
      GOIKO hace uso de cookies propias, así como de terceros. Algunas estrictamente necesarias, para el correcto funcionamiento de esta página y analíticas, para mostrarte información de tu 
      interés.
    </p>
    <p>En cualquier momento puedes cambiar o retirar tu consentimiento desde la Declaración de cookies, por medio del enlace “Cambiar su consentimiento” en nuestro sitio web.</p>
    <p>Para más información relacionada con el tratamiento de sus datos de carácter personal, puede consultar nuestra Política de protección de datos.</p>
    <p>Su consentimiento se aplica a los siguientes dominios: www.goiko.com</p>
    <p>
      Tu estado actual: Permitir todas.
      <br>
      ID de tu consentimiento: 
      <br>
      07bEjqn410CdOca3jkeeFZwDjQmFSVEcmA9n/pa41geZm2z8w14tQQ==
      <br>
      Fecha del consentimiento: lunes, 3 de octubre de 2022, 16:56:38 CEST
    </p>
    <a href="">Cambiar tu consentimiento</a> | <a href="">Retirar tu consentimiento</a>

    <!-- scrip de cookies aquí -->
  </div>
  <BodyFooter />
</template>

<script>
import BodyFooter from '../../components/BodyFooter.vue'
export default {
  name: 'LegalNoti',
  components: {
    BodyFooter
  },
  mounted() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }
}
</script>

<style scoped lang="scss">
  .container {
    font-size: 26px;
    margin: 60px auto 150px auto;
    max-width: 1200px;
    padding: 0 30px;
    h1 {
      font-family: $font__headings;
      text-transform: uppercase;
      text-align: center;
    }
    a {
      color: $c-red;
    }
  }
</style>