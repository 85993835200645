<template>
  <div class="footer-modal" @click.self="$emit('toggleCart')">
    <div class="footer-modal-dialog">
      <Cart @toggleCart="$emit('toggleCart')"></Cart>
    </div>
  </div>
</template>


<script>
import { mapGetters } from 'vuex';
import Cart from "./Cart.vue";

export default {
	name: 'CartModal',
	components: {
		Cart,
	},
	computed: {
		...mapGetters('Cart', ['cartItemCount'])
	}
}
</script>

<style scoped lang="scss">

.footer-modal {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.6);
	-webkit-transition: all .7s;
	transition: all .7s;
	display: block;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	transition: all 0.3s ease-out;
	overflow: auto;
	z-index: 1;

	@include tablet {
		display: none;
	}
}
.footer-modal-dialog {
	position: absolute;
	bottom: 0;
	cursor: default;
	width: 100%;
	background-color: $c-white;
	animation: showModal .5s ease-out;

	.container-btn {
		display: flex;
		justify-content: center;
		align-items: flex-start;
		height: 10%;
		padding-bottom: 30px;

		.btn {
			max-width: 300px;
			width: 60%;
		}
	}

	.container {
		min-height: 35vh;
		max-height: 47vh;
	}
}
@keyframes showModal {
	0%{
		transform: translateY(100%)
	}
	100% {
		transform: translateY(0%);
	}
}

</style>
