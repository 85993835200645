<template>
  <div class="confirmation">
    <div class="confirmation-mobile-wrapper">
      <div class="hero">
        <h1>HA OCURRIDO UN ERROR :-(</h1>
        <p>Ha ocurrido un error procesando tu pago.</p>
      </div>
    </div>
    <div class="confirmation-desktop-wrapper">
      <h1>HA OCURRIDO UN ERROR :-(</h1>
      <p>Ha ocurrido un error procesando tu pago.</p>
    </div>
  </div>  
</template>

<script>
export default {
  name: 'FailedPayment',
  data() {
    return {

    }
  },
}
</script>

<style scoped lang="scss">
h1 {
  font-family: $font__headings;
  font-size: $fs-m-medium;
  // margin: 0 0 30px 0;
  @include tablet {
    font-size: $fs-xxl-large;
  }
}

.confirmation {
  height: 100vh;
  color: $c-white;
}

.confirmation-mobile-wrapper {
  .hero {
    // margin-top: -80px;
    height: 70vh;
    background-image: url('../assets/PatatasExtraRicas_Carrousel.png');
    background-size: cover;
    background-position: center;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    p {
      font-family: $font__main;
      font-weight: $fw-bold;
      max-width: 80vw;
      text-align: center;
    }
  }
  @include tablet {
    display: none;
  }
}

.order {
  background-color: $c-black;
  padding: 20px;
  a {
    display: flex;
    justify-content: center;
    margin: 30px;
    text-decoration: none;
  }
  .products-wrapper {
    border: 1px solid $c-white;
    border-radius: 6px;
    padding: 15px;
    margin-bottom: 30px;
    .products-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .articles {
        display: flex;
        align-items: center;
        svg {
          margin-right: 10px;
        }
        p {
          font-family: $font__main;
          font-weight: $fw-bold;
          margin: 0;
        }
        span {
          font-family: $font__main;
          font-size: $fs-s-small;
        }
      }
    }
  }
  @include tablet {
    display: none;
  }
}

.delivery-wrapper {
  display: flex;
  justify-content: space-between;
  .delivery {
    display: flex;
    align-items: center;
    svg {
      min-width: 40px;
    }
    p {
      font-size: $fs-s-small;
      margin: 0;
    }
    span {
      font-size: $fs-s-small;
    }
  }
  @include tablet {
     p {
      font-size: $fs-m-small;
      margin: 0;
    }
    span {
      font-size: $fs-m-small;
    }
  }
}

.confirmation-desktop-wrapper {
  display: none;
  @include tablet {
    display: block;
    height: 100vh;
    background-image: url('../assets/PatatasExtraRicas_Carrousel.png');
    background-size: cover;
    background-position: center;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h2 {
      margin-bottom: 70px;
    }
    p {
      max-width: 50vw;
      text-align: center;
      font-size: $fs-xl-medium;
    }
  }
}
.delivery-desktop-wrapper {
  display: none;
  @include tablet {
    display: block;
    margin-top: 70px;
    background-color: rgba(255, 255, 255, 0.7);
    .delivery-header {
      background-color: $c-black;
      display: flex;
      flex-wrap: wrap;
      gap: 0 20px;
      justify-content: space-between;
      align-items: center;
      padding: 20px;
      min-width: 700px;
      p {
        margin: 0;
        font-weight: $fw-bold;
        font-size: $fs-m-small;
      }
      span {
        display: flex;
        align-items: center;
        gap: 0 8px;
        cursor: pointer;
        a {
          text-decoration: underline;
          font-weight: $fw-regular;
          font-size: $fs-m-small;
        }
      }
    }
    .delivery-wrapper {
      display: flex;
      justify-content: space-around;
      padding: 20px;
      .delivery {
        div {
          color: $c-black;
          p {
            text-align: left;
          }
        }
      }
    }
    .help {
      display: flex;
      justify-content: center;
      margin-bottom: 20px;
      text-decoration: none;
      .btn {
        border: 1px solid $c-black;
      }
    }
  }
}

</style>
