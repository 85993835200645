import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import AOS from 'aos'
import VueGoogleMaps from '@fawmi/vue-google-maps'
import { createI18n } from 'vue-i18n/index'
import VueGtag from "vue-gtag"
import es from './i18n/es.json'
import 'aos/dist/aos.css'

const i18n = createI18n({
	locale: 'es',
	messages: { es },
})
const app = createApp(App)

AOS.init()

app.use(store)
app.use(router)
app.use(i18n)
app.use(VueGoogleMaps, {
	load: {
		key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
		libraries: "places"
	},
})
app.use(VueGtag, {
	appName: "GOIKO Pedidos",
	pageTrackerScreenviewEnabled: true,
	config: {
			id: "G-T2M77RR2DF"
	},
	includes: [
		{
			id: "AW-927558373"
		}
	],
	router
})

app.config.globalProperties.$filters = {
	dateESP(timestamp) {
		const date = new Date(timestamp * 1000)
		return date.toLocaleString()
	},
	formatEUR(amount) {
		let options = {style: 'decimal', minimumFractionDigits: 2}

		const intl = new Intl.NumberFormat('es-ES', options)
		const pricesNumbers = intl.format(amount / 100)

		if (pricesNumbers.includes(',00')) {
			return pricesNumbers.substr(0, pricesNumbers.length - 3);
		} else if ('0' === pricesNumbers.substr(pricesNumbers.length - 1, 1)) {
			return pricesNumbers.substr(0, pricesNumbers.length - 1);
		} else {
			return pricesNumbers;
		}
	}
}

app.mount('#app')
 
export default app;
