<template>
<div class="modal" @click.self="closeModal">
    <div class="modal-dialog">
        <div class="modal-header__wrapper">
          <svg @click="$router.push({name: 'home'})" class="logo-goiko" :class="color" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="0 0 765.07 414.4" style="enable-background:new 0 0 765.07 414.4;" xml:space="preserve">
            <g>
              <path class="logo-goiko-st0" d="M174.28,172.77h16.31c4.17,0,7.55-3.38,7.55-7.55v-12.99c0-17.11-4.18-30.25-12.53-39.42
                c-8.36-9.16-20.69-13.74-37-13.74s-28.65,4.58-37,13.74c-8.36,9.16-12.54,22.3-12.54,39.42v109.94c0,17.12,4.18,30.26,12.54,39.42
                c8.35,9.17,20.69,13.74,37,13.74s28.64-4.58,37-13.74c8.35-9.16,12.53-22.3,12.53-39.42v-59.5c0-4.17-3.38-7.55-7.55-7.55h-31.41
                c-4.17,0-7.55,3.38-7.55,7.55v15.1c0,4.17,3.38,7.55,7.55,7.55s7.55,3.38,7.55,7.55v31.61c0,7.69-1.56,13.05-4.68,16.09
                c-3.12,3.04-7.3,4.55-12.54,4.55c-5.24,0-9.41-1.51-12.53-4.54c-3.12-3.03-4.69-8.37-4.69-16.04V150.16
                c0-7.66,1.56-13.06,4.69-16.19c3.12-3.12,7.29-4.69,12.53-4.69c5.23,0,9.41,1.56,12.54,4.68c3.12,3.12,4.68,8.51,4.68,16.16v15.1
                C166.73,169.39,170.11,172.77,174.28,172.77z"/>
              <path class="logo-goiko-st0" d="M241.94,112.81c-8.66,9.16-12.99,22.3-12.99,39.42v109.94c0,17.12,4.33,30.26,12.99,39.42
                c8.65,9.17,21.14,13.74,37.45,13.74c16.31,0,28.8-4.58,37.46-13.74c8.65-9.16,12.99-22.3,12.99-39.42V152.23
                c0-17.11-4.33-30.25-12.99-39.42c-8.66-9.16-21.14-13.74-37.46-13.74C263.08,99.07,250.6,103.65,241.94,112.81z M296.61,150.11
                v114.17c0,7.66-1.56,13.04-4.68,16.16c-3.12,3.12-7.3,4.68-12.54,4.68c-5.24,0-9.41-1.56-12.53-4.68
                c-3.12-3.12-4.69-8.5-4.69-16.16V150.11c0-7.65,1.56-13.04,4.69-16.16c3.12-3.12,7.29-4.68,12.53-4.68c5.23,0,9.41,1.56,12.54,4.68
                C295.05,137.08,296.61,142.46,296.61,150.11z"/>
              <path class="logo-goiko-st0" d="M370.91,101.49c-4.17,0-7.55,3.38-7.55,7.55v196.33c0,4.17,3.38,7.55,7.55,7.55h18.12
                c4.17,0,7.55-3.38,7.55-7.55V109.04c0-4.17-3.38-7.55-7.55-7.55H370.91z"/>
              <path class="logo-goiko-st0" d="M458.49,101.49h-18.12c-4.17,0-7.55,3.38-7.55,7.55v196.33c0,4.17,3.38,7.55,7.55,7.55h18.12
                c4.17,0,7.55-3.38,7.55-7.55v-55.51c0-1.24,0.3-2.45,0.88-3.54l1.61-3.03c3.04-5.72,11.4-5.21,13.72,0.84l24.52,63.94
                c1.12,2.92,3.92,4.85,7.05,4.85h17.84c5.33,0,8.98-5.37,7.02-10.32l-40.92-103.77c-0.78-1.98-0.69-4.19,0.24-6.09l39.41-80.37
                c2.46-5.02-1.19-10.88-6.78-10.88h-16.31c-2.93,0-5.59,1.69-6.84,4.35l-40.85,87.17h-0.6v-83.97
                C466.05,104.87,462.66,101.49,458.49,101.49z"/>
              <path class="logo-goiko-st0" d="M578.11,112.81c-8.66,9.16-12.99,22.3-12.99,39.42v109.94c0,17.12,4.33,30.26,12.99,39.42
                c8.65,9.17,21.14,13.74,37.45,13.74s28.8-4.58,37.46-13.74c8.65-9.16,12.99-22.3,12.99-39.42V152.23
                c0-17.11-4.33-30.25-12.99-39.42c-8.66-9.16-21.14-13.74-37.46-13.74S586.76,103.65,578.11,112.81z M632.77,150.11v114.17
                c0,7.66-1.56,13.04-4.68,16.16c-3.12,3.12-7.3,4.68-12.54,4.68c-5.24,0-9.41-1.56-12.53-4.68c-3.12-3.12-4.69-8.5-4.69-16.16
                V150.11c0-7.65,1.56-13.04,4.69-16.16c3.12-3.12,7.29-4.68,12.53-4.68c5.23,0,9.41,1.56,12.54,4.68
                C631.21,137.08,632.77,142.46,632.77,150.11z"/>
            </g>
          </svg>
        </div>
        
        <div class="wrapper-box">
          <div class="modal-container">
            <h1>Confirma tu cuenta</h1>
            <p>Revista tu correo, te hemos enviado un código para confirmar tu cuenta.</p>
            <form @submit.prevent="registerPasswordCode">
              <div class="form__code">
                <input
                  v-for="(input, idx) in Array(inputQuantity).fill('')"
                  class="form__field-input"
                  :key="`input_${idx + 1}`"
                  :value="form[`code${idx + 1}`]"
                  :ref="`input_${idx + 1}`"
                  @input="updateValue($event, idx + 1)"
                  @paste="pasteCode(idx + 1)"
                  type="text"
                  required
                  maxlength="1"
                > 
              </div>
              <div class="error-code">
                <p v-if="errors">Por favor, introduce un código válido</p>
              </div>
              <button class="btn" type="submit">Enviar</button>
            </form>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import Logo from './Logo.vue'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'RegisterConfirmation',
  components: {
    Logo
  },

  data() {
    return {
      inputQuantity: 6,
      form: {
        code1: '',
        code2: '',
        code3: '',
        code4: '',
        code5: '',
        code6: ''
      },
      code: '',
      errors: false,
      isLoading: true,
      arrInput: []
    }
  },

  methods: {
    ...mapActions('Auth', ['registerCode']),
    ...mapActions('User', ['fetchProfile']),
    ...mapState('Auth', ['temporalToken']),

    updateValue(event, inputOrder) {
      const value = event.target.value;
      const nextInput = value ? inputOrder + 1 : inputOrder - 1

      if (nextInput > 0 && nextInput <= this.inputQuantity) {
        this.$refs[`input_${nextInput}`][0].focus()
      }

      this.form[`code${inputOrder}`] = value
      this.code = Object.values(this.form).join('')
    },

    async pasteCode(inputOrder) {
      const code = await navigator.clipboard.readText()
        if (code.length === this.inputQuantity) {
          Array(this.inputQuantity).fill('').forEach((value, idx) => {
            this.form[`code${idx + 1}`] = code.slice(idx, idx + 1)
          })
        } else {
          this.errors = true
          this.form[`code${inputOrder}`] = ''
        }
    },

    async registerPasswordCode() {
      if (this.code.length === this.inputQuantity) {
        try {
          await this.registerCode(this.code)
          await this.fetchProfile()
          if (this.$route.name == 'register-confirmation-checkout') {
            this.$router.push({name: 'checkout'})
          } else {
            this.$router.push({name: 'home'})
          }
        } catch (errors) {
          this.errors = true
        }
      }
    }
  },
}
</script>

<style scoped lang="scss">

.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  // background-color: $c-white;
  -webkit-transition: all .7s;
  transition: all .7s;
  display: block;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.35s ease-in;
  z-index: 301;
  overflow: hidden;

  .modal-dialog {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: default;
    height: 100vh;
    max-width: 100vw;
    width: 100%;
    background-image: url("../assets/bg-texture.jpg");

    .modal-header__wrapper {
      text-align: center;
      padding: 30px 0;
      width: 100%;
    }

    .modal-container {
      background-color: $c-white;
      border-radius: 20px;
      text-align: center;
      margin: 0 auto;
      padding: 15px 0;
      max-width: 76%;
      width: 100%;

      h1 {
        font-family: $font__headings;
        font-size: $fs-m-medium;
      }

      p {
       font-size:$fs-m-small;
       padding: 10px 20px;
      }

      /* Chrome, Safari, Edge, Opera */
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      input[type=number] {
        -moz-appearance: textfield;
      }

      .form__code {
        padding: 0 20px;
        
        .form__field-input {
          border: 1px solid $c-red;
          color: $c-red;
          height: 35px;
          font-size: $fs-l-small;
          font-weight: 700;
          width: 35px;
          margin: 5px;
          padding: 5px 10px;
          outline: none;
          text-align: center;
    
          &:not(:last-child) {
            margin-right: 10px;
          }
        }
      }


      button {
        background-color: $c-red;
        border-radius: 20px;
        border: 0;
        color: $c-white;
        max-width: 250px;
        margin-top: 25px;
        margin-bottom: 35px;
        padding: 10px;
        transition: all .3s;
        width: 50%;
        
        &:hover {
          background-color: $c-black;
        }
      }

      .error-code {
        font-size: 14px;
        margin: 0;
        color: $c-red;
        text-align: center;
      }
    }

    @include tablet {
      .wrapper-box {
        display: flex;
        height: 100%;
        justify-content: center;
        align-items: center;

      .modal-container {
        padding: 15px;
        max-width: 55%;

        h1 {
          font-size: $fs-l-large;
        }

        p {
          font-size: $fs-xs-medium;
        }

        button {
          font-size: $fs-l-small;
        }

        .form__field-input {
          width: 35px;
          height: 35px;
          font-size: $fs-xs-medium;
        }
      }
    }
    }
  }
  .logo-goiko {
    height: 90px;
  }
}
</style>