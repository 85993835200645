import { apiClient } from '@/services/ApiClient'

const state = {
  user: null,
  ordersHistory: [],
  detailedOrder: null,
}

const actions = {
  fetchProfile({ commit }) {
    if (localStorage.getItem('refreshToken')) {
      return new Promise((resolve, reject) => {
        apiClient.get('/user')
        .then(response => {
            commit('SET_USER_INFO', response.data)
            resolve(response.data)
        })
        .catch(err => reject(err.response.data))
      })
    }
  },

  async updateUserInfo({ commit }, newUserInfo) {
    await apiClient.patch('/user', newUserInfo)
      .then(() => commit('UPDATE_USER_INFO', newUserInfo))
      .catch(err => {
        throw err.response.data.errors
      })
  },

  getOrdersHistoryFromUser({ commit }) {
    return new Promise((resolve, reject) => {
      apiClient.get('/user/orders')
        .then(response => {
          commit('SET_ORDERS_HISTORY', response.data)
          resolve(response.data)
        })
        .catch(err => reject(err.response.data))
      })
  },

  getOrderById({commit}, id) {
    return new Promise((resolve, reject) => {
      apiClient.get(`/user/orders/${id}`)
        .then(response => {
          commit('ORDER_ID', response.data)
          resolve(response.data)
        })
        .catch(err => reject(err.response))
    })
  }
}

const mutations = {
  SET_USER_INFO(state, info) {
    state.user = info
  },

  UPDATE_USER_INFO(state, updatedInfo) {
    const newUserInfo = Object.keys(updatedInfo)
    newUserInfo.forEach(info => state.user[info] = updatedInfo[info])
  },

  RESET_USER(state) {
    state.user = null
  },

  SET_ORDERS_HISTORY(state, ordersHistory) {
    state.ordersHistory = ordersHistory
  },

  ORDER_ID(state, orderById) {
    state.detailedOrder = orderById
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
