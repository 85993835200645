const forceTwoDigitNumbers = (value) => {
return (value < 10 ? '0' : '') + value
}

const formatDate = (timestamp) => {
  const hour = forceTwoDigitNumbers(new Date(timestamp * 1000).getHours())
  const minutes = forceTwoDigitNumbers(new Date(timestamp * 1000).getMinutes())
  const day = forceTwoDigitNumbers(new Date(timestamp * 1000).getDate())
  const monthJS = new Date(timestamp * 1000).getMonth()
  const monthString = forceTwoDigitNumbers(monthJS + 1)

  if (window.location.pathname === '/pago-exitoso' || window.location.pathname === '/finalizar-compra') {
    return `Listo a las ${hour}:${minutes} del día ${day} de ${formatMonth(monthJS)}`
  } else {
    return `${day}/${monthString} a las ${hour}:${minutes}`
  }
}

const formatMonth = (month) => {
const monthYear = ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre']
monthYear.forEach((eachMonth, idx) => idx == month ? month = eachMonth : null)
return month
}

const setOrderStatusMessage = (status, isDelivery) => {
  let statusValue = { 
    text: '', 
    message: '',
    class: '' 
  }

  switch (status) {
    case 'FINALIZED':
      statusValue = { 
        text: 'Entregado', 
        message: isDelivery ? 'Hemos entregado tu pedido.' : 'Tu pedido está listo para la recogida.',
        class: 'delivered' 
      }
      break;

    case 'ACCEPTED':
      statusValue = { 
        text: 'Preparando', 
        message: 'Estamos preparando tu pedido, muy pronto estará listo.',
        class: 'in-process' 
      }
      break;

    case 'SCHEDULED':
      statusValue = { 
        text: 'Programado', 
        message: 'Hemos programado tu pedido para su preparación.',
        class: 'in-process' 
      }
      break;

    case 'IN_ROUTE_TO_DROPOFF':
      statusValue = { 
        text: 'En reparto', 
        message: 'Tu pedido está en reparto, muy pronto estará en tus manos.',
        class: 'in-process' 
      }
      break;

    case 'ARRIVE_AT_DROPOFF':
      statusValue = { 
        text: 'En reparto', 
        message: 'Tu pedido está muy cerca, pendiente en el telefonillo.',
        class: 'in-process' 
      }
      break;
  
    default:
      statusValue = { 
        text: 'Preparando', 
        message: 'Estamos preparando tu pedido, muy pronto estará listo.',
        class: 'in-process' 
      }
      break;
  }

  return statusValue
}

const getPreparingOrder = (orderStatus) => {
  const preparingStatusArr = ['ACCEPTED', 'PREPARING', 'IN_DELIVERY', 'NEW', 'PRINTED', 'IN_ROUTE_TO_PICKUP', 'IN_ROUTE_TO_DROPOFF', 'DELIVERECT_PARSED', 
  'POS_RECEIVED', 'SENT_TO_DMA', 'BEFORE_DELIVERECT_PARSE', 'POS_RECEIPT_NOT_FOUND_YET', 'RECEIVED_BY_DMA']
  return preparingStatusArr.includes(orderStatus)
}

export {
  formatDate,
  formatMonth,
  forceTwoDigitNumbers,
  setOrderStatusMessage,
  getPreparingOrder
}
