<template>
  <Header
    color="white"
    colorBg="black"
  ></Header>
  <router-view></router-view>
</template>

<script>
import Header from './components/Header.vue'

export default {
  name: 'App',
  components: {
    Header,
  },
}
</script>

<style lang="scss">
@import 'scss/styles';
body {
  overflow-x: hidden;
}
</style>
