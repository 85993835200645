<template>
  <template v-if="isLoading">
    <span class="spinner"></span>
  </template>
  <template v-else>
    <div v-if="orderToken" class="confirmation-wrapper">
      <div class="hero">
        <h1>¡TODO LISTO!</h1>
        <div class="status">
          <p>Estado del pedido</p>
          <span>{{setOrderStatusMessage(orderToken.status, orderToken.isDelivery)?.message}}</span>
          <div v-if="isOrderFinalized(orderToken.status)" class="lds-ripple"><div></div><div></div></div>
        </div>
      </div>
      <div class="order-wrapper">
        <div class="products-wrapper">
          <div class="products-header">
            <div @click="toggleModal" class="articles">
              <svg xmlns="http://www.w3.org/2000/svg" width="27" height="25" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 54 54" style="enable-background:new 0 0 54 54;" xml:space="preserve" fill="black" stroke="white">
                <g><g>
                <path fill="white" d="M27,0C12.112,0,0,12.112,0,27s12.112,27,27,27s27-12.112,27-27S41.888,0,27,0z M27,52C13.215,52,2,40.785,2,27    S13.215,2,27,2s25,11.215,25,25S40.785,52,27,52z"/>
                <path fill="white" d="M27.707,18.935c-0.391-0.391-1.023-0.391-1.414,0l-10.5,10.5c-0.391,0.391-0.391,1.023,0,1.414s1.023,0.391,1.414,0    L27,21.056l9.793,9.793c0.195,0.195,0.451,0.293,0.707,0.293s0.512-0.098,0.707-0.293c0.391-0.391,0.391-1.023,0-1.414    L27.707,18.935z"/>
                </g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g>
              </svg>
              <div>
                <p>Pedido #{{ orderToken.numOrder }}</p>
                <span>Ver detalle del pedido</span>
              </div>
            </div>
            <p>{{$filters.formatEUR(orderToken.total, true)}}</p>
          </div>
        </div>
        <div class="delivery-header">
          <p>Tu pedido tiene el número: {{ orderToken.numOrder }}</p>
          <span v-if="!isModalOpen" @click="toggleModal">
            <a>Ver detalle del pedido</a>
            <svg width="7" height="13" viewBox="0 0 7 13" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.68963 13L-3.55155e-08 12.1875L5.44444 6.49783L-5.32733e-07 0.8125L0.689629 -3.01447e-08L7 6.49783L0.68963 13Z" fill="white"/>
            </svg>
          </span>
        </div>
        <div class="delivery-wrapper">
          <div class="delivery">
            <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 2C14.131 2 16 3.73 16 5.702C16 7.752 14.286 10.643 12 14.263C9.714 10.643 8 7.752 8 5.702C8 3.73 9.869 2 12 2ZM12 0C8.852 0 6 2.553 6 5.702C6 8.85 8.602 12.609 12 18C15.398 12.609 18 8.85 18 5.702C18 2.553 15.149 0 12 0ZM12 8C10.895 8 10 7.105 10 6C10 4.895 10.895 4 12 4C13.105 4 14 4.895 14 6C14 7.105 13.105 8 12 8ZM20 14H16.865C16.48 14.641 16.067 15.309 15.633 16H18.764L19.264 17H15L14.656 17.544L14.367 18H14.925L15.783 20H8.295L9.153 18H9.632L9.343 17.544L9 17H6.958L5.947 16H8.367C7.932 15.309 7.519 14.641 7.135 14H4L0 22H24L20 14ZM7.206 20H3.236L5 16.472L6.516 18H8.065L7.206 20ZM16.014 18H19.764L20.764 20H16.872L16.014 18Z" fill="#EE2737"/>
            </svg>
            <div>
              <p>Se entregará en</p>
              <span v-if="!orderToken.isDelivery">{{orderToken.restaurantAddress}}</span>
              <span v-else>
                <template v-if="orderToken.deliveryAddress">
                {{orderToken.deliveryAddress.street}},
                {{orderToken.deliveryAddress.streetNumber}}
                {{orderToken.deliveryAddress.postalCode}},
                {{orderToken.deliveryAddress.city}}
                </template>
              </span>
            </div>
          </div>
          <div class="delivery">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15.91 13.34L18.546 9.314L18.092 8.908L14.419 12.007C13.744 11.869 13.017 12.075 12.525 12.625C11.789 13.448 11.86 14.713 12.684 15.449C13.508 16.185 14.772 16.114 15.508 15.29C16 14.74 16.123 13.995 15.91 13.34ZM12 2.694V0H16V2.694C14.561 2.451 13.408 2.456 12 2.694ZM20.851 4.758L22.258 3.351L23.672 4.765L22.351 6.086C21.889 5.602 21.387 5.159 20.851 4.758ZM2 9H10V11H2V9ZM0 13H8V15H0V13ZM3 17H10V19H3V17ZM24 14C24 19.523 19.523 24 14 24C11.21 24 8.7 22.845 6.889 21H10.169C11.307 21.631 12.608 22 14 22C18.411 22 22 18.411 22 14C22 9.589 18.411 6 14 6C12.608 6 11.307 6.369 10.169 7H6.889C8.7 5.155 11.21 4 14 4C19.523 4 24 8.477 24 14Z" fill="#EE2737"/>
            </svg>
            <div>
              <p>Lo antes posible</p>
              <span>{{formatDate(orderToken.estimatedAt)}}</span>
            </div>
          </div>
        </div>
        <div class="delivery-wrapper">
          <span class="help" :class="{is_take_away: !orderToken.isDelivery}" @click="toggleHelpModal">
            <button class="btn black">¿Necesitas ayuda?</button>
          </span>
        </div>
      </div>
    </div>
    <div v-if="isModalOpen" @click.self="toggleModal" class="modal">
      <OrderDetail :order="orderToken" v-if="isModalOpen" @toggleModal="isModalOpen = !isModalOpen" />
    </div>
    <div class="modal" @click.self="toggleHelpModal" :class="{hidden_modal: !isHelpModalOpen}">
      <div class="modal-dialogo">
        <div class="close-modal" @click="toggleHelpModal">X</div>
        <h1>¿Necesitas ayuda?</h1>
        <p class="description">Si tienes cualquier duda, consulta o problema en relación a tu pedido puedes ponerte en contacto a través de tu plataforma preferida:</p>
        <div class="wrapper">
          <a href="mailto:dimedime@goiko.com" class="container">
            <svg xmlns="http://www.w3.org/2000/svg" width="34" height="26" viewBox="0 0 34 26" fill="none">
              <path d="M0 0.25V25.75H34V0.25H0ZM30.4838 3.08333L17 14.0101L3.51617 3.08333H30.4838ZM2.83333 22.9167V6.17592L17 17.6566L31.1667 6.17592V22.9167H2.83333Z" fill="#EE2737"/>
            </svg>
            <p>Correo electrónico</p>
            <strong>dimedime@goiko.com</strong>
          </a>
          <a href="https://wa.me/34602220309" class="container">
            <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34" fill="none">
              <path d="M0.0805664 34L2.47048 25.2691C0.995733 22.7134 0.220816 19.8163 0.222233 16.8456C0.226483 7.55792 7.7844 0 17.0707 0C21.5771 0.00141667 25.8072 1.75667 28.9891 4.94133C32.1695 8.126 33.9205 12.359 33.9191 16.8612C33.9148 26.1502 26.3569 33.7082 17.0707 33.7082C14.2515 33.7067 11.4734 32.9998 9.01265 31.6568L0.0805664 34ZM9.42632 28.6068C11.8006 30.0163 14.0673 30.8607 17.065 30.8621C24.783 30.8621 31.0702 24.5806 31.0744 16.8583C31.0772 9.1205 24.8198 2.8475 17.0763 2.84467C9.35265 2.84467 3.06973 9.12617 3.0669 16.847C3.06548 19.9991 3.98915 22.3592 5.5404 24.8285L4.12515 29.9965L9.42632 28.6068ZM25.5579 20.8661C25.4531 20.6904 25.1726 20.5856 24.7504 20.3745C24.3297 20.1634 22.2599 19.1448 21.8731 19.0046C21.4878 18.8643 21.2073 18.7935 20.9254 19.2157C20.6449 19.6364 19.8374 20.5856 19.5923 20.8661C19.3472 21.1466 19.1007 21.182 18.68 20.9709C18.2592 20.7598 16.9021 20.3164 15.2941 18.8813C14.0432 17.765 13.1975 16.3866 12.9524 15.9644C12.7073 15.5437 12.9269 15.3156 13.1366 15.1059C13.3264 14.9175 13.5573 14.6143 13.7684 14.3678C13.9823 14.1242 14.0517 13.9485 14.1934 13.6666C14.3337 13.3861 14.2642 13.1396 14.158 12.9285C14.0517 12.7188 13.2102 10.6462 12.8603 9.80333C12.5175 8.98308 12.1704 9.09358 11.9126 9.08083L11.1051 9.06667C10.8246 9.06667 10.3684 9.1715 9.98307 9.59367C9.59773 10.0158 8.50973 11.033 8.50973 13.1056C8.50973 15.1782 10.0185 17.1799 10.2281 17.4604C10.4392 17.7409 13.1961 21.9937 17.4191 23.817C18.4236 24.2505 19.2084 24.5097 19.819 24.7038C20.8277 25.024 21.7457 24.9787 22.471 24.871C23.2799 24.7506 24.9615 23.8524 25.3128 22.8692C25.6642 21.8847 25.6642 21.0417 25.5579 20.8661Z" fill="#EE2737"/>
            </svg>
            <p>WhatsApp</p>
            <strong class="phone-number">(+34) 602 22 03 09</strong>
          </a>
        </div>
        <span @click="toggleHelpModal" class="back__order-detail" v-if="$route.name == 'confirmation'">
          <svg xmlns="http://www.w3.org/2000/svg" width="8" height="14" viewBox="0 0 8 14" fill="none">
            <path d="M6.53579 0.109817L7.21116 0.934203L1.66847 6.5282L7.01308 12.3075L6.30941 13.1078L0.113147 6.50111L6.53579 0.109817Z" fill="black"/>
          </svg>
          <a>Volver al resumen del pedido</a>
        </span>
      </div>
    </div>
  </template>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { formatDate, setOrderStatusMessage } from '../commonMethods/methods'
import OrderDetail from '../components/OrderDetail.vue'

export default {
  name: 'Confirmation',
  components: {
    OrderDetail
  },

  data() {
    return {
      isModalOpen: false,
      isHelpModalOpen: false,
      interval: null,
      isLoading: true,
    }
  },
  computed: {
    ...mapGetters('Cart', ['getRestaurantInfo', 'cartTotalPrice']),
    ...mapState('Cart', ['orderToken']),
  },
  methods: {
    ...mapActions('Cart', ['getOrderByToken']),
    ...mapMutations('Cart', ['RESET_ORDER_INFO_STORAGE']),
    formatDate, setOrderStatusMessage,

    toggleModal() {
      this.isModalOpen = !this.isModalOpen
    },
    toggleHelpModal() {
      this.isHelpModalOpen = !this.isHelpModalOpen
    },
    isOrderFinalized(status) {
      return !(status === 'FINALIZED' || status === 'PREPARED')
    },
  },

  beforeMount() {
    this.getOrderByToken(this.$route.query.token)
      .then(data => {
        if (!this.$route.query.hasOwnProperty('notracking')) {
          let orderItems = []
          data.products.forEach(product => orderItems.push({
            item_id: product.plu,
            item_name: product.name
          }))
          this.$gtag.event('purchase', {
            currency: 'EUR',
            transaction_id: data.numOrder,
            value: data.total / 100,
            items: orderItems
          })
          this.$gtag.event('conversion', {
            send_to: 'AW-927558373/f1_fCO7k084DEOXVpboD',
            value: data.total / 100,
            currency: 'EUR',
            transaction_id: data.numOrder
          })

          this.$router.push({ name: 'confirmation', query: { token: this.$route.query.token, notracking: '1' } })
        }
      })
    this.RESET_ORDER_INFO_STORAGE()
  },

  mounted() {
    this.isLoading = false
    this.interval = setInterval(() => {
      this.getOrderByToken(this.$route.query.token)
        .then(data => {
          if (!this.isOrderFinalized(data.status)) {
            clearInterval(this.interval)
          }
        })
    }, 10000)
  },

  beforeUnmount() {
    clearInterval(this.interval)
  },
}
</script>

<style scoped lang="scss">
h1 {
  font-family: $font__headings;
  font-size: $fs-m-medium;
  margin: 0;
  @include tablet {
    font-size: $fs-xxl-large;
  }
}

.modal {
  .cart-wrapper {
    position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
  }

  
  @include tablet {
    &.close_modal {
      display: none;
    }

    .cart-wrapper {
    top: 16%;
    width: 65vh;
    bottom: 70px;
    margin: 0 auto;
    max-height: unset;
    padding: 0 25px;
  }

  .close-modal {
      display: inline-block;
      font-family: $font__headings;
      font-size: $fs-l-large;
      color: $c-white;
      cursor: pointer;
      position: absolute;
      top: -40px;
      right: 0;
    }
  }
}

.confirmation-wrapper {
  color: $c-white;
  position: relative;
}
.hero {
  background-image: url('../assets/home-image.jpg');
  background-size: cover;
  background-position: center;
  height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  p {
    max-width: 80%;
    font-size: $fs-m-small;
  }

  .paragraph {
    margin-top: 40px;
  }

  .status {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    p {
      font-size: $fs-xs-medium;
      font-weight: $fw-bold;
      margin: 0;
      margin-top: 30px;
    }
    span {
      font-size: $fs-m-small;
      padding: 0 25px;
    }

    .lds-ripple {
      display: inline-block;
      position: relative;
      width: 40px;
      height: 40px;
      margin-left: -25px;
    }
    .lds-ripple div {
      position: absolute;
      border: 4px solid #fff;
      opacity: 1;
      border-radius: 50%;
      animation: lds-ripple 1.5s cubic-bezier(0, 0.2, 0.8, 1) infinite;
    }
    .lds-ripple div:nth-child(2) {
      animation-delay: -.5s;
    }
    @keyframes lds-ripple {
      0% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 0;
      }
      4.9% {
        top: 0px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 0;
      }
      5% {
        top: 38px;
        left: 38px;
        width: 0;
        height: 0;
        opacity: 1;
      }
      100% {
        top: 18px;
        left: 18px;
        width: 40px;
        height: 40px;
        opacity: 0;
      }
    }
  }

  @include tablet {
    height: calc(100vh - 60px);
    display: block;
    padding-top: 80px;
    text-align: center;
    p {
      font-size: $fs-xl-medium;
      margin: auto;
    }
    .status {
      p {
        font-size: $fs-s-medium;
      }
      span {
        font-size: $fs-xs-medium;
      }
    }
  }
  @include desktop {
    height: calc(100vh - 70px);
  }
}


.order-wrapper {
  background-color: $c-black;
  padding: 30px;
  .delivery-header {
    display: none;
  }
  @include tablet {
    position: absolute;
    bottom: 40px;
    right: 0;
    left: 0;
    width: 684px;
    margin: auto;
    background-color: rgba(255, 255, 255, 0.7);
    padding: 0;
    .delivery-header {
      background-color: $c-black;
      display: flex;
      flex-wrap: wrap;
      gap: 0 20px;
      justify-content: space-between;
      align-items: center;
      padding: 20px;
      p {
        margin: 0;
        font-weight: $fw-bold;
        font-size: $fs-m-small;
      }
      span {
        display: flex;
        align-items: center;
        gap: 0 8px;
        cursor: pointer;
        a {
          text-decoration: underline;
          font-weight: $fw-regular;
          font-size: $fs-m-small;
        }
      }
    }
  }
}

.products-wrapper {
  border: 1px solid $c-white;
  border-radius: 6px;
  padding: 15px;
  margin-bottom: 30px;
  .products-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .articles {
      cursor: pointer;
      display: flex;
      align-items: center;
      svg {
        margin-right: 10px;
      }
      p {
        font-family: $font__main;
        font-weight: $fw-bold;
        margin: 0;
      }
      span {
        font-family: $font__main;
        font-size: $fs-s-small;
      }
    }
  }
  @include tablet {
    display: none;
  }
}

.delivery-wrapper {
  display: flex;
  justify-content: space-between;
  padding-bottom: 30px;

  .help {
    text-decoration: none;

    &.is_take_away {
      margin: 0 auto;
    }
    .btn {
      border: 1px solid $c-white;
      padding: 17px;
    }
  }

  .delivery {
    display: flex;
    align-items: center;
    margin-right: 20px;

    svg {
      min-width: 40px;
    }
    p {
      font-size: $fs-s-small;
      font-weight: $fw-bold;
      margin: 0;
    }
    span {
      font-size: $fs-s-small;
    }
  }
  @include tablet {
    padding: 30px;
    .delivery-header {
      display: block;
      background-color: $c-black;
    }
    p {
      font-size: $fs-m-small;
      margin: 0;
      color: $c-black;
    }
    span {
      font-size: $fs-m-small;
      color: $c-black;
    }

    .help {
      .btn {
        border: 1px solid $c-black;
        // margin-right: 80px;
      }
    }
  }
}


.modal {
    &.hidden_modal {
      display: none;
    }
}

.modal-dialogo {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: default;
  height: 100vh;
  width: 100%;
  background-color: #FFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1 {
    font-size: $fs-l-meduim;
    text-transform: uppercase;
  }

  .description {
    font-size: $fs-m-small;
    margin: 30px 0;
    max-width: 380px;
    text-align: center;
  }

  .wrapper {
    display: flex;
    justify-content: center;
    width: 100%;

    .container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 0 20px;
      text-decoration: none;
      color: black;

      p {
        font-size: $fs-s-small;
        color: $c-gray;
      }

      strong {
        font-size: $fs-m-small;
      }

      .phone-number {
        margin-bottom: 5px;
      }
    }
  }

  .back__order-detail {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-left: 20px;
    width: 100%;

    a {
      font-size: 12px;
      color: #000;
      margin-left: 5px;
      text-decoration: underline
    }
}

  @include tablet {
      min-width: 200px;
      max-width: 600px;
      max-height: 45vh;
      background-color: $c-white;
      position: relative;

      .back__order-detail {
        display: none;
      }
  }
}
</style>
