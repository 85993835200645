import axios from 'axios'
import store from '../store'
import router from '@/router'

class ApiClient {
  constructor (baseUrl, timeOut) {
    this.client = axios.create({
      baseURL: baseUrl,
      timeout: timeOut
    })
  }

  get (endpoint) {
    return this.request('get', endpoint, {})
  }

  post (endpoint, payload) {
    return this.request('post', endpoint, payload)
  }

  patch (endpoint, payload) {
    return this.request('patch', endpoint, payload)
  }

  delete (endpoint) {
    return this.request('delete', endpoint, {})
  }

  request(method, endpoint, payload) {
    let options = {
      ...this.requestOptions(),
      method: method,
      url: endpoint
    }

    if (payload) {
      options.data = payload
    }

    return new Promise((resolve, reject) => {
      this.client.request(options)
        .then(response => resolve(response))
        .catch(err => {
          if (410 === err.response.status) {
            this.refreshToken()
              .then(response => {
                store.commit('Auth/DO_LOGIN', response.data)
                resolve(this.request(method, endpoint, payload))
              })
              .catch(errRefresh => {
                store.commit('Auth/DO_LOGOUT')
                router.push({ name: 'login' })
                reject(errRefresh)
              })
          } else if (401 === err.response.status) {
            store.commit('Auth/DO_LOGOUT')
            router.push({ name: 'login' })
            reject(err)
          } else if (403 === err.response.status) {
            store.commit('Auth/DO_LOGOUT')
            router.push({ name: 'login' })
            reject(err)
          } else {
            reject(err)
          }
        })
    })
  }

  refreshToken() {
    return this.client.post('refresh-token', {refresh_token: localStorage.getItem('refreshToken')}, {headers: {'Content-Type': 'application/json'}})
  }

  requestOptions () {
    let requestOptions = {}
    const accessToken = localStorage.getItem('accessToken')
    if (accessToken) {
      requestOptions = { headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + accessToken } }
    }

    return requestOptions
  }
}

const HTTP_ERRORS = {
    NO_CONNECTION: 'no_connection',
    NO_RESPONSE: 'no_response'
}

const apiClient = new ApiClient(process.env.VUE_APP_API_BASE_URI, process.env.VUE_APP_API_TIMEOUT)
export { apiClient, HTTP_ERRORS }