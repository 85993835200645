import { apiClient } from '@/services/ApiClient'
import router from '@/router'

const state = {
  temporalToken: null,
  newTemporalToken: null
}

const initialState = () => ({
  temporalToken: null,
  newTemporalToken: null
})

const actions = {
  logout({ commit }) {
    commit('DO_LOGOUT')
    commit('User/RESET_USER', null, {root: true})
      router.push({ name: 'login' })
  },
  async login({ commit }, userLogin) {
    commit('DO_LOGOUT')
    return new Promise((resolve, reject) => {
      apiClient.post('/login', userLogin)
      .then(response => {
        resolve(response.data)
      })
      .catch(err => {
        reject(err.response.data.errors)
      })
    })
  },
  async refresh({ commit }) {
    return new Promise((resolve, reject) => {
      apiClient.refreshToken()
        .then(response => {
          commit('DO_LOGIN', response.data)
          resolve(response.data)
        })
        .catch(err => {
          commit('DO_LOGOUT')
          reject(err.response.data)
        })
    })
  },
  async signup({ commit }, userRegister) {
    commit('DO_LOGOUT')
    await apiClient.post('/signup', userRegister)
    .then(response => {
      commit('DO_REGISTER', response.data.temporalToken)
    })
    .catch(err => {
      throw err.response.data.errors
    })
  },
  async registerCode({ commit, state }, confirmationCode) {
    const infoCode = {
      temporalToken: state.temporalToken,
      code: confirmationCode
    }
    await apiClient.post('/signup/confirmation', infoCode)
    .then(response => {
      commit('DO_LOGIN', response.data)
    })
    .catch(err => {
      throw err.response.data.errors
    })
  },

  // async resendCode({commit, state}) {
  //   const newTemporalToken = {
  //     temporalToken: state.newTemporalToken
  //   }
  //   await apiClient.post('/signup/resend-code', newTemporalToken)
  //     .then(response => {
  //       commit('RESEND_CODE', response)
  //     })
  //     .catch(err => err.response.data)
  // },

  async recover({ commit }, userEmail) {
    await apiClient.post('/recover-password', userEmail)
    .then(response => {
      commit('DO_REGISTER', response.data.temporalToken)
    })
    .catch(err => {
      throw err.response.data.errors
    })
  },
  async recoverConfirmation({ commit, state }, recoverInfo) {
    const info = {
      temporalToken: state.temporalToken,
      code: recoverInfo.code,
      password: recoverInfo.password
    }
    await apiClient.post('/recover-password/confirmation', info)
    .then(response => {
      commit('DO_LOGIN', response.data)
    })
    .catch(err => {
      throw err.response.data.errors
    })
  },

  async getGoikoId({ commit }, userData) {
    try {
      const { data } = await apiClient.post('/signup/goikoId', userData)
      return data
    } catch (error) {
      throw error
    }
  }
}

const mutations = {
  DO_LOGIN(state, response) {
    localStorage.setItem('accessToken', response.accessToken)
    localStorage.setItem('refreshToken', response.refreshToken)
  },
  DO_REGISTER(state, temporalToken) {
    state.temporalToken = temporalToken
  },
  DO_LOGOUT(state) {
    const newState = initialState()
    localStorage.removeItem('accessToken')
    localStorage.removeItem('refreshToken')
    Object.keys(newState).forEach(key => {
      state[key] = newState[key]
    })
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
