<template>
  <template v-if="$route.name !== 'confirmation'">
    <div 
      v-if="$route.name !== 'checkout'" 
      class="user_button__wrapper" 
      :class="{'dropdown-border': dropdownMenu && innerWidth < 768}" 
      :style="{backgroundColor: dropdownMenu ? '' : 'black'}"
      @click="toggleLogin"
      >
      <div class="content">
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
          viewBox="0 0 280 263.3" style="enable-background:new 0 0 280 263.3; fill: white;" xml:space="preserve">
        <path d="M105.3,135.6c-5.6-7.4-10.1-15-13.2-23.4c-5.3-14.4-7.6-29.2-4.5-44.4c5.7-27.6,27.4-45.9,55.8-44.5
          c23.3,1.1,43.3,18.8,48.6,42.4c4.1,18.4,1,35.9-6.8,52.7c-2.8,6-6.5,11.6-9.9,17.5c2.5,1.2,5.4,2.5,8.3,3.9
          c12,5.9,24.1,11.6,36,17.8c17.2,8.9,25.2,23.9,26.6,42.6c0.8,10.5-2.3,20.1-12.4,25.4c-5.1,2.7-10.7,4.7-16.2,6.3
          c-18.7,5.3-37.9,7.6-57.2,8.1c-16.1,0.4-32.3,0.3-48.4-0.5c-18.6-1-37.2-3-54.9-9.4c-5.6-2-10.9-5.2-15.9-8.6
          c-2.1-1.4-3.6-4.4-4.5-6.9c-4.8-12.8-3-25,3.5-36.7c5.1-9.3,12.2-16.4,21.7-21c13.6-6.6,27.1-13.1,40.6-19.7
          C103.2,136.6,104.1,136.2,105.3,135.6z"/>
        </svg>
        <span class="user-name" v-if="user">{{user.name}} {{user.lastName}}</span>
        <span class="user-name" v-else>Iniciar sesión</span>
      </div>
    </div>
    <div v-if="user && active" class="modal" :class="{active, desactive}">
      <UserArea @handlerModal="handlerModal" />
    </div>
  </template>
  <template v-else>
    <router-link :to="{ name: 'home' }">
    <div class="user_button__wrapper">
      <div class="content">
        <svg :class="{ icon_hidden: $route.name === 'confirmation' }" xmlns="http://www.w3.org/2000/svg" fill="white" viewBox="0 0 48 48" width="48px" height="48px">
          <path d="M 23.951172 4 A 1.50015 1.50015 0 0 0 23.072266 4.3222656 L 8.859375 15.519531 C 7.0554772 16.941163 6 19.113506 6 21.410156 L 6 40.5 C 6 41.863594 
          7.1364058 43 8.5 43 L 18.5 43 C 19.863594 43 21 41.863594 21 40.5 L 21 30.5 C 21 30.204955 21.204955 30 21.5 30 L 26.5 30 C 26.795045 30 27 30.204955 27 30.5 
          L 27 40.5 C 27 41.863594 28.136406 43 29.5 43 L 39.5 43 C 40.863594 43 42 41.863594 42 40.5 L 42 21.410156 C 42 19.113506 40.944523 16.941163 39.140625 15.519531 
          L 24.927734 4.3222656 A 1.50015 1.50015 0 0 0 23.951172 4 z M 24 7.4101562 L 37.285156 17.876953 C 38.369258 18.731322 39 20.030807 39 21.410156 L 39 40 L 30 40 L 
          30 30.5 C 30 28.585045 28.414955 27 26.5 27 L 21.5 27 C 19.585045 27 18 28.585045 18 30.5 L 18 40 L 9 40 L 9 21.410156 C 9 20.030807 9.6307412 18.731322 10.714844 
          17.876953 L 24 7.4101562 z"/>
        </svg>
        <div>
          <span class="back-home">Inicio</span>
        </div>
      </div>
    </div>
    </router-link>
  </template>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import UserArea from './UserArea.vue'
export default {
  name: 'UserButton',
  components: {
    UserArea
  },
  props: {
    color: {
      type: String,
      default: ''
    },
    dropdownMenu: {
      type: Boolean,
      default: false
    },
    innerWidth: {
      type: Number,
      default: 0
    },  
    isCartOpen: Boolean
  },

  data() {
    return {
      active: false,
      desactive: true,
      orderDetail: false,
      scroll: window
    }
  },
  computed: {
    ...mapState('User', ['user']),
  },
  watch: {
    $route: {
      immediate: true,
      handler(url) {
        if (url.path !== '/') {
          this.active = false,
          this.desactive = true
        } else if (url.path == '/' && this.active) {
          this.active = false,
          this.desactive = true
        }
      }
    }
  },
  methods: {
    ...mapActions('User', ['fetchProfile', 'getOrdersHistoryFromUser']),
    toggleLogin() {
      if (!this.user) {
        if (this.$route.name == 'checkout') {
          this.$router.push({name: 'login-checkout'})
        } else {
          this.$router.push({name: 'login'})
        }
      } else {
        this.getOrdersHistoryFromUser()
        this.active = !this.active
        this.desactive = !this.desactive
      }
    },
    handlerModal() {
      this.active = false
      this.desactive = true
    }
  }
}
</script>
<style scoped lang="scss">
  .user_button__wrapper {
    top: 15px;
    right: 0;
    transition: all .3s;
    cursor: pointer;
    .content {
      display: flex;
      align-items: center;
      svg {
        width: 28px;
        height: 28px;
      }
      span {
        display: none;
        text-transform: uppercase;
        font-family: $font__headings;
        font-size: $fs-l-small;
        .user-name {
          font-weight: $fw-regular;
          color: $c-white;
          margin: 0;
        }
      }
    }

    @include tablet {
      // background-color: $c-black;
      border: 2px solid $c-white;
      border-radius: 30px;
      top: 13px;
      padding: 0.75rem 1rem;
      .content {
        color: $c-white;
        svg {
          width: 20px;
          height: 20px;
          vertical-align: middle;
          margin-right: 10px;

          &.icon_hidden {
            display: none;
          }
        }
        span {
          display: inline-block;
        }
      }
      .back-home {
        display: block;
        color: $c-white;
      }
    }
    @include desktop {
      top: 18px;
    }
    &.white {
      svg {
        path {
          fill: $c-white;
          stroke: $c-white;
        }
      }
    }
    &.black {
      svg {
        path {
          fill: $c-black;
          stroke: $c-black;
        }
      }
    }
  }

  .dropdown-border {
    border: 2px solid white;
    border-radius: 50%;
    padding: 10px;

    @include tablet {
      border-radius: 30px;
      padding: 0.75rem 1rem;
    }
  }

  .modal {
      display: none;
      &.active, &.desactive {
        top: 78px;
        background-color: $c-white;
        @include tablet {
          left: 60%;
        }
        @include desktop {
          left: 70%;
          top: 108px;
        }
      }

      &.active {
        display: block;
        animation: openModal .8s linear;
        animation-fill-mode: forwards;
        @keyframes openModal {
          from {
            transform: translateY(-150%);
          } to {
            transform: translateY(0%)
          }
        }
        @include tablet {
          @keyframes openModal {
            from {
              transform: translateX(150%);
            } to {
              transform: traslateX(0%);
            }
          }
        }
      }

      &.desactive {
        display: none;
        animation: closeModal .8s linear;
        animation-fill-mode: forwards;
        @keyframes closeModal {
          from {
            transform: translateY(0%);
          } to {
            transform: translateY(-150%);
          }
        }
        @include tablet {
          @keyframes closeModal {
            from {
              transform: translateX(0%);
            } to {
              transform: traslateX(150%);
            }
          }
        }
      }
    }
  body.route-catalog {
    .user_button__wrapper {
      .modal {
        bottom: 80px;
      }
    }
  }
  .logout {
    cursor: pointer;
    fill: $c-white;
    position: absolute;
    width: 27px;
    right: 60px;
  }
  .userHello {
      text-transform: uppercase;
      font-size: 24px;
  }
</style>
