<template>
<div class="modal" @click.self="$emit('closeModal')">
    <div class="modal-dialog">
        <span class="close-modal" @click="$emit('closeModal')">X</span>   
        <div class="header-wrapper">
        <h2>¿Te vas sin llevarte esto?</h2>
        </div>  
        <div v-for="product in getUpsellingProducts" :key="product.id" class="catalog-product">
            <div class="catalog-product-box">
                <img :src="product.imageUrl" alt="upselling-picture">
                <div class="text">
                    <h3>{{product.name}}</h3>
                    <p>{{product.description}}</p>
                    <button @click="addToCart(product)" class="btn">Añadir al pedido</button>
                </div>
            </div>
              <button @click="$emit('closeModal')" class="btn footer">
                Continuar con mi pedido
                <svg xmlns="http://www.w3.org/2000/svg" width="9" height="15" viewBox="0 0 9 15" fill="none" >
                  <path d="M0.886667 15L-4.09794e-08 14.0625L7 7.4975L-6.14691e-07 0.9375L0.886666 -3.87574e-08L9 7.4975L0.886667 15Z" fill="white"/>
                </svg>
              </button>
        </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'UpsellingModal',
  props: {
    productDetail: { 
      type: String,
    },
  },
  methods: {
    ...mapActions('Cart', ['addProductToCart', 'checkProductsCart']),
    ...mapActions('Catalog', ['products']),

    addToCart(product) {
        this.$emit('closeModal')
        this.$router.push({ name: 'product-detail-checkout', params: { productDetail: product.plu } })
    },
  },

  computed: {
    ...mapGetters('Cart', ['getUpsellingProducts']),
  },
}
</script>

<style scoped lang="scss">

.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  -webkit-transition: all .7s;
  transition: all .7s;
  display: block;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.35s ease-in;
  z-index: 300;
  overflow: hidden;

  .modal-dialog {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: default;
    height: 100vh;
    width: 100%;
    background-image: url("../assets/bg-texture.jpg");
    overflow: unset;

  .close-modal {
    display: none;
    }

    .header-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 120px;
        padding: 0 20px;
    }


    h2 {
        font-family: $font__headings;
        font-size: $fs-l-meduim;
        text-align: center;
        text-transform: uppercase;
        margin-top: 50px;
    }

    .catalog-product {
        cursor: default;
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
        gap: 40px;
        font-size: 0;
        margin: 0;
        padding: 0 20px;
    }

    .catalog-product-box {
      background-color: $c-white;
      border-radius: 8px;
      column-gap: 1rem;
      cursor: pointer;
      display: flex;
      margin: 0 40px;
      max-width: 550px;
      width: 100%;
      
      img {
        border-radius: 8px;
        object-fit: cover;
        width: 150px;
      }

      .text {
        width: 100%;
        display: flex;
        padding: 15px 10px 10px;
        flex-direction: column;
      }

      p {
        font-size: $fs-m-small;
        font-family: $font__main;
        margin-top: 10px;
        margin-right: 10px;
        flex-grow: 2;
        max-height: 4em;
        overflow: hidden;
      }

      h3 {
        font-family: $font__headings;
        text-transform: uppercase;
        margin: 0;
        font-size: $fs-l-small;
      }
      
      .btn {
        padding: 8px 20px;
        font-size: $fs-m-small;
        display: block;
        width: fit-content;
        background-color: $c-black;
      }
    }
  }

   @include tablet {
    .modal-dialog {
      min-width: 50vw;
      max-width: 30vw;
      height: unset;
      width: unset;
      position: relative;

    .close-modal {
      display: inline-block;
      font-family: $font__headings;
      font-size: $fs-l-large;
      color: $c-white;
      cursor: pointer;
      position: absolute;
      top: $fs-l-large * -1.5;
      right: 0;
    }

    .header-wrapper {
      flex-direction: row;
      justify-content: space-around;
      margin: 0;
      margin-top: 50px;
      margin-bottom: 10px;

      h2 {
        margin: 0;
      }
    }

    .catalog-product {
      flex-direction: column;
      align-items: center;
      padding: 30px 40px;
    }

    .catalog-product-box {
      // width: 100%;
      margin: 0 auto;
      background-color: $c-white;
      max-width: 500px;
      .text {
        p {
          text-align: start;
        }
      }
    }

    .btn {
      &.footer {
        max-width: 280px;
      }
    }
    }
  }
}

</style>