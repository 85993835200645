<template>
  <svg v-if="name === 'arrow-down-circle'" @click="click" xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44" fill="none">
    <circle cx="22" cy="22" r="21" transform="rotate(90 22 22)" stroke="white" stroke-width="2"/>
    <path d="M22 12.6667L22 31.3334" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M31.3335 22L22.0002 31.3333L12.6668 22" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'Icons',
  props: {
    name: {
      type: String,
      default: ''
    },
    click: {
      type: Function,
      default: null
    }
  }
}
</script>
