<template>
  <div>
    <label for="add-payment-method-input">
      <span>Titular de la tarjeta</span>
      <input type="text" data-paycomet="cardHolderName" />
    </label>
    <label for="add-payment-method-input">
      <span>Número de la tarjeta</span>
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text"><i class="fa fa-credit-card"></i></span>
        </div>
        <div id="paycomet-pan" paycomet-style="display: none;"></div>
        <input id="paycomet-pan" type="tel" paycomet-style="width:100%; height:21px; font-size:14px; padding-left:7px; padding-top:8px; border:0px;" paycomet-name="pan" data-paycomet="paNumber" paycomet-placeholder="Introduce tu tarjeta..."/>
      </div>
    </label>
    <div class="expiration-date">
      <label for="add-payment-method-input">
        <span>Fecha de caducidad</span>
        <div>
          <input type="text" placeholder="MM" data-paycomet="dateMonth" maxlength="2" />  /
          <input type="text" placeholder="AA" data-paycomet="dateYear" maxlength="2"/>
        </div>
      </label>
    </div>
    <div class="cvv-date">
      <label for="add-payment-method-input">
        <span>CVV</span>
        <div id="paycomet-cvc2"></div>
        <input type="text" paycomet-name="cvc2" paycomet-style="border:0px; width: 100%; height: 21px; font-size:12px; padding-left:7px; padding-tap:8px;" paycomet-placeholder="CVV2"/>
      </label>
    </div>

  </div>
</template>

<script>
export default {
  name: 'PaycometForm',
  mounted() {
    //this.$forceUpdate()
    let preExistingIframes = window.document.querySelector('#paycomet_ifr_panumber')
    if(preExistingIframes) {
      location.reload()
    }

    let paycometScript = document.createElement('script')
    paycometScript.setAttribute('src', 'https://api.paycomet.com/gateway/paycomet.jetiframe.js?lang=es')
    paycometScript.setAttribute('id', 'paycometJS')
    document.head.appendChild(paycometScript)
  },
  unmounted() {
    document.getElementById('paycometJS').remove()
    /*const iframes = document.querySelectorAll('iframe');

    iframes.forEach(iframe => {
      iframe.remove();
    });*/
  }
}
</script>

<style scoped lang="scss">
.personal-info, .detail-info, .add-payment-method-info, .payment-method-info {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  label {
    display: flex;
    flex-direction: column;
    span {
      font-family: $font__main;
      font-size: $fs-s-small;
    }
  }
}
input, #paycomet-pan, #paycomet-cvc2 {
  outline: none !important;
}
.input-group, #paycomet-cvc2 {
  height: 44px;
  border: 1px solid $c-border-input;
  border-radius: 6px;
  margin-top: 6px;
  margin-bottom: 10px;
  padding: 0 10px;
  &::placeholder {
    color: $c-placeholder;
    font-family: $font__main;
  }
  #paycomet-pan {
    padding: 5px;
  }
}

.paycomet-input:focus {
  outline: none;
}

#paycomet-cvc2 {
  padding-top: 9px;
}
</style>
