<template>
<div class="modal" @click.self="closeModal">
	<div class="modal-dialog">
		<span class="close-modal" @click="closeModal">X</span>
		<div class="modal-header__wrapper">
			<Header color="black" colorBg="white" />
		</div>
		<div class="container">
			<div class="container__header">
				<h1>Identifícate</h1>
				<div @click="openModalRegister" class="container__header-login">
					<strong>¿Aún no tienes cuenta?</strong>
					<button class="btn">Regístrate</button>
				</div>
			</div>
			<form @submit.prevent="loginUser">
				<div class="form__field-wrapper">
					<label>Introduce tu correo electrónico:</label>
					<input 
						v-model="v$.form.email.$model" 
						:class="{'form__input-invalid': v$.form.email.$invalid && v$.form.email.$dirty}"
						name="email" 
						id="email" 
						type="email" 
						required
						@click="removeErrors"
					>
					<p class="input-errors" v-if="v$.form.email.$invalid && v$.form.email.$dirty">
						Por favor, introduce un correo válido
					</p>
				</div>
				<div class="errors" v-if="errors.user != ''">
					<p>{{errors.user}}</p>
				</div>
				<div v-if="isLoading" class="loading">
					<span class="spinner"></span>
				</div>
				<div v-else-if="success" class="success">Si estás registrado en la plataforma te llegará un correo con instrucciones para acceder.</div>
				<div v-else class="container__button-submit">
					<button type="submit" class="btn">Entrar en tu cuenta</button>
				</div>
			</form>
		</div>
	</div>
  </div>
</template>

<script>
import Header from '../components/Header'
import { mapActions, mapState } from 'vuex'
import { required, email } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'


export default {
	name: 'Login',
	components: {
			Header
	},

	setup() {
		return { v$: useVuelidate() }
	},

	data() {
		return {
			form: {
				email: ''
			},
			errors: {
				user: ''
			},
			success: false,
			isLoading: false
		}
	},

	validations: {
		form: {
			email: {
				required,
				email
			}
		}
	},

	methods: {
		...mapActions('Auth', ['login', 'refresh']),

			closeModal() {
			if (this.$route.name == 'login-checkout') {
				this.$router.push({name: 'checkout'})
			} else {
				this.$router.push({name: 'home'})
			}
		},

		openModalRegister() {
			if (this.$route.name == 'login-checkout') {
				this.$router.push({name: 'register-checkout'})
			} else {
				this.$router.push({name: 'modal-register'})
			}
		},

		loginUser() {
			this.isLoading = true
			if (!this.v$.$invalid) {
				this.login(this.form)
					.then(() => {
						this.success = true
						this.isLoading = false
						this.$gtag.event('login')
					})
					.catch(() => this.isLoading = false)
			}
		},

		removeErrors() {
			this.errors.user = ''
		},
	},

	computed: {
		...mapState('User', ['user'])
	},
}
</script>

<style scoped lang="scss">
.modal {
    .modal-dialog {
        .container {
            padding: 20px;

            .container__header {
                text-align: center;

                h1 {
                    font-family: $font__headings;
                    text-transform: uppercase;
                }

                .container__header-login {
                    display: flex;
                    flex-direction: column;
                    width: 200px;
                    margin: 0 auto;

                    strong {
                        font-size: $fs-m-small;
                        display: block;
                        margin-bottom: 5px;
                    }

                    .btn {
                        padding: 5px 30px;
                        font-size: 16px;
                        background-color: $c-black;
                    }
                }
            }
            .errors {
                p {
                color: $c-red;
                font-size: $fs-m-small;
                text-align: center;
                margin: 0;
                margin-top: 10px;
                }
            }

            .form__field-wrapper {
                display: flex;
                flex-direction: column;
                font-size: $fs-m-small;;
                margin-top: 20px;
                padding: 10px;

                .input-errors {
                    color: $c-red;
                    text-align: right;
                }
            }

            .container__button-submit {
                display: flex;
                justify-content: center;
            }

            .success {
                color: $c-green;
                font-size: $fs-m-small;
                padding: 15px 10px;
								text-align: center;
            }
        }
    }

    @include tablet {
        .modal-header__wrapper {
					display: none;
        }
        .modal-dialog {
					.container {
						padding: 20px 40px;

						.container__header {
							display: flex;
							align-items: flex-end;
							justify-content: space-between;
							padding: 10px;
							
							.container__header-login {
								display: flex;
								flex-direction: column;
								align-items: flex-end;
								margin: 0;
							}
						}

						.container__button-submit {
							margin-top: 15px;
							margin-bottom: 20px;
						}
				}
			}
    }
}
.loading {
  position: relative;
  height: 60px;
}

</style>
