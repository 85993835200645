import { createStore } from 'vuex'
import Auth from './modules/auth.store'
import Cart from './modules/cart.store'
import User from './modules/user.store'
import Catalog from './modules/catalog.store'

export default createStore({
  modules: {
    Auth,
    Cart,
    User,
    Catalog,
  },
})
