<template>
	<div v-if="order" @click.self="$emit('toggleModal')" class="cart-wrapper">
		<span @click="$emit('toggleModal')" class="close-modal">X</span>
		<h3>Resumen de tu pedido:</h3>
		<div class="container"> 
			<div class="wrapper-cart">
				<div v-for="(product, idx) in order.products" :key="idx" class="product-info">
					<img :src="product.image" alt="burger">
					<div class="description">
						<h2>{{product.name}}</h2>
							<template v-for="(eachProduct, index) in product.products" :key="index">
								{{eachProduct.name}}<span v-if="index !== product.products.length - 1">, </span>
							</template>
						<div class="modifiers">
							<template v-for="(modifier, index) in product.modifiers" :key="index">
								{{modifier.name}}<span v-if="index !== product.modifiers.length - 1">, </span>
							</template>
						</div>
					</div>
					<div class="calc">
						<div class="quantity">
							<strong>{{product.quantity}}</strong>
						</div>
						<span class="price">
							{{$filters.formatEUR((product.price * product.quantity), true)}}
						</span>
					</div>
				</div>
			</div>
		</div>
		<hr>
		<div v-if="$route.name === 'confirmation'" class="link">
			<span @click="$emit('toggleModal')" class="back__order-detail">
				<svg xmlns="http://www.w3.org/2000/svg" width="8" height="14" viewBox="0 0 8 14" fill="none">
					<path d="M6.53579 0.109817L7.21116 0.934203L1.66847 6.5282L7.01308 12.3075L6.30941 13.1078L0.113147 6.50111L6.53579 0.109817Z" fill="black"/>
				</svg>
				<a>Volver al resumen del pedido</a>
			</span>
		</div>
		<SumPrices :order="order"/>
	</div>
</template>

<script>
import { mapActions } from 'vuex'
import SumPrices from './SumPrices.vue'
export default {
	name: 'OrderDetail',
	emits: ['toggleModal'],
	components: {
		SumPrices
	},
	props: {
		closeModal: {
			type: Boolean
		},
		order: Object,
	},
	data() {
		return {
			chosenProduct: null,
			isModalOpen: false,
		}
	},

	methods: {
	...mapActions('User', ['getOrderById']),
	},
}
</script>

<style scoped lang="scss">
.cart-wrapper {
	background-color: $c-white;
	padding: 10px 20px;
	transition: all .3s;

	&.close_modal {
		display: none;
	}
	
	h3 {
		font-family: $font__headings;
		font-size: $fs-s-medium;
		text-transform: uppercase;
		margin-top: 30px;
		margin-bottom: 30px
	}

	.container {
		min-height: 35vh;
		max-height: 47vh;
		overflow: auto;

		.empty-cart {
			display: flex;
			flex-direction: column;
			font-family: $font__headings;
			text-align: center;
			padding: 130px 0;

			a {
				font-size: $fs-m-small;
				margin: 10px;
				color: $c-black;
			}
		}

		.warnings {
			color: $c-red;
			font-size: $fs-m-small;
			padding-bottom: 20px;
			text-align: center;
		}

		@include tablet {
			@media screen and (min-height: 0px) {
				min-height: 38vh;

				.empty-cart {
					overflow-x: scroll;
				}
			}
		}
	}

		.link {
			display: flex;
			justify-content: center;
			align-items: center;
			margin: 15px 0;

			a {
				font-size: $fs-s-small;
				color: $c-black;
				margin-left: 5px;
				text-decoration: underline;
			}

			&.link_hidden {
				display: none;
			}

			.back__order-detail {
				cursor: pointer;
				display: flex;
				align-items: center;
			}

			@include tablet {
				display: none;
			}
		}
		
		::-webkit-scrollbar {display: none;}
		.wrapper-cart {
			margin: 0 0 20px;
			.product-info {
				padding: 5px 0;
				display: flex;
				justify-content: space-around;

				&.disabled {
					opacity: .5;
					position: relative;
				}
		
				img {
					border-radius: 6px;
					width: 70px;
					height: 70px;
					object-fit: cover;
					flex-shrink: 0;
				}

				.description {
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					margin: 0 15px;
					width: 100%;

					.modifiers {
						font-size: $fs-s-small;
					}

					h2 {
						font-size: $fs-m-small;
						font-family: $font__headings;
						text-transform: uppercase;
						margin-top: 0;
					}
				}

				.calc {
					display: flex;
					flex-direction: column;
					align-items: flex-end;
					justify-content: flex-end;
					margin-left: 20px;
					width: 20%;

					.quantity {
						display: flex;
						justify-content: flex-end;
						margin-right: 10px;
						width: 100%;

						span {
							text-align: center;
							border-radius: 50%;
							border: 1px solid $c-black;
							cursor: pointer;
							height: 25px;
							line-height: 25px;
							width: 25px;
							transition: all .3s;

							&:hover {
								background-color: $c-black;
								color: $c-white;
							}

							&.disabled_quantity_button {
								pointer-events: none;
							}

							&.hidden_button {
								display: none;
							}
						}

						strong {
							margin: 2px 8px;
							}
						}

						.price {
							color: #5F5F5F;
							font-size: $fs-s-small;
							margin-top: 10px;
						}
				}
			}
			.availabilityErrors {
				margin: 10px 0;
				p {
					margin: 0;
					font-size: $fs-xs-small;
					text-align: center;
					color: $c-red;
				}
			}
		}


	.calc {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-end;
		width: 20%;

		.quantity {
			display: flex;
			justify-content: space-between;
			width: 100%;

			span {
				text-align: center;
				border-radius: 50%;
				border: 1px solid $c-black;
				cursor: pointer;
				height: 25px;
				line-height: 25px;
				width: 25px;
				transition: all .3s;

				&:hover {
					background-color: $c-black;
					color: $c-white;
				}
			}

			strong {
				margin: 2px 8px;
			}
		}

		.iva {
			font-size: $fs-s-small;
			opacity: 0.3;
			.price {
				color: #5F5F5F;
				font-size: $fs-s-small;
				margin-top: 10px;
			}
		}
	}
}
</style>
