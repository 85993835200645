<template v-if="product">
  <div class="modal" @click.self="closeModal">
    <div class="modal-dialog">
      <span class="close-modal" @click.self="closeModal">X</span>
      <div class="spinner" v-if="isLoading"></div>
      <div class="modal-tablet" v-else>
        <img :src="product.imageUrl" alt="burger">
        <div class="middle-tablet">
          <h2>{{product.name}}</h2>
          <p class="product-description">{{product.description}}</p>
          <span v-if="product.bundles.length > 0">
              <ComboMenu v-model:comboMenu="comboMenu" @checkSelectedData="checkSelectedData" />
          </span>
          <div v-else v-for="(modifierGroup, modifierGroupIdx) in product.modifiersGroups" :key="modifierGroupIdx" class="modifier-group"
            :class="{disabled_modifier: modifierGroup.modifiers.length === 0}">
            <span class="availability-error" v-if="modifierGroup.min == 1 && modifierGroup.max == 1 && !hasValidModifier(modifierGroup.id, modifierGroup.min, modifierGroup.max)">
              Selecciona exactamente uno
            </span>
            <span class="availability-error" v-else-if="modifierGroup.min > 0 && !hasValidModifier(modifierGroup.id, modifierGroup.min, modifierGroup.max)">
              Selecciona al menos {{ modifierGroup.min == 1 ? '1 opción' : `${modifierGroup.min} opciones` }}
            </span>
            <span class="availability-error" v-else-if="modifierGroup.max !== null && modifierGroup.max < modifierValue[modifierGroup.id].length && !hasValidModifier(modifierGroup.id, modifierGroup.min, modifierGroup.max)">
              Selecciona un máximo de {{modifierGroup.max }} opciones
            </span>
            <h5 @click="handleModifierGroup(modifierGroupIdx)" class="modifier-group-header">
              <svg :class="{'collapsed': isModifierGroupHidden && !arrIndex.includes(modifierGroupIdx)}" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z"/></svg>
              <span>{{modifierGroup.name}}</span>
            </h5>
            <div class="checkbox-wrapper">
              <label v-for="(modifier, idx) in modifierGroup.modifiers" :key="idx" :class="{hidden_group: isModifierGroupHidden && !arrIndex.includes(modifierGroupIdx)}">
                <input v-if="modifierGroup.max == 1 && modifierGroup.min == 1"
                  @change="checkSelectedData"
                  type="radio"
                  :name="modifierGroup.id"
                  :value="modifier.plu"
                  v-model="modifierValue[modifierGroup.id]"
                >
                  <input v-else
                    @change="checkSelectedData"
                    type="checkbox"
                    :name="modifierGroup.id"
                    :value="`${modifier.plu}|${idx}|${modifierGroupIdx}`"
                    v-model="modifierValue[modifierGroup.id]"
                  >
                  <div class="checkbox-content">
                    <span>{{modifier.name}}</span>
                    <div class="price">
                      <span v-if="hasPrice(modifier.price)">+{{$filters.formatEUR(modifier.price, false)}}</span>
                    </div>
                  </div>
                </label>
              </div>
          </div>
        </div>
        <div class="footer-modal">
          <strong class="price">{{$filters.formatEUR(handleTotalProductSum, false)}}</strong>
          <button 
            @click="addToCart(product.id)" 
            class="add-product" 
            :class="{disabled_button: isDisabledButton}">
            {{ typeof productPosition === 'number' ? 'Modificar producto' : 'Añadir al pedido' }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import ComboMenu from './ProductDetail/ComboMenu.vue'

export default {
  components: { 
    ComboMenu
  },
  name: 'ModalDetail',
  props: {
    productDetail: { 
      type: String,
      required: true
    }
  },

  data() {
    return {
      isLoading: true,
      disable: false,
      isDisabledButton: false,
      isModifierGroupHidden: false,
      id: null,
      productToModify: null,
      productPosition: null,
      totalModifiersSum: 0,
      indexReturned: 0,
      modifierValue: {},
      comboMenu: {},
      arrIndex: [],
      filterArrIdx: [],
    }
  },
  computed: {
    ...mapState('Catalog', ['product', 'catalog']),
    ...mapState('Cart', ['orderInfo', 'productInCartIndex', 'productInCart']),
    ...mapGetters('Cart', ['getOrderInfo', 'getUpsellingProducts', 'getProductIndexPosition']),
    handleTotalProductSum() {
      let modifiersMap = {}
      this.product.modifiersGroups.forEach(modifierGroup => modifierGroup.modifiers.forEach((modifier) => {
        modifiersMap[modifier.plu] = modifier.price
      }))
      let totalModifiersSum = 0
      Object.values(this.modifierValue).forEach((modifiers) => {
        if (Array.isArray(modifiers)) {
          modifiers.forEach(modifierValue => {
            let modifierSplit = modifierValue.split('|')
            if (modifiersMap.hasOwnProperty(modifierSplit[0])) {
              totalModifiersSum += modifiersMap[modifierSplit[0]]
            }
          })
        } else if (typeof modifiers == 'string' && modifiersMap.hasOwnProperty(modifiers)) {
          totalModifiersSum += modifiersMap[modifiers]
        }
      })
      return this.product.price + totalModifiersSum
    }
  },
  methods: {
    closeModal() {
      this.$route.name === 'product-detail' ? this.$router.push({name: 'catalog'}) : this.$router.push({name: 'checkout'})
    },
    addToCart() {
      let modifiersToCart = []
      let hasComboProducts = []

      let modifiersMap = {}
      this.product.modifiersGroups.forEach(modifierGroup => modifierGroup.modifiers.forEach(modifier => {
        modifiersMap[modifier.plu] = modifier
      }))
      Object.values(this.modifierValue).forEach((modifiers) => {
        if (Array.isArray(modifiers)) {
          modifiers.forEach((modifierValue) => {
            let modifierSplit = modifierValue.split('|')
            if (modifiersMap.hasOwnProperty(modifierSplit[0])) {
              modifiersToCart.push({
                ...modifiersMap[modifierSplit[0]],
                index: Number(modifierSplit[1]),
                modifierGroupIndex: Number(modifierSplit[2])
              })
            }
          })
        } else if (typeof modifiers == 'string' && modifiersMap.hasOwnProperty(modifiers)) {
          modifiersToCart.push(modifiersMap[modifiers])
        }
      })
      this.product.bundles.forEach(bundle => bundle.products.forEach(product => Object.values(this.comboMenu).forEach(value => {
        if (value === product.plu) {
          hasComboProducts.push({ plu: product.plu, name: product.name})
        }})))
      this.addProductToCart({
        id: this.product.id,
        imageUrl: this.product.imageUrl,
        name: this.product.name,
        plu: this.product.plu,
        price: this.product.price,
        modifiers: modifiersToCart,
        quantity: 1,
        isProductAvailable: true,
        hasUnavailableModifiers: [],
        hasUnavailableProducts: [],
        isAlcohol: false,
        hasComboProducts,
        productToModifyIndex: typeof this.productPosition === 'number' ? this.productPosition : null
      })
      this.checkProductsCart(true)
      this.$gtag.event('add_to_cart', {
        currency: 'EUR',
        value: this.product.price / 100,
        items: [{
          item_id: this.product.plu,
          item_name: this.product.name
        }]
      })
      this.$route.name === 'product-detail' ? this.$router.push({ name: 'catalog' }) : this.$router.push({ name: 'checkout' })
    },
    ...mapActions('Catalog', ['products']),
    ...mapActions('Cart', ['addProductToCart', 'checkProductsCart']),
    hasPrice(product) {
      if (product !== 0) {
        return product
      }
    },
    checkSelectedData() {
      this.isDisabledButton = false
      let sum = 0
      this.product.modifiersGroups.forEach((modifierGroup) => {
        if (!this.hasValidModifier(modifierGroup.id, modifierGroup.min, modifierGroup.max)) {
          this.isDisabledButton = true
        }
      })
      this.product.bundles.forEach(bundle => sum += bundle.multiply)
      if (Object.keys(this.comboMenu).length !== sum) {
        this.isDisabledButton = true
      }
    },
    hasValidModifier(modifierGroupId, min, max) {
      if (Object.prototype.hasOwnProperty.call(this.modifierValue, modifierGroupId)) {
        let selectedModifiers = this.modifierValue[modifierGroupId]
        let modifiersCount = this.modifierValue[modifierGroupId].length
        if (typeof selectedModifiers == 'string' && selectedModifiers != '') {
          modifiersCount = 1
        }
        if (modifiersCount >= min && modifiersCount <= max) {
          return true;
        }
      }
      return false;
    },
    handleModifierGroup(idx) {
      if (this.arrIndex.includes(idx)) {
        let index = this.arrIndex.indexOf(idx)
        if (index !== - 1) {
          this.arrIndex.splice(index, 1)
        }
        this.isModifierGroupHidden = true
      } else {
        this.arrIndex.push(idx)
      }
    }
  },
  mounted() {
    if (this.$route.params.productPosition) {
      this.productPosition = Number(this.$route.params.productPosition)
      this.productToModify = this.getProductIndexPosition(this.productPosition)
    }
    this.checkProductsCart()
    this.products(this.productDetail)
      .then(data => {
        if (data.modifiersGroups.length === 0 && data.bundles.length === 0) {
          this.addProductToCart({
            id: this.product.id,
            imageUrl: this.product.imageUrl,
            name: this.product.name,
            plu: this.product.plu,
            price: this.product.price,
            modifiers: [],
            quantity: 1,
            isProductAvailable: true,
            hasUnavailableModifiers: [],
            hasUnavailableProducts: [],
            isAlcohol: false,
            hasComboProducts: []
          })
          this.$gtag.event('add_to_cart', {
            currency: 'EUR',
            value: this.product.price / 100,
            items: [{
              item_id: this.product.plu,
              item_name: this.product.name
            }]
          })
          this.checkProductsCart()
          this.$router.push({ name: 'catalog' })
        }
        data.modifiersGroups.forEach((modifierGroup, modifierIdx) => {
          if (modifierGroup.min === 1 && modifierGroup.modifiers.length == 1) {
            this.modifierValue[modifierGroup.id] = modifierGroup.modifiers[0].plu
          } else {
            this.modifierValue[modifierGroup.id] = []
            if (this.productToModify) {
              modifierGroup.modifiers.forEach((modifier, idx) => {
                this.productToModify.modifiers.forEach(modifierToModify => {
                  if (modifierToModify.plu === modifier.plu) {
                    if (modifierGroup.min === 1 && modifierGroup.max === 1) {
                      this.modifierValue[modifierGroup.id] = modifier.plu
                    } else {
                      if (modifierToModify.index === idx && modifierToModify.modifierGroupIndex === modifierIdx) {
                        this.modifierValue[modifierGroup.id].push(`${modifier.plu}|${idx}|${modifierIdx}`)
                      }
                    }
                  }
                })
              })
            }
          }
          this.arrIndex.push(modifierIdx)
        }) 
        this.$gtag.event('view_item', {
          currency: 'EUR',
          value: data.price / 100,
          items: [{ 
            item_id: data.plu, 
            item_name: data.name 
          }]
        })
        this.checkSelectedData()
        this.checkProductsCart()
      })
      .catch(error => {
        if(error.includes('Not Found')) {
          this.closeModal()
        }
      })
      .finally(() => this.isLoading = false)
  }
}
</script>

<style scoped lang="scss">
.modal {
  .modal-dialog {
    border-radius: 10px;
  }

  .modal-tablet {
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: 5px;
    height: 100%;
    position: relative;

    .back-button {
      background-color: $c-black;
      opacity: .8;
      border-radius: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 10%;
      left: 20px;
      height: 20px;
      padding: 18px;
      width: 90px;
      transition: all .3s;

      p {
        color: $c-white;
        font-size: $fs-m-small;
        margin-left: 5px;
      }

      .icon {
        height: 20px;
        width: 20px;
      }

      &:hover {
        background-color: $c-red;

      }
    }

    img {
      height: 75vw;
      object-fit: cover;
      margin-top: 55px;
      width: 100%;
      z-index: 1;
    }

    .middle-tablet {
      padding-bottom: 100px;
      width: 100%;
      
      h2 {
        font-family: $font__main_bold;
        margin-left: 25px;
        margin-bottom: 0;
        text-align: start;
        text-transform: uppercase;
      }

      p.product-description {
        margin-left: 25px;
        margin-right: 25px;
        font-size: $fs-m-small;
        margin-bottom: 2em;
      }
      div.modifier-group:last-child {
        margin-bottom: 100px;
      }
    }

    .footer-modal {
        display: flex;
        justify-content: flex-end;
        position: fixed;
        right: 0;
        width: 100%;
        height: 80px;

        strong {
          margin-right: 25px;
        }

        .add-product {
           &.disabled_button {
            opacity: .5;
            pointer-events: none;

            &:hover {
              background-color: $c-red;
            }
          }
        }
      }

    @include tablet {
      background-color: $c-white;
      border-radius: 10px;
      display: flex;
      height: 550px;
      margin: 0;
      width: 100%;

      .back-button {
        display: none;
      }

      img {
        height: 100%;
        margin-top: 0;
        overflow-y: hidden;
        width: 100%;
      }

      .middle-tablet {
        height: 100%;
        padding-bottom: 80px;
        overflow-y: auto;
        h2 {
          padding-top: 10px
        }

        p.product-description {
          margin-left: 25px;
          // margin-right: 26px;
          font-size: $fs-m-small;
          margin-bottom: 2em;
        }
      }

      .footer-modal {
        position: absolute;
        width: 51%;
      }

      ::-webkit-scrollbar {display: none;}
    }
  }

  @include tablet {
      .modal-dialog {
        top: 30px;
      }
  }

  .modifier-group {
    padding: 0 25px;
    margin-bottom: 30px;

    h2 {
        font-family: $font__headings;
        font-size: $fs-l-meduim;
        margin: 0;
        text-transform: uppercase;
    }

    p {
        font-size: $fs-s-small;
    }

    .hidden_group {
      background-color: red;
      display: none;
    }

    .availability-error {
      float: right;
      display: block;
      font-size: $fs-s-small;
      margin: 0;
      text-align: center;
      color: rgba($c-notice, .7);
    }

    h5 {
      font-size: $fs-m-small;
      margin: 0;
      padding-top: 0;
      padding-bottom: 5px;
      margin-bottom: 20px;
      position: relative;

      &::after {
        content: " ";
        display: block;
        position: absolute;
        width: 100%;
        height: 1px;
        background-color: #cacaca;
        bottom: 0;
      }
    }

    .checkbox-wrapper {
      display: flex;
      flex-direction: column;
      margin: 14px 21px;
      gap: 14px;
      width: calc(100% - 24px);
      position: relative;

      input[type="checkbox"], input[type="radio"] {
        display: none;
      }

      .checkbox-content {
        display: flex;
        justify-content: space-between;
        padding-left: 12px;
      }

      .checkbox-price {
        font-size: $fs-s-small;
      }
    }

    svg {
      margin-bottom: -2px;
      margin-right: 10px;
      height: 20px;
      width: 20px;
    }
    .deploy_icon {
      margin-bottom: -8px;
    }
    .modifier-group-header {
      cursor: pointer;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      svg {
        margin: 0 5px -2px 5px;
        margin-right: 10px;
        height: 14px;
        width: auto;
        transform: rotate(-90deg);
        transition: .15s ease-in-out transform;
        &.collapsed {
          transform: rotate(0);
        }
      }
    }
  }

    .footer-modal {
      background-color: $c-white;
      bottom: 0;
      display: flex;
      height: 80px;
      justify-content: space-between;
      align-items: center;

      .price {
        color: $c-black;
        font-family: $font__headings;
        font-size: $fs-s-medium;
        margin-left: 40px;
      }

      button {
        background-color: $c-red;
        border-radius: 20px;
        border: 0;
        color: white;
        cursor: pointer;
        height: 42px;
        font-size: $fs-l-small;
        margin-right: 40px;
        max-width: 400px;
        opacity: 1;
        padding: 0 50px;
        transition: all .3s;

        &:hover {
          background-color: $c-black;
        }
      }
    }
}

input {
  margin: 0;
  width: auto;
  display: inline-block;
  opacity: 0;
  & + span { 
    position: relative;
    cursor: pointer;
    user-select: none;
    padding-left: 5px;
    font-family: $font__main;
    font-size: $fs-s-small;
    font-weight: $fw-regular;

    &::before {
      content: " ";
      position: absolute;
      right: 100%;
      top: 6px;
      margin-top: -8px;
      height: 18px;
      width: 18px;
      border-radius: 50%;
      background-color: transparent;
      border: 1px solid $c-black !important;
    }
    &::after {
      content: " ";
      width: 25px;
      height: 17px;
      position: absolute;
      left: -16px;
      top: 3px;
      margin-top: -7px;
    }
  }
  &:checked + span::after {
    background: url('../assets/check.svg');
    background-repeat: no-repeat;
  }
  &:checked + span::before {
    background-color: $c-black;
  }
}

.element {
    margin-right: 16px;
}
</style>
