<template>
  <div @click="goHome" class="container-logo">
      <div class="container-logo__mobile">
        <div @click="dropMenu" class="menu__navtrigger">
          <div class="ham">
            <div class="ham--line"></div>
            <div class="ham--line"></div>
            <div class="ham--line"></div>
          </div>
        </div>
      <svg @click="$router.push({ name: 'home' })" class="logo" :class="color" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 765.07 414.4" style="enable-background:new 0 0 765.07 414.4;" xml:space="preserve">
        <g>
          <path class="logo-goiko-st0" d="M174.28,172.77h16.31c4.17,0,7.55-3.38,7.55-7.55v-12.99c0-17.11-4.18-30.25-12.53-39.42
            c-8.36-9.16-20.69-13.74-37-13.74s-28.65,4.58-37,13.74c-8.36,9.16-12.54,22.3-12.54,39.42v109.94c0,17.12,4.18,30.26,12.54,39.42
            c8.35,9.17,20.69,13.74,37,13.74s28.64-4.58,37-13.74c8.35-9.16,12.53-22.3,12.53-39.42v-59.5c0-4.17-3.38-7.55-7.55-7.55h-31.41
            c-4.17,0-7.55,3.38-7.55,7.55v15.1c0,4.17,3.38,7.55,7.55,7.55s7.55,3.38,7.55,7.55v31.61c0,7.69-1.56,13.05-4.68,16.09
            c-3.12,3.04-7.3,4.55-12.54,4.55c-5.24,0-9.41-1.51-12.53-4.54c-3.12-3.03-4.69-8.37-4.69-16.04V150.16
            c0-7.66,1.56-13.06,4.69-16.19c3.12-3.12,7.29-4.69,12.53-4.69c5.23,0,9.41,1.56,12.54,4.68c3.12,3.12,4.68,8.51,4.68,16.16v15.1
            C166.73,169.39,170.11,172.77,174.28,172.77z"/>
          <path class="logo-goiko-st0" d="M241.94,112.81c-8.66,9.16-12.99,22.3-12.99,39.42v109.94c0,17.12,4.33,30.26,12.99,39.42
            c8.65,9.17,21.14,13.74,37.45,13.74c16.31,0,28.8-4.58,37.46-13.74c8.65-9.16,12.99-22.3,12.99-39.42V152.23
            c0-17.11-4.33-30.25-12.99-39.42c-8.66-9.16-21.14-13.74-37.46-13.74C263.08,99.07,250.6,103.65,241.94,112.81z M296.61,150.11
            v114.17c0,7.66-1.56,13.04-4.68,16.16c-3.12,3.12-7.3,4.68-12.54,4.68c-5.24,0-9.41-1.56-12.53-4.68
            c-3.12-3.12-4.69-8.5-4.69-16.16V150.11c0-7.65,1.56-13.04,4.69-16.16c3.12-3.12,7.29-4.68,12.53-4.68c5.23,0,9.41,1.56,12.54,4.68
            C295.05,137.08,296.61,142.46,296.61,150.11z"/>
          <path class="logo-goiko-st0" d="M370.91,101.49c-4.17,0-7.55,3.38-7.55,7.55v196.33c0,4.17,3.38,7.55,7.55,7.55h18.12
            c4.17,0,7.55-3.38,7.55-7.55V109.04c0-4.17-3.38-7.55-7.55-7.55H370.91z"/>
          <path class="logo-goiko-st0" d="M458.49,101.49h-18.12c-4.17,0-7.55,3.38-7.55,7.55v196.33c0,4.17,3.38,7.55,7.55,7.55h18.12
            c4.17,0,7.55-3.38,7.55-7.55v-55.51c0-1.24,0.3-2.45,0.88-3.54l1.61-3.03c3.04-5.72,11.4-5.21,13.72,0.84l24.52,63.94
            c1.12,2.92,3.92,4.85,7.05,4.85h17.84c5.33,0,8.98-5.37,7.02-10.32l-40.92-103.77c-0.78-1.98-0.69-4.19,0.24-6.09l39.41-80.37
            c2.46-5.02-1.19-10.88-6.78-10.88h-16.31c-2.93,0-5.59,1.69-6.84,4.35l-40.85,87.17h-0.6v-83.97
            C466.05,104.87,462.66,101.49,458.49,101.49z"/>
          <path class="logo-goiko-st0" d="M578.11,112.81c-8.66,9.16-12.99,22.3-12.99,39.42v109.94c0,17.12,4.33,30.26,12.99,39.42
            c8.65,9.17,21.14,13.74,37.45,13.74s28.8-4.58,37.46-13.74c8.65-9.16,12.99-22.3,12.99-39.42V152.23
            c0-17.11-4.33-30.25-12.99-39.42c-8.66-9.16-21.14-13.74-37.46-13.74S586.76,103.65,578.11,112.81z M632.77,150.11v114.17
            c0,7.66-1.56,13.04-4.68,16.16c-3.12,3.12-7.3,4.68-12.54,4.68c-5.24,0-9.41-1.56-12.53-4.68c-3.12-3.12-4.69-8.5-4.69-16.16
            V150.11c0-7.65,1.56-13.04,4.69-16.16c3.12-3.12,7.29-4.68,12.53-4.68c5.23,0,9.41,1.56,12.54,4.68
            C631.21,137.08,632.77,142.46,632.77,150.11z"/>
        </g>
      </svg>
    </div>
    <div class="container-logo__tablet">
      <svg @click="$router.push({ name: 'home' })" class="logo" viewBox="0 0 36 56" xmlns="http://www.w3.org/2000/svg">
        <path d="m5.23886 25.9358h.9679c.25679 0 .45432-.1975.45432-.4543v-.7704c0-1.0074-.25679-1.7975-.75062-2.3506s-1.22469-.8099-2.19259-.8099-1.69877.2765-2.19259.8099c-.49383.5531-.750622 1.3234-.750622 2.3506v6.5383c0 1.0074.256792 1.7975.750622 2.3506.49382.5531 1.22469.8099 2.19259.8099s1.69876-.2766 2.19259-.8099c.49383-.5531.75062-1.3235.75062-2.3506v-3.5358c0-.2568-.19753-.4544-.45432-.4544h-1.87655c-.25679 0-.45432.1976-.45432.4544v.8889c0 .2567.19753.4543.45432.4543s.45433.1975.45433.4543v1.8765c0 .4544-.09877.7704-.27655.9482s-.43457.2765-.75061.2765c-.31605 0-.55309-.0987-.75062-.2765-.17778-.1778-.27655-.4938-.27655-.9482v-6.795c0-.4543.09877-.7704.27655-.9679.17778-.1778.43457-.2766.75062-.2766.31604 0 .55308.0988.75061.2766.17778.1778.27655.5136.27655.9679v.8889c0 .2568.19753.4543.45432.4543z"></path>
        <path d="m9.24864 22.3802c-.51358.5531-.77037 1.3235-.77037 2.3507v6.5382c0 1.0074.25679 1.7976.77037 2.3507.51358.553 1.26416.8098 2.23206.8098s1.7186-.2765 2.2321-.8098c.5136-.5531.7704-1.3235.7704-2.3507v-6.5382c0-1.0074-.2568-1.7976-.7704-2.3507-.5135-.553-1.2642-.8098-2.2321-.8098s-1.71848.2765-2.23206.8098zm3.25926 2.2124v6.7753c0 .4543-.0988.7704-.2765.9679-.1778.1778-.4346.2765-.7507.2765-.316 0-.553-.0987-.7506-.2765-.1778-.1778-.2765-.5136-.2765-.9679v-6.7753c0-.4543.0987-.7704.2765-.9679.1778-.1778.4346-.2765.7506-.2765.3161 0 .5531.0987.7507.2765.1777.1975.2765.5136.2765.9679z"></path>
        <path d="m16.9128 21.7086c-.2568 0-.4543.1976-.4543.4543v11.6544c0 .2568.1975.4543.4543.4543h1.0864c.2568 0 .4544-.1975.4544-.4543v-11.6544c0-.2567-.1976-.4543-.4544-.4543z"></path>
        <path d="m22.1277 21.7086h-1.0865c-.2568 0-.4543.1976-.4543.4543v11.6544c0 .2568.1975.4543.4543.4543h1.0865c.2567 0 .4543-.1975.4543-.4543v-3.2988c0-.079.0197-.1383.0592-.2173l.0988-.1778c.1778-.3358.6716-.316.8099.0593l1.4617 3.7926c.0593.1778.237.2963.4148.2963h1.0667c.316 0 .5333-.3161.4148-.6124l-2.4296-6.1629c-.0395-.1185-.0395-.2568.0197-.3556l2.3506-4.7802c.1383-.2963-.079-.6519-.395-.6519h-.9679c-.1778 0-.3358.0988-.4148.2568l-2.4297 5.1753h-.0395v-4.9975c-.0395-.2371-.237-.4346-.474-.4346z"></path>
        <path d="m29.2191 22.3802c-.5136.5531-.7704 1.3235-.7704 2.3507v6.5382c0 1.0074.2568 1.7976.7704 2.3507.5136.553 1.2642.8098 2.2321.8098s1.7185-.2765 2.2321-.8098c.5136-.5531.7704-1.3235.7704-2.3507v-6.5382c0-1.0074-.2568-1.7976-.7704-2.3507-.5136-.553-1.2642-.8098-2.2321-.8098s-1.7185.2765-2.2321.8098zm3.2593 2.2124v6.7753c0 .4543-.0988.7704-.2766.9679-.1778.1778-.4345.2765-.7506.2765-.316 0-.5531-.0987-.7506-.2765-.1778-.1778-.2766-.5136-.2766-.9679v-6.7753c0-.4543.0988-.7704.2766-.9679.1778-.1778.4346-.2765.7506-.2765.3161 0 .5531.0987.7506.2765.1778.1975.2766.5136.2766.9679z"></path>
        <path d="m32.7154 36.9778h-6.1433c-1.4419 0-2.6271 1.0469-2.7654 2.4494 0 .0592 0 .0987-.0197 2.9629 0 .3161-.1383.6124-.3556.8494-.237.237-.5728.3753-.9679.3753-.0197 0-.0395 0-.0395 0-.3556 0-.6914-.1383-.9481-.3753-.2371-.237-.3556-.5333-.3556-.8494v-2.6271c0-1.5013-1.3432-2.7655-2.9235-2.7655h-1.2049c-1.521 0-2.7852 1.2445-2.7852 2.7852v6.4395c0 .3161-.1383.6124-.3555.8494-.2568.2568-.5926.3753-.9877.3753-.3753 0-.7111-.1185-.9679-.3753-.237-.237-.3555-.5333-.3555-.8494 0-6.6568 0-6.716-.0198-6.7753-.1383-1.3629-1.3432-2.4494-2.76543-2.4494h-6.26173c-.69136 0-1.34321.2963-1.817287.7902-.474074.5135-.7111119 1.2049-.67160569 1.8963.33580269 4.7604 1.85679269 8.6123 4.50370269 11.3975 3.08148 3.2592 7.48645 4.9185 13.07655 4.9185s9.9951-1.6593 13.0765-4.9185c2.647-2.805 4.1679-6.6371 4.5037-11.3975.0593-.6914-.1975-1.3828-.6716-1.8963-.4345-.5136-1.0864-.8099-1.7777-.8099zm-3.4568 12.721c-2.7062 2.8642-6.6173 4.3061-11.6346 4.3061s-8.94815-1.4419-11.63457-4.3061c-2.33086-2.4494-3.65432-5.8864-3.97037-10.1926-.01975-.1976.07901-.3358.13827-.3951.05926-.0592.17778-.158.37531-.158h6.22223c.39506 0 .75061.2765.80987.6321v6.6173c0 .8494.33581 1.6395.94816 2.2518.6321.6124 1.4815.9482 2.3506.9482.8889.0197 1.758-.3161 2.3901-.9482.6124-.6123.9482-1.4024.9482-2.2518v-6.4395c0-.4346.3555-.8099.8099-.8099h1.2049c.4741 0 .9481.3951.9481.8099v2.6271c0 .8494.3358 1.6395.9482 2.2519.6321.6123 1.4815.9481 2.3506.9481.8889.0198 1.758-.316 2.3901-.9481.6124-.6124.9482-1.4025.9482-2.2519 0-.9679 0-2.5284 0-2.8049.0592-.3556.395-.6321.7901-.6321h6.1432c.1975 0 .3161.0988.3753.158.0593.0593.158.1975.1383.3951-.3358 4.3062-1.679 7.7432-3.9901 10.1926z"></path>
        <path d="m2.49318 19.0025h30.20242c.6914 0 1.3433-.2963 1.8173-.7902.4741-.5135.7111-1.2049.6716-1.8962-.3358-4.7605-1.8568-8.59264-4.5037-11.39758-3.0814-3.25926-7.4864-4.91852-13.0765-4.91852s-9.99507 1.65926-13.07655 4.91852c-2.64692 2.80494-4.167906 6.63708-4.5037085 11.39758-.0592592.6913.1975315 1.3827.6716055 1.8962.454323.4939 1.125923.7902 1.797533.7902zm3.47654-12.72102c2.70617-2.8642 6.61728-4.30617 11.63458-4.30617s8.9481 1.44197 11.6346 4.30617c2.3308 2.44939 3.6543 5.88642 3.9703 10.19262.0198.1975-.079.3358-.1382.395-.0593.0593-.1778.1581-.3754.1581h-30.20242c-.19753 0-.31605-.0988-.37531-.1581-.05926-.0592-.15803-.1975-.13827-.395.3358-4.3062 1.65926-7.74323 3.99012-10.19262z"></path>
      </svg>
      <div @click="dropMenu" class="menu__navtrigger">
        <div class="ham left-side">
          <div class="ham--line"></div>
          <div class="ham--line"></div>
          <div class="ham--line"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
export default {
  name: 'Logo',
  props: {
    color: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapState('Cart', ['productPlu']),
  },
  methods: {
    ...mapMutations('Cart', ['WAS_PRODUCT_CHOOSED']),
    goHome() {
       if (!this.productPlu) {
        this.WAS_PRODUCT_CHOOSED(false)
      }
    },
    dropMenu() {
      this.$emit('isOpen', true)
    }
  },
}
</script>

<style scoped lang="scss">
.container-logo__mobile {
  display: flex;
  align-items: center;
  @include desktop {
    display: none;
  }
}

.container-logo__tablet {
  display: none;
  @include desktop {
    display: flex;
    align-items: center;
  }
}
.menu__navtrigger {
  cursor: pointer;
  padding: 0.5rem;
  margin: -0.5rem;

  .ham {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    height: 2.75rem;
    width: 2.75rem;
    .ham--line {
      height: 0.15625rem;
      position: relative;
      overflow: hidden;
      width: 100%;
    }
    .ham--line:nth-child(1), .ham--line:nth-child(3) {
      margin: 0.375rem 0;
    }
    .ham--line::before, .ham--line::after {
      background: #fff;
      border-radius: 0.125rem;
      content: "";
      height: 100%;
      left: 0;
      position: absolute;
      transform-origin: left;
      width: 100%;
    }
    .ham--line::before {
      transform: scaleX(1);
    }
    .ham--line::after {
      transform: scaleX(0);
    }
  }
  .left-side {
    margin-left: 1.5rem;
  }

  &:hover {
    .ham--line {
      &::before {
        transform-origin: right;
				transform: scaleX(0);
				transition: transform .64s cubic-bezier(0.78, 0, 0.13, 1);
      }
      &::after {
				transform-origin: left;
				transform: scaleX(1);
				transition: transform .64s cubic-bezier(0.78, 0, 0.13, 1);
			}
      &:nth-child(1) {
				&::after {
					transition-delay: 0.08s;
				}
			}
      &:nth-child(2) {
				&::before {
					transition-delay: 0.02s;
				}
				&::after {
					transition-delay: 0.12s;
				}
			}
			&:nth-child(3) {
				&::before {
					transition-delay: 0.06s;
				}
				&::after {
					transition-delay: 0.16s;
				}
			}
    }
  }
}

svg {
  height: 100%;
}
.logo {
  cursor: pointer;
  fill: $c-white;
  width: auto;
}

svg {
  width: auto;
  height: 50px;
  &.white {
    path {
      fill: $c-white;
    }
  }

  &.black {
    path {
      fill: $c-black;
    }
  }

  @include desktop {
    height: 60px;
  }
}


</style>