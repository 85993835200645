<template>
  <header class="header" :class="colorBg">
    <div class="header__menu">
      <Back 
        v-if="$route.name !== 'home' && 
        $route.name !== 'takeaway-home' &&
        $route.name !== 'catalog' && 
        $route.name !== 'confirmation' &&
        $route.name !== 'cookies-policy' &&
        $route.name !== 'data-protection'"
        :color="color" 
        />
      <Logo 
        v-if="$route.name !== 'modal-delivery' &&
        $route.name !== 'modal-takeaway' &&
        $route.name !== 'product-detail' &&
        $route.name !== 'product-detail-checkout' &&
        $route.name !== 'modal-checkout' &&
        $route.name !== 'login' &&
        $route.name !== 'modal-register' &&
        $route.name !== 'register-checkout' &&
        $route.name !== 'login-checkout' &&
        $route.name !== 'checkout' &&
        $route.name !== 'modal-timechange'
        || innerWidth > 768" 
        :color="color" 
        @isOpen="dropMenu"
      />
    </div>
    <div class="header__config" :class="{'gap-reset': $route.name === 'checkout'}">
      <span :class="{ disabled_user: $route.name === 'product-detail' || $route.name === 'modal'}">
        <UserButton :color="color" />
      </span>
    </div>
    <span class="mobile-user-panel" :class="{ disabled_user: $route.name === 'product-detail' || $route.name === 'modal'}">
      <UserButton :color="color" />
    </span>
  </header>
  <div class="header-gap"></div>
  <DropdownMenu 
    v-if="isOpenDropdown === true || isOpenDropdown === false" 
    :isOpenDropdown="isOpenDropdown" 
    :innerWidth="innerWidth"
    :showIcon="showIcon"
    @isOpen="dropMenu" 
    @showIcon="toggleIcon" 
  />
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Back from './Back.vue'
import Logo from './Logo.vue'
import UserButton from './UserArea/HeaderButton.vue'
import DropdownMenu from './DropdownMenu.vue'

export default {
  name: 'Header',
  components: {
    Back,
    Logo,
    UserButton,
    DropdownMenu
  },
  props: {
    color: {
      type: String,
      default: ''
    },
    colorBg: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      scrollPosition: null,
      innerWidth: window.innerWidth,
      isOpenDropdown: null,
      showIcon: true
    }
  },
  computed: {
    ...mapState('Cart', ['restaurant'])
  },
  methods: {
    ...mapActions('Auth', ['logout']),
    ...mapActions('User', ['fetchProfile']),
    updateScroll() {
      this.scrollPosition = window.scrollY
    },
    dropMenu(value) {
      this.isOpenDropdown = value
      this.showIcon = true

      if (!value) {
        setTimeout(() => {
          this.isOpenDropdown = null
        }, 640) 
      }
    },
    toggleIcon(value) {
      this.showIcon = value
    }
  },
  mounted() {
    window.addEventListener('scroll', this.updateScroll)
    addEventListener('resize', e => {
      this.innerWidth = e.target.innerWidth
    })
  }
}
</script>

<style scoped lang="scss">
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: fixed;
  z-index: 301;
  transition: all 0.3s ease 0s;
  background-color: #000;
  padding: .875rem 24px;
  opacity: 1;
  & + .header-gap {
    display: block;
  }

  @media only screen and (min-width: 1200px) {
    padding: 1.5rem 40px;
  }

  .mobile-user-panel {
    display: flex;
    align-items: center;
    @media only screen and (min-width: 1200px) {
      display: none;
    }
  }

  .container-logo__tablet {
    display: none;

    @media only screen and (min-width: 1200px) {
      display: flex;
      align-items: center;
      gap: 8px;

      .logo {
        height: 4.5rem;
      }
    }
  }
  .menu__navtrigger {
    cursor: pointer;
    padding: 0.5rem;
    margin: -0.5rem;

    .ham {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: relative;
      height: 2.75rem;
      width: 2.75rem;
      .ham--line {
        height: 0.15625rem;
        position: relative;
        overflow: hidden;
        width: 100%;
      }
      .ham--line:nth-child(1), .ham--line:nth-child(3) {
        margin: 0.375rem 0;
      }
      .ham--line::before, .ham--line::after {
        background: #fff;
        border-radius: 0.125rem;
        content: "";
        height: 100%;
        left: 0;
        position: absolute;
        transform-origin: left;
        width: 100%;
      }
      .ham--line::before {
        transform: scaleX(1);
      }
      .ham--line::after {
        transform: scaleX(0);
      }
    }
    .left-side {
      margin-left: 1.5rem;
    }

    &:hover {
      .ham--line {
        &::before {
          transform-origin: right;
          transform: scaleX(0);
          transition: transform .64s cubic-bezier(0.78, 0, 0.13, 1);
        }
        &::after {
          transform-origin: left;
          transform: scaleX(1);
          transition: transform .64s cubic-bezier(0.78, 0, 0.13, 1);
        }
        &:nth-child(1) {
          &::after {
            transition-delay: 0.08s;
          }
        }
        &:nth-child(2) {
          &::before {
            transition-delay: 0.02s;
          }
          &::after {
            transition-delay: 0.12s;
          }
        }
        &:nth-child(3) {
          &::before {
            transition-delay: 0.06s;
          }
          &::after {
            transition-delay: 0.16s;
          }
        }
      }
    }
  }

  svg {
    height: 100%;
  }
  .logo {
    cursor: pointer;
    fill: #fff;
    width: auto;
  }

  svg {
    width: auto;
    height: 50px;
    &.white {
      path {
        fill: #fff;
      }
    }

    &.black {
      path {
        fill: #000;
      }
    }

    @media only screen and (min-width: 1200px) {
      height: 60px;
    }
  }

  .user_button__wrapper {
    top: 15px;
    right: 0;
    transition: all .3s;
    cursor: pointer;
    .content {
      display: flex;
      align-items: center;
      svg {
        width: 28px;
        height: 28px;
      }
      span {
        display: none;
        text-transform: uppercase;
        font-size: 16px;
        .user-name {
          font-weight: 400;
          color: #fff;
          margin: 0;
        }
      }
    }

    @media only screen and (min-width: 768px) {
      border: 2px solid #fff;
      border-radius: 30px;
      top: 13px;
      padding:  1rem;
      .content {
        color: #fff;
        svg {
          width: 20px;
          height: 20px;
          vertical-align: middle;
          margin-right: 10px;

          &.icon_hidden {
            display: none;
          }
        }
        span {
          display: inline-block;
        }
      }
    }
  }

  &__config {
    display: none;

    @media only screen and (min-width: 1200px) {
      display: flex;
      align-items: center;
      gap: 30px;

      span {
        color: #fff;
      }

      &--booking {
        border: 2px #ee2737 solid;
        border-radius: 32px;
        background-color: #ee2737;
        color: #fff;
        cursor: pointer;
        font-weight: 700;
        font-size: 1rem;
        line-height: 1rem;
        padding: 0.75rem 1rem;
        text-transform: uppercase;
        text-decoration: none;

        .mask {
          height: 1rem;
          position: relative;
          overflow: hidden;

          span {
            display: block;
            font-family: "Knockout";
          }

          .transition-text {
            transition: transform 0.3s, opacity 0.3s;
            transition-delay: .3s;
          }

          .text-top {
            transform: translateY(0);
            opacity: 1;
          }

          .text-bottom {
            transform: translateY(100%);
            opacity: 0;
            color: blue;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            text-align: center;
          }
        }
      }

      .lang--switch {
        display: flex;
        flex-direction: column;
        gap: 14px;
        margin-bottom: -15px;
        position: relative;

        .active {
          position: relative;
          z-index: 1;
          .lang--btn {
            background: #000;
          }
        }

        .btn {
          font-family: "Knockout";
          text-transform: uppercase;
          color: #fff;
          border-radius: 32px;
          background-color: rgba(0,0,0,0);
          border-color: #fff;
          line-height: 1rem;
          padding: 0.75rem 1rem;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          .arrow-drop-down {
            height: 100%;
            overflow: hidden;
            pointer-events: none;
            margin: -0.4375rem -0.25rem !important;
            order: 1;
            transition: .4s cubic-bezier(0.78, 0, 0.13, 1);
            width: 1.5rem;
            transition: .4s;
          }
          .active {
            transform: rotate(180deg);
          }
          .icon--filled__glyph {
            fill: #fff;
            fill-rule: evenodd;
            stroke: none !important;
          }
        }

        .lang--btn {
          font-weight: 400;
          gap: 0.5rem;
          border: 2px solid #fff;
          font-size: 20px;

          span {
            margin-right: 0;
            font-size: 1rem;
          }
        }

        .langs--availables {
          display: flex;
          flex-direction: column;
          gap: 14px;
          overflow: hidden;

          .lang--btn {
            background: #000;
            display: block;
            margin-left: 0 !important;
            position: absolute;
            opacity: 0;
            top: 0;
            left: 0;
            transition: transform .4s cubic-bezier(0.78, 0, 0.13, 1),opacity 0s .4s;
            transform: translateY(0);
            text-align: center;
            pointer-events: none;
            width: 100%;
          }

          a:hover {
            background-color: #fff;

            span {
              color: #000;
            }
          }
        }
      }

      .open {
        .langs--availables {
          .lang--btn {
            opacity: 1;
            pointer-events: auto;
            transition: transform .4s cubic-bezier(0.78, 0, 0.13, 1);
          }
          .lang--btn:first-child {
            transform: translateY(calc(100% + 0.5rem));
          }
          .lang--btn:nth-child(2) {
            transform: translateY(calc(200% + 1rem));
          }
          .lang--btn:nth-child(3) {
            transform: translateY(calc(300% + 1.5rem));
          }
        }
      }

    }

    &--booking:hover {
      background-color: #ffb600;
      border-color: #ffb600;

      .mask {
        span {
          color: #000;
        }
        .text-top {
          transform: translateY(-100%);
          opacity: 0;
          transition: transform 0.3s, opacity 0.3s;
          transition-delay: .3s;
        }

        .text-bottom {
          transform: translateY(0);
          opacity: 1;
          transition: transform 0.3s, opacity 0.3s;
          transition-delay: .3s;
        }
      }
    }
    a:hover {
      text-decoration: none;
    }

    &--order {
      border: 2px #fff solid;
      border-radius: 32px;
      color: #fff;
      cursor: pointer;
      font-weight: 700;
      font-size: 1rem;
      line-height: 1rem;
      padding: 0.75rem 1rem;
      text-transform: uppercase;
      text-decoration: none;

      .mask {
        height: 1rem;
        position: relative;
        overflow: hidden;

        span {
          display: block;
          font-family: "Knockout";
        }

        .transition-text {
          transition: transform 0.3s, opacity 0.3s;
          transition-delay: .3s;
        }

        .text-top {
          transform: translateY(0);
          opacity: 1;
        }

        .text-bottom {
          transform: translateY(100%);
          opacity: 0;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          text-align: center;
        }
      }

      &:hover {
        border: 2px #fff solid;
        background-color: #fff;

        .mask {
          span {
            color: #000;
          }

          .text-top {
            transform: translateY(-100%);
            opacity: 0;
            transition: transform 0.3s, opacity 0.3s;
            transition-delay: .3s;
          }

          .text-bottom {
            transform: translateY(0);
            opacity: 1;
            transition: transform 0.3s, opacity 0.3s;
            transition-delay: .3s;
          }
        }
      }
    }

  }
  .gap-reset {
    @media only screen and (min-width: 1200px) {
      gap: 0;
    }
  }
}
.hidden-header {
  z-index: -1;
  pointer-events: none;
  opacity: 0;
}

.header-gap {
  display: none;
  background-color: black;
  height: 60px;
  
  @include desktop {
    height: 108px;
  }
}
</style>
