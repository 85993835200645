<template>
    <Swiper
        class="card"
        :breakpoints="swiperOptions.breakpoints"
        @swiper="onSwiper"  
        @slideChange="onSlideChange">
        <SwiperSlide>
          <div class="menu__wrapper">
            <span class="sugestions__menu">
              <span class="sugestions__menu-card">
                <div class="sugestions__menu-img">
                  <img src="https://resizer.staging.deliverect.com/c4q9RA8OpZEYlG83syv7bh_FzH_j_zhQp6WxJ8JRB7s/rt:fill/g:ce/el:0/cb:4f4a827fca4946b9adc6c502b1f6e3e3/aHR0cHM6Ly9yZXNpemVyLmRlbGl2ZXJlY3QuY29tLzZ3ZnY1dkROOVI3NUhjNDJZQ0xhTTZpSzY4UnJIUXpSeE1qdEdzXzlJZmsvcnQ6ZmlsbC93OjExNDQvaDo3MDAvZzpjZS9lbDowL2NiOjIwMjIwMzIyLTEzL2FIUjBjRG92TDJWMWR6RXVjRzl6YVc5ekxtTnZiUzl3YjNOcGJXRm5aWE12WjJkdGJXeHpRR2R2YVd0dlozSnBiR3d1WTI5dFh6TTJNVGN5TDJsdFlXZGxjeTl3Y205a2RXTjBjeTgwTWpSaU1HRTJaalUwWmpZME0yTTRPVFZsTkdNd016RTNaVGxpTVRsallpNXFjR2M9LmpwZWc=.jpg" />
                </div>
                <h3>Nachorreo</h3>
                <p class="overflow-ellipsis">Nachos bañados en chili con carne, queso fundido (Enquésame), guacamole, nata agria, tomate picado y jalapeños.</p>
                <router-link :to="{ name: 'modal-delivery' }">
                  <button class="btn home-btn">Haz tu pedido</button>
                </router-link>
              </span>
            </span>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div class="menu__wrapper">
            <span class="sugestions__menu">
              <span class="sugestions__menu-card">
                <div class="sugestions__menu-img">
                  <img src="https://resizer.staging.deliverect.com/OIU_ZzdheDb53jVLRlKY0UlrYS-OsagEf7afiQic8dY/rt:fill/g:ce/el:0/cb:477ca525398247599a50aff4c58142b2/aHR0cHM6Ly9yZXNpemVyLmRlbGl2ZXJlY3QuY29tL1A5Mkp5ZFVYaHJqTlRUY0xtN293d0NLVlh4MU1nY1RsNlM1cjhTVXVBSXcvcnQ6ZmlsbC93OjExNDQvaDo3MDAvZzpjZS9lbDowL2NiOjIwMjIwMzIyLTEzL2FIUjBjSE02THk5emRHOXlZV2RsTG1kdmIyZHNaV0Z3YVhNdVkyOXRMMmxyYjI1aExXSjFZMnRsZEMxd2NtOWtkV04wYVc5dUwybHRZV2RsY3k4MVltWXdOVFF3TW1NMk5EZzVaakF3TW1JMlpXTTVabVl2UzBWV1NVNHROakZtTjJWaU1ERmhOV1V5TkdZek1ESmhNR0prT1dabUxtcHdadz09LmpwZWc=.jpg" />
                </div>
                <h3>Kevin Bacon</h3>
                <p class="overflow-ellipsis">Carne picada en la plancha con bacon bits, cebolla crunchy y queso americano. Acompañada de salsa Barbacoa Goiko.</p>
                <router-link :to="{ name: 'modal-delivery' }">
                  <button class="btn home-btn">Haz tu pedido</button>
                </router-link>
              </span>
            </span>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div class="menu__wrapper">
            <span class="sugestions__menu">
              <span class="sugestions__menu-card">
                <div class="sugestions__menu-img">
                  <img src="https://resizer.staging.deliverect.com/q8Endkihq5bj9ioEXGItt6AsToovIUfW-iag8UGA5-o/rt:fill/g:ce/el:0/cb:477ca525398247599a50aff4c58142b2/aHR0cHM6Ly9yZXNpemVyLmRlbGl2ZXJlY3QuY29tL0xZSUNTSE0zSUp2NDVldFdwU3g2ZzB4S1F6NDgzR2xSM2hNai1uWnplRUkvcnQ6ZmlsbC93OjExNDQvaDo3MDAvZzpjZS9lbDowL2NiOjIwMjIwMzIyLTEzL2FIUjBjSE02THk5emRHOXlZV2RsTG1kdmIyZHNaV0Z3YVhNdVkyOXRMMmxyYjI1aExXSjFZMnRsZEMxd2NtOWtkV04wYVc5dUwybHRZV2RsY3k4MVltWXdOVFF3TW1NMk5EZzVaakF3TW1JMlpXTTVabVl2V1dGdWEyVmxYekV3TWpSNE56WTRMVFl4WmpreU5USmhZMlptWVRKak4ySmxNREkwWmpoaE5TNXFjR2M9LmpwZWc=.jpg" />
                </div>
                <h3>Yankee</h3>
                <p class="overflow-ellipsis">Carne de vaca, costillas de cerdo deshuesadas y bañadas en salsa Barbacoa Goiko, queso americano, cebolla blanca a la plancha y lechuga batavia. Acompañada de salsa Barbacoa Goiko.</p>
                <router-link :to="{ name: 'modal-delivery' }">
                  <button class="btn home-btn">Haz tu pedido</button>
                </router-link>
              </span>
            </span>
          </div>
        </SwiperSlide>
      </Swiper>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      swiperOptions: {
        breakpoints: {
          335: { slidesPerView: 1, spaceBetween: -30 }, 
          355: { slidesPerView: 1, spaceBetween: -50 },
          395: { slidesPerView: 1, spaceBetween: -70 },
          340: { slidesPerView: 1, spaceBetween: -30 },
          430: { slidesPerView: 1, spaceBetween: -80 },
          450: { slidesPerView: 1, spaceBetween: -100 },
          490: { slidesPerView: 1, spaceBetween: -140 },
          570: { slidesPerView: 1, spaceBetween: -180 },
          590: { slidesPerView: 1, spaceBetween: -200 },
          670: { slidesPerView: 1, spaceBetween: -230 },
          720: { slidesPerView: 1, spaceBetween: -270 },
          755: { slidesPerView: 1, spaceBetween: -290 },
          768: { slidesPerView: 3, spaceBetween: 0 }
        }
      }
    }
  },
}
</script>

<style scoped lang="scss">
.sugestions__menu {
  display: flex;
  justify-content: center;
  padding-bottom: 50px;
  width: 100%;

  .sugestions__menu-card {
    background-color: $c-white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 6px;
    padding: 0 0 30px 0;
    width: 265px;
    h3 {
      color: $c-red;
      font-family: $font__headings;
      font-size: $fs-s-medium;
      text-align: center;
      text-transform: uppercase;
      padding: 0 10px;
    }

    img {
      border-radius: 10px;
      object-fit: cover;
      min-height: 240px;
      width: 100%;
    }

    p {
      font-size: $fs-m-small;
      font-weight: normal;
      padding: 0 20px;
      overflow: auto;
      white-space: nowrap;
      text-overflow: ellipsis;
      text-align: center;

      @supports (-webkit-line-clamp: 3) {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: initial;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
    }
  }
}

@include tablet {
  h2 {
    font-size: $fs-l-large;
    padding-top: 130px;
    padding-bottom: 20px;
  }

  p {
    font-size: $fs-xs-medium;
    max-width: unset;
  }

  .dinamyc-text {
    margin-bottom: 60px;
  }

  .sugestions__menu {
    .sugestions__menu-card {
      width: 320px;
      h3 {
        font-size: $fs-xl-medium;
      }
    }
  }

}

</style>
