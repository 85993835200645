<template>
  <span class="availability-error" v-if="!hasValidProducts">Debes seleccionar todos los campos</span>
  <span class="bundler__wrapper" v-for="(bundle) in product.bundles" :key="bundle.id">
    <h5>{{bundle.name}}</h5>
    <template v-for="idx in bundle.multiply" :key="idx">
        <Select2
          v-bind="$attrs"
          :options="chooseComboProducts(bundle.id, idx)"
          :settings="{ minimumResultsForSearch: Infinity }"
          v-model="comboMenu[`${bundle.id}-${idx}`]"
          @select="$emit('update:comboMenu', comboMenu)"
        />
    </template>
  </span>
</template>

<script>
import { mapState } from 'vuex'
import Select2 from 'vue3-select2-component'
export default {
  name: 'ComboMenu',
  emits: ['update:comboMenu', 'checkSelectedData', 'select'],
  components: {
    Select2
  },

  data() {
    return {
      comboMenu: {}
    }
  },

  methods: {
    chooseComboProducts(id) {
      let productsArr = []
      this.product.bundles.forEach(bundle => {
        if (bundle.id === id) {
          bundle.products.forEach(product => {
            productsArr.push({
              id: product.plu,
              text: product.name
            })
          })
        }
      })
      this.$emit('checkSelectedData')
      return productsArr
    }
  },

  computed: {
    ...mapState('Catalog', ['product']),

    hasValidProducts() {
      let sum = 0;
      this.product.bundles.forEach(bundle => sum += bundle.multiply)
      if (Object.keys(this.comboMenu).length === sum) {
        return true 
      }
      return false
    }
  },
}
</script>

<style scoped lang="scss">

.availability-error {
    float: right;
    display: block;
    font-size: $fs-s-small;
    margin: 0;
    margin-right: 21px;
    text-align: center;
    color: rgba($c-notice, .7);
  }
.bundler__wrapper {

  h2 {
      font-family: $font__headings;
      font-size: $fs-l-meduim;
      margin: 0;
      text-transform: uppercase;
  }

  p {
      font-size: $fs-s-small;
  }

  h5 {
    font-size: $fs-m-small;
    margin: 15px 20px;
    position: relative;

    &::after {
      content: " ";
      display: block;
      position: absolute;
      width: 100%;
      height: 1px;
      background-color: #cacaca;
      bottom: 0;
    }
  }
}
</style>