import { apiClient } from '@/services/ApiClient'

const state = {
	catalog: [],
	restaurant: [],
	product: null,
	toggleModal: false
}

const actions = {
	restaurant({ commit, rootGetters }) {
		return new Promise((resolve, reject) => {
			const restaurant = rootGetters['Cart/getRestaurantInfo']
			const getIfAdult = rootGetters['Cart/getIfAdult']
			// dispatch('Cart', null, {root: true})
			if (!restaurant) {
				reject()
			}
			const isAdult = getIfAdult ? '?adult' : ''
			apiClient.get(`/catalog/${restaurant.id}${isAdult}`)
				.then(response => {
					commit('DO_RESTAURANT', response.data)
					resolve(response.data)
				})
				.catch(err => {
					console.log(err)
					reject(err.response.data)
				})
		})
	},

	products({commit, rootGetters}, code) {
		return new Promise((resolve, reject) => {
			const restaurant = rootGetters['Cart/getRestaurantInfo']
			apiClient.get(`/catalog/${restaurant.id}/products/${code}`)
				.then(response => {
					let product = response.data
					product.modifiersGroups.forEach(modifierGroup => modifierGroup.max == null ? modifierGroup.max = modifierGroup.modifiers.length : modifierGroup.max)
					commit('DO_PRODUCTS', product),
					resolve(product)
				})
				.catch(err => {
					commit('RESET_PRODUCT', code)
					reject(err.response.data)
				})
		})
	},
	toggleModalToModifyProduct({ commit }, value) {
		commit('TOGGLE_MODAL', value)
	}
}

const mutations = {
	DO_RESTAURANT(state, catalog) {
		state.catalog = catalog
	},

	DO_PRODUCTS(state, product) {
		state.product = product
	},

	RESET_PRODUCT(state, plu) {
		state.catalog.forEach(element => {
			const index = element.products.findIndex( product => product.plu === plu )
			element.products.splice( index, 1 )
		})
	},
	TOGGLE_MODAL(state, value) {
		state.toggleModal = value
	}
}

export default {
	namespaced: true,
	state,
	actions,
	mutations,
}