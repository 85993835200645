<template>
  <div class="user-profile__container">
    <div class="icon">
      <svg @click="handlerModal" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" id="Layer_1" style="enable-background:new 0 0 512 512;" version="1.1" viewBox="0 0 512 512" xml:space="preserve"><path d="M437.5,386.6L306.9,256l130.6-130.6c14.1-14.1,14.1-36.8,0-50.9c-14.1-14.1-36.8-14.1-50.9,0L256,205.1L125.4,74.5  c-14.1-14.1-36.8-14.1-50.9,0c-14.1,14.1-14.1,36.8,0,50.9L205.1,256L74.5,386.6c-14.1,14.1-14.1,36.8,0,50.9  c14.1,14.1,36.8,14.1,50.9,0L256,306.9l130.6,130.6c14.1,14.1,36.8,14.1,50.9,0C451.5,423.4,451.5,400.6,437.5,386.6z"/></svg>
    </div>
    <template v-if="!isOrderDetail">
      <h1 class="userHello">¡Hola, {{user.name}}!</h1>
      <p>{{updatedInfoMsg}}</p>
      <template v-if="ordersHistory.length === 0 && !isEditActive">
        <span class="text">
          <p>Aún no has realizado ningún pedido.</p>
          <span @click="startOrder">Empieza a llenar tu historial del mejor chorreo.</span>
        </span>
      </template>
        <template v-else>
        <template v-if="!isEditActive">
          <h4 v-if="!isOrderDetail">Tu historial de pedidos:</h4>
            <div class="table-wrapper">
              <div class="container" v-for="order in ordersHistory" :key="order.id">
                <div class="container-left">
                  <strong>{{order.numOrder}}</strong>
                  <div class="info">
                    <span>{{formatDate(order.createdAt)}}</span>
                    <span>{{$filters.formatEUR(Number(order.total), true)}}</span>
                  </div>
                  <span class="article" @click="getId(order.id, order)">Ver {{order.products.length}} art<template v-if="order.products.length > 1">s</template>.</span>
                </div>
                <div class="status">
                  <strong :class="setOrderStatusMessage(order.status).class">{{setOrderStatusMessage(order.status).text}}</strong>
                  <span v-if="getPreparingOrder(order.status)" @click="trakingOrder(order.token)" class="order-link">Ver seguimiento</span>
                </div>
              </div>
            </div>
        </template>
      </template>
    </template>
    <span v-if="isLoading" class="spinner"></span>
    <span v-else class="detail-order">
      <OrderDetail v-if="isOrderDetail" :order="detailedOrder"/>
    </span>
    <div v-if="isEditActive" class="form__field-column">
      <div class="form__field-wrapper">                     
        <label>Nombre:</label>
        <input required type="text" v-model="form.name" name="name">
        <div class="errors-input" v-show="errors.hasOwnProperty('name')">
          <div v-for="(error, key) in errors.name" :key="key">{{ $t(error) }}</div>
        </div>
      </div>
      <div class="form__field-wrapper">                     
        <label>Apellidos:</label>
        <input required type="text" v-model="form.lastName" name="lastName">
        <div class="errors-input" v-show="errors.hasOwnProperty('lastName')">
          <div v-for="(error, key) in errors.lastName" :key="key">{{ $t(error) }}</div>
        </div>
      </div>
      <div class="form__field-wrapper">                     
        <label>Teléfono:</label>
        <input required type="text" v-model="form.phone" name="phone" placeholder="+34">
        <div class="errors-input" v-show="errors.hasOwnProperty('phone')">
          <div v-for="(error, key) in errors.phone" :key="key">{{ $t(error) }}</div>
        </div>
      </div>
      <div class="form__field-wrapper">                       
        <label>¿Somos FRIENDS WITH BENEFITS?</label>
        <textarea cols="30" rows="10" v-model="form.goikoId" placeholder="GOIKO ID"></textarea>
        <div v-for="(error, key) in errors.goikoId" :key="key" class="errors-input">GOIKO ID incorrecto</div>
      </div>
        <div class="form__field-wrapper">
          <label class="checkbox">
          <input type="checkbox" v-model="form.isAdult" />
          <div>Soy mayor de 18 años</div>
          </label>
          <label class="checkbox">
            <input type="checkbox" v-model="form.acceptNotification" />
            <div>Acepto recibir comunicaciones electrónicas comerciales de la marca</div>
          </label>
      </div>
    </div>
    <template v-if="!isEditActive">
      <span class="button-wrapper">
        <template v-if="!isOrderDetail">
          <button class="btn primary" @click.stop="editProfileToggle">Editar perfil</button>
        </template>
        <template v-else>
          <button v-if="!isLoading" class="btn primary" @click="handlerModal">Volver</button>
        </template>
        <button v-if="!isOrderDetail" class="btn" @click="logout">Cerrar sesión</button>
      </span>
    </template>
    <button v-else @click.stop="completeEditUserInfo" class="btn edit">Guardar perfil</button>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import OrderDetail from '../OrderDetail.vue'
import { setOrderStatusMessage, formatDate, getPreparingOrder } from '../../commonMethods/methods'
export default {
  name: 'UserButton',
  components: {
    OrderDetail,
  },
  props: {
    color: {
      type: String,
      default: ''
    },
    isCartOpen: Boolean
  },

  data() {
    return {
      isOrderDetail: false,
      isEditActive: false,
      updatedInfoMsg: '',
      isCatalog: false,
      form: {
        name: '',
        lastName: '',
        phone: '',
        isAdult: false,
        acceptNotification: false,
        goikoId: ''
      },
      errors: [],
      isLoading: false,
    }
  },
  computed: {
    ...mapState('User', ['user', 'ordersHistory', 'detailedOrder']),
  },
  methods: {
    ...mapActions('Auth', ['logout']),
    ...mapActions('User', ['fetchProfile', 'getOrdersHistoryFromUser', 'getOrderById', 'updateUserInfo']),
    ...mapActions('Cart', ['getOrderByToken']),
    setOrderStatusMessage, formatDate, getPreparingOrder,
    handlerModal() {
      if (!this.isEditActive && !this.isOrderDetail) {
        this.$emit('handlerModal')
      }
      this.isEditActive = false
      this.isOrderDetail = false
    },
    editProfileToggle() {
      this.fetchProfile()
        .then(({ name, lastName, phone, goikoId, isAdult,acceptNotification }) => {
          this.form.name = name
          this.form.lastName = lastName
          this.form.phone = phone
          this.form.goikoId = goikoId
          this.form.isAdult = isAdult
          this.form.acceptNotification = acceptNotification
        })
      setTimeout(() => {
        this.isEditActive = true
        this.isCatalog = true
        this.updatedInfoMsg = ''
        this.errors = []
      }, 180);
    },
    getId(id) {
      this.isLoading = true
      this.getOrderById(id)
        .finally(() => this.isLoading = false)
      this.isOrderDetail = !this.isOrderDetail
      this.updatedInfoMsg = ''
    },
    trakingOrder(token) {
      this.$router.push({ path: '/pago-exitoso', query: { token }})
      this.$emit('handlerModal')
    },
    startOrder() {
      this.$router.push({name: 'modal-delivery'})
      this.$emit('handlerModal')
    },
    async completeEditUserInfo() {
      this.updateUserInfo(this.form)
        .then(() =>  {
          this.isEditActive = false
          this.updatedInfoMsg = '¡Perfil actualizado!'
          this.errors = []
        })
        .catch(err => this.errors = err)
    },
  }
}
</script>
<style scoped lang="scss">
  .form__field-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: $fs-s-small;
    margin-top: 10px;
    padding: 0 30px;

    .form__field-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 30px;
      width: 100%;
      margin: 10px 0;
      padding: 0;

      &:first-child {
        margin-top: 20px;
      }

      label {
        font-family: $font__main;
        margin: -5px 0;
      }

      textarea {
        height: 44px;
      }

      .errors-input {
        color: $c-red;
        font-family: $font__main;
        margin-top: -5px;
        margin-bottom: 10px;
      }

      .checkbox{
        margin: 25px;
        margin-left: 20px;
        margin-top: -15px;
      }
    }
  }
  .user-profile__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $c-black;
    height: 100%;
    padding: 10px;
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
    h1 {
      font-family: $font__headings;;
    }
    .detail-order {
      width: 100%;
      margin-top: -20px;
    }
    h4 {
      font-family: $font__main;
      font-size: $fs-xs-medium;
      // margin: 25px 0;
      margin: 16px 0;
      margin-left: 58px;
      text-align: start;
      width: 100%;
    }
    .table-wrapper {
      display: flex;
      flex-direction: column;
      font-size: $fs-m-small;
      margin-bottom: 20px;
      min-height: 60%;
      overflow: auto;
      padding: 0 30px;
      width: 100%;
      .status {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-transform: uppercase;
        strong {
          &.in-process {
            color: $c-black;
          }

          &.delivered {
            color: $c-green;
          }

          &.failed {
            color: $c-red;
          }
        }
        &--failed {
          color: $c-red;
        }
        .order-link {
          color: $c-black;
          cursor: pointer;
          margin-top: 5px;
          text-transform: initial;
          text-decoration: underline;
        }
      }
      .container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px 0;
        border-bottom: 1px solid #F2F2F2;
      }
      .container-left {
        display: flex;
        flex-direction: column;
        .info {
          display: flex;
          align-items: center;
          margin-top: 5px;
          margin-bottom: 5px;
          > span {
            padding-right: 10px;
          }
        }
        .article {
          cursor: pointer;
        }
        strong {
          font-size: $fs-l-small;
          text-transform: uppercase;
        }
      }
    }
    .icon {
      display: flex;
      justify-content: flex-start;
      fill: white;
      padding: 20px;
      width: 100%;
      svg {
        border-radius: 50%;
        cursor: pointer;
        height: 25px;
        padding: 5px;
        width: 25px;
      }
    }
    h1 {
      margin: 0;
    }
    p {
      color: $c-green;
      font-family: $font__main;
      margin: 10px;
    }
    svg {
      background: $c-black;
    }
    h5 {
      text-align: start;
    }
    .text {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 50%;
      padding: 0 20px;
      text-align: center;
      p {
        color: $c-black;
        margin: 3px;
      }
      span {
        color: $c-black;
        margin-left: 5px;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
  .button-wrapper {
    height: 60%;
    display: flex;
    align-items: flex-end;
  }
  .btn {
    margin: 5px;
    margin-bottom: 10px;
    height: 50px;
    &.primary {
      background-color: $c-black;
    }
  }
  // @include tablet {
  //   left: 60%;
  // }
  // @include desktop {
  //   left: 70%;
  //   top: 70px;
  // }
  .logout {
    cursor: pointer;
    fill: $c-white;
    position: absolute;
    width: 27px;
    right: 60px;
  }
  .userHello {
    text-transform: uppercase;
    font-size: 24px;
  }
</style>
