<template>
  <div @click.self="cancelCouponProcess" class="modal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div v-if="isPendingPromotionValid" class="loading spn-coupons">
          <span class="spinner"></span>
        </div>
        <template v-else>
          <div v-if="canContinue === false" class="products-container">
            <template v-for="trigger in triggers" :key="trigger.id">
              <p :class="{ 'triggers-error': triggerChosenProducts[trigger.id].length !== trigger.quantity && triggersToValidate[trigger.id]?.quantity !== -1 }">
                Por favor seleccione {{ trigger.quantity }} {{ trigger.quantity === 1 ? trigger.category.slice(0, -1) : trigger.category }}
              </p>
              <span 
                v-for="(product) in trigger.products" :key="product.id" @click="handleSelection(product, trigger)"
                :class="{ 'selected-border': triggerChosenProducts[trigger.id].find(triggerProduct => triggerProduct.id === product.id) }">
                {{ product.name }}
              </span>
            </template>
          </div>
          <div v-if="canContinue && pendingCoupons[0].promotionToChose !== null" class="products-container">
            <p :class="{ 'triggers-error': promotionChosenProducts.length !== maxProductsToChoose && promotionProducts.quantity !== -1 }">
              Por favor seleccione {{ maxProductsToChoose }} {{ maxProductsToChoose === 1 ? 'producto' : 'productos' }}
            </p>
            <span 
              v-for="product in promotionProducts.products" :key="product.id" @click="handleSelection(product, null)"
              :class="{'selected-border': promotionChosenProducts.find(promotionProduct => promotionProduct.id === product.id)}"
              >
              {{ product.name }}
            </span>
          </div>
          <div class="cupon-btn-wrapper">
            <span @click="cancelCouponProcess">Cancelar</span>
              <span 
                :class="{'disabled': toggleDisable}"  
                :disabled="toggleDisable"
                @click="checkCoupon"
                >
                Confirmar
              </span>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'DiscountProducts',
  props: {
    pendingCoupons: {
      type: Array,
      default: [],
    },
    isPendingPromotionValid: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isModalOpen: false,
      maxProductsToChoose: 0,
      triggers: [],
      promotionProducts: {},
      triggerChosenProducts: {},
      promotionChosenProducts: [],
      canContinue: false,
      triggersToValidate: {},
      currentTrigger: null,
    }
  },
  computed: {
    maxTriggersQuantity() {
      let quantity = 0
      const triggersId = Object.keys(this.triggerChosenProducts)

      if (triggersId.length > 0) {
        triggersId.forEach((id) => {
          quantity += this.triggerChosenProducts[id].length
        })
      }
      return quantity
    },
    toggleDisable() {
      return (
        (this.triggerChosenProducts[this.currentTrigger]?.length !== this.triggersToValidate[this.currentTrigger]?.quantity ||
        this.maxTriggersQuantity !== this.maxProductsToChoose && this.canContinue === false) ||
        (this.canContinue && this.promotionChosenProducts.length !== this.maxProductsToChoose)
      )
    }
  },
  methods: {
    handleSelection(product, trigger) {
      if (trigger !== null) {
        this.canContinue = false
        this.currentTrigger = trigger.id
        this.triggersToValidate[trigger.id] = {
          id: trigger.id,
          quantity: trigger.quantity,
        }

        this.checkExistingCoupon(this.triggerChosenProducts[trigger.id], product)
      } else {
        this.promotionProducts.quantity = this.pendingCoupons[0].promotionToChose.quantity
        this.checkExistingCoupon(this.promotionChosenProducts, product)
      }
    },
    checkExistingCoupon(products, productSelected) {
      const index = products.findIndex(product => product.id === productSelected.id)

      if (index !== -1) {
        products.splice(index, 1)
      } else {
        products.push(productSelected)
      }
    },
    checkCoupon() {
      if (this.toggleDisable) {
        return
      }

      if (!this.canContinue) {
        const triggersId = Object.keys(this.triggerChosenProducts)
        const validateTrigger = triggersId.map((id) => {
          return {
            id: +id,
            products: this.triggerChosenProducts[id].map(product => product.plu)
          }
        })

        this.maxProductsToChoose = this.pendingCoupons[0].promotionToChose?.quantity
        this.$emit('validateTrigger', validateTrigger)
        this.canContinue = true
      } else {
        const validatePromotionsProducts = this.promotionChosenProducts.map(promotion => (promotion.plu))
        this.$emit('validatePromotion', validatePromotionsProducts)
        this.canContinue = null
      }
    },

    cancelCouponProcess() {
      this.$emit('cancelCouponProcess', false)
    }
  },


  mounted() {
    let id = 1

    if (this.pendingCoupons[0].triggersToChose.length > 0) {
      this.triggers = this.pendingCoupons[0].triggersToChose.map(trigger => {
        this.maxProductsToChoose += trigger.quantity
        this.triggerChosenProducts[trigger.id] = []
        
        this.triggersToValidate[trigger.id] = {
            id: trigger.id,
            quantity: -1,
          }
        
        return {
          ...trigger,
          products: trigger.products.map(product => ({
            ...product,
            id: id++
          }))
        }
      })
    }

    id = 1
    if (this.pendingCoupons[0].promotionToChose !== null) {
      if (!this.pendingCoupons[0].triggersToChose.length) {
        this.canContinue = true
        this.maxProductsToChoose = this.pendingCoupons[0].promotionToChose?.quantity
      }
      
      this.promotionProducts = {
        products: this.pendingCoupons[0].promotionToChose.products.map(promotion => ({
          ...promotion,
          id: id++
        })),
        quantity: -1
      }
    }
  },
}
</script>
  
<style scoped lang="scss">
.modal {
  z-index: 301 !important;
  .modal-dialog {
    .modal-content {
      background-color: $c-white;
      padding: 30px;
      padding-top: 0;
      height: unset;
      max-height: 80vh;
      // margin-top: 40px;
      overflow-y: scroll;

      .spn-coupons {
        padding: 30px;
      }

      p {
        all: unset;
      }

      .products-container {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-top: 20px;

        .triggers-error {
          color: $c-red;
        }

        span {
          background-color: #f0f0f0;
          border-radius: 10px;
          cursor: pointer;
          padding: 16px;
        }

        .selected-border {
          border: 1px solid $c-red;
        }
      }

      .cupon-btn-wrapper {
        display: flex;
        justify-content: space-between;
        font-family: $font__headings;
        margin-top: 40px;
        text-transform: uppercase;

        span {
          cursor: pointer;
        }

        span:last-child {
          color: $c-red;
        }

        .disabled {
          // cursor: auto;
          opacity: .5;
          // pointer-events: none;
        }
      }  

    }

    @include tablet {
      width: 300px;

      .modal-content {
        margin-top: 0;
        padding-top: 20px;
      }
    }
  }
}
</style>
