<template>
  <div class="container">
    <h1>Condiciones de uso de la web (Aviso legal)</h1>
    <ol>
    <li>Condiciones de Uso y su aceptación</li>
    <li>Acceso y uso de la Web</li>
    <li>Tratamiento de los datos de los Usuarios</li>
    <li>Garantías y responsabilidad</li>
    <li>Denegación de acceso a la Web y/o MEDIO DIGITAL y retirada de credenciales</li>
    <li>Propiedad intelectual e industrial</li>
    <li>Ley aplicable y resolución de conflictos</li>
  </ol>
    <div>
      <ol>
        <li id="title">Condiciones de Uso y su aceptación</li>
        <p>
          Las presentes Condiciones regulan el uso de la Web y/o MEDIO DIGITAL responsabilidad de GOIKO GRILL GROUP, S.L. con domicilio social en 
          C/ Maestro Ángel Llorca, 6, 5o , 28003 – Madrid (Madrid); sociedad inscrita en el Registro Mercantil de Madrid Tomo 35926, de la sección 8a, Folio 191, Hoja 645523 , 
          inscripción primera, provista de CIF ESB87771945, La utilización de la Web y/o MEDIO DIGITAL atribuye la condición de usuario y expresa la conformidad plena y sin reservas
          de éste a todas y cada una de las condiciones generales y/o particulares, con respecto a la versión publicada en el momento del acceso del Usuario. Por ello, se recomienda al 
          Usuario que lea detenidamente su contenido. 
          <br>
          A través de la Web y/o MEDIO DIGITAL, GOIKO facilita a los Usuarios el acceso y la utilización de diversos servicios y contenidos puestos a disposición por GOIKO o 
          por terceros usuarios de la Web y/o MEDIO DIGITAL y/o terceros proveedores de servicios y contenidos (en adelante, los «Servicios»). 
          <br>
          GOIKO se reserva el derecho a modificar unilateralmente, en cualquier momento y sin aviso previo, la presentación 
          y configuración de la Web, así como también se reserva el derecho a modificar o eliminar, en cualquier momento y sin previo aviso, 
          los Servicios y las condiciones requeridas para acceder y/o utilizar la Web y sus Servicios.
        </p>

        <li id="title">Acceso y uso de la Web</li>
        <p>2.1. Carácter gratuito del acceso y utilización de la Web
          <br>
          Los Servicios prestados por medio de la Web y/o MEDIO DIGITAL tienen carácter 
          gratuito para los usuarios, sin embargo, en ocasiones requerirá el Registro en la Web y/o MEDIO DIGITAL. El registro no está permitido a los menores de 16 años.
          <br>
          2.2. Obligación de hacer un uso correcto de la Web y/o MEDIO DIGITAL y de los Servicios El Usuario se compromete a utilizar la Web y/o MEDIO DIGITAL 
          y los Servicios de conformidad con la Ley, con lo dispuesto en estas Condiciones, las buenas costumbres, el orden público, y los usos generalmente aceptados,
          y se obliga a abstenerse de utilizar la Web y/o MEDIO DIGITAL y los Servicios con fines o efectos ilícitos, contrarios a lo establecido en las presentes Condiciones, 
          lesivos de los derechos e intereses y la imagen de terceros, o que de cualquier forma puedan dañar, inutilizar, sobrecargar o deteriorar la Web y/o MEDIO DIGITAL 
          y los Servicios o impedir la normal utilización o disfrute de la Web y/o MEDIO DIGITAL y sus Servicios por parte de los Usuarios.
          <br>
          2.3. Uso correcto de los Contenido por el Usuario
          <br>
          El Usuario se obliga a usar informaciones, mensajes, gráficos, dibujos, archivos de sonido y/o imagen, fotografías, grabaciones, software y, en general, 
          cualquier clase de material accesible a través del Portal o de los Servicios (en adelante, los «Contenidos») de forma diligente, correcta y lícita y, en particular, 
          se compromete a abstenerse de:
          <br>
          (a) Utilizar los Contenidos de forma, con fines o efectos contrarios a la ley, las buenas costumbres y los usos generalmente aceptados o al orden público;
          <br>
          (b) Suprimir, eludir o manipular los derechos de propiedad intelectual o industrial de GOIKO así como de cualquier titular de marca, así como el resto de datos 
          identificativos o derechos de GOIKO o de cualquiera de sus titulares que figuren en los Contenidos, así como los dispositivos técnicos de protección, 
          las huellas digitales o cualesquiera mecanismos de información que pudieren contener los Contenidos;
          <br>
          (c) Emplear los Contenidos y, en particular, la información de cualquier clase obtenida a través de la Web y/o MEDIO DIGITAL o de los Servicios para remitir publicidad, 
          comunicaciones con fines de venta directa o con cualquier otra clase de finalidad comercial, mensajes no solicitados dirigidos a una pluralidad de personas 
          con independencia de su finalidad, así como a abstenerse de comercializar o divulgar de cualquier modo dicha información;
          <br>
          (d) y en especial, a realizar cualquier acto contrario a las normas que regula la protección de datos de carácter personal, el mercado, los consumidores y la Propiedad Intelectual.
          El incumplimiento por el Usuario de cualquiera de estas obligaciones llevará aparejada la/s acción/es legales oportunas.
          <br>
          2.4.- Enlaces e Hiperenlaces
          <br>
          Los Usuarios y, en general, aquellas personas que se propongan establecer un hiperenlace entre su página web y cualquiera de las páginas de la Web y/o MEDIO DIGITAL
          o de los Servicios (en adelante, el «Hiperenlace») deberán cumplir las condiciones siguientes:
          el Hiperenlace únicamente permitirá el acceso a las páginas de la Web y/o MEDIO DIGITAL y a los Servicios, pero no podrá reproducirlos de ninguna forma;
          no se establecerán deep-links o hiperenlaces con las páginas de la Web y/o MEDIO DIGITAL o los Servicios;
          <br>
          2.5. Responsabilidad por daños y perjuicios
          <br>
          El Usuario responderá de todos y cada uno de los daños (presentes, probables y presumibles) y perjuicios de toda naturaleza que GOIKO pueda sufrir como consecuencia de incumplimiento de cualquiera de las obligaciones a las que queda sometido por las Condiciones, y dispuesto en los artículos aquí recogidos.
          GOIKO se reserva el derecho de incoar las acciones legales que en derecho le pudieran asistir.
        </p>

        <li id="title">Tratamiento de los datos de los Usuarios</li>
        <p>
          Para acceder a y/o utilizar algunos de los Servicios es necesario que los Usuarios proporcionen previamente a GOIKO ciertos datos de carácter personal 
          (en adelante, los «Datos Personales»), que GOIKO tratará automatizadamente e incorporará a un fichero, con las finalidades de prestar los servicio de la Web y/o MEDIO DIGITAL.
          <br>
          Todas estas circunstancias serán previa y debidamente advertidas por GOIKO a los Usuarios, en los casos y en la forma en que ello resulta legalmente exigible.
          <br>
          Si el Usuario facilita datos de carácter personal de terceras personas, informará a dichos terceros del contenido de los datos facilitados, de la existencia y 
          finalidad del fichero donde se contienen sus datos, de la posibilidad de ejercitar los derechos, identificará a GOIKO y deberá obtener el consentimiento de dicho 
          tercero para comunicar los datos de éste a GOIKO.
          <br>
          GOIKO garantiza que ha adoptado las medidas oportunas de seguridad en sus instalaciones, sistemas y ficheros. Asimismo, GOIKO garantiza la confidencialidad de los Datos Personales. 
          Ello no obstante, GOIKO revelará a las autoridades públicas competentes los Datos Personales y cualquier otra información que esté en su poder o sea accesible a través de sus sistemas 
          y sea requerida de conformidad con las disposiciones legales y reglamentarias aplicables al caso.
          <br>
          Los Usuarios tienen reconocidos y podrán ejercitar los derechos de acceso, supresión, rectificación y oposición, revocar su consentimiento, limitar su tratamiento y 
          solicitar la portabilidad de sus datos en cualquier momento, contactando con GOIKO siguiendo el procedimiento para el ejercicio de derechos descrito a tal efecto en la Política de 
          Protección de Datos.
        </p>

        <li id="title">Garantías y responsabilidad</li>
        <p>
          4.1. Exclusión de garantías y de responsabilidad por el funcionamiento de la Web y/o MEDIO DIGITAL y de los Servicios
          <br>
          GOIKO no garantiza la disponibilidad y continuidad en cuanto al funcionamiento de la Web y/o MEDIO DIGITAL o a la información, contenido, software, materiales, 
          o productos incluidos en el mismo en la medida que lo permita la legislación aplicable.
          <br>
          De esta forma, se excluye cualquier responsabilidad por los daños y perjuicios de cualquier naturaleza que puedan derivarse de la falta de disponibilidad 
          o de continuidad del funcionamiento de la Web y/o MEDIO DIGITAL y de sus servicios y la utilidad que los usuarios hubieran podido atribuir al presente sitio Web y/o MEDIO DIGITAL.
          <br>
          En cualquier caso, GOIKO realizará sus mejores esfuerzos de cara a mantener la disponibilidad continuada de la presente Web y/o MEDIO DIGITAL.
          <br>
          Asimismo, GOIKO no se hace responsable de los posibles errores o deficiencias de seguridad que pudieran producirse por la utilización, por parte del Usuario, 
          de un navegador de una versión no actualizada o insegura, así como por la activación de los dispositivos de conservación de claves o códigos de identificación del 
          Usuario registrado en el navegador o de los daños, errores o inexactitudes que pudieran derivarse del mal funcionamiento del mismo.
          <br>
          GOIKO ha implantado todas las medidas de seguridad técnicas y organizativas necesarias que garanticen la integridad, confidencialidad y disponibilidad de los datos de 
          carácter personales facilitados por el usuario.
          <br>
          El usuario acepta que la Web y/o MEDIO DIGITAL ha sido creada y desarrollada de buena fe por GOIKO con información procedente de fuentes internas y externas y
          lo ofrece en su estado actual a los usuarios, pudiendo contener imprecisiones o erratas. Por ello, el usuario exonera a GOIKO de cualquier responsabilidad
          en relación con la fiabilidad, utilidad o falsa expectativa que la Web y/o MEDIO DIGITAL pudiera producirle durante su navegación por el mismo.
          <br>

          4.2. Exclusión de garantías y de responsabilidad por los servicios y contenidos alojados fuera de la Web y/o MEDIO DIGITAL
          <br>
          La Web y/o MEDIO DIGITAL podría poner a disposición de los Usuarios dispositivos técnicos de enlace (tales como, entre otros, links, banners, botones), 
          directorios y herramientas de búsqueda que permiten a los Usuarios acceder a sitios web pertenecientes a terceros (en adelante, «Sitios Enlazados»). 
          En ese caso, la instalación de estos enlaces, directorios y herramientas de búsqueda en la Web y/o MEDIO DIGITAL tiene por único objeto facilitar a los Usuarios 
          la búsqueda de y acceso a la información disponible en Internet, y no presupone que exista ninguna clase de vínculo o asociación entre GOIKO y los operadores de los Sitios Enlazados.
          GOIKO no controla previamente, aprueba ni hace propios los servicios, información, datos, archivos, productos y cualquier clase de material existente en los Sitios Enlazados. 
          El Usuario, por tanto, debe extremar la prudencia en la valoración y utilización de los servicios, información, datos, archivos, productos y cualquier clase de material 
          existente en los Sitios Enlazados.
          <br>
          GOIKO excluye plenamente su responsabilidad por cualquier daño o perjuicio de cualquier clase que derive o pueda derivar del (a) funcionamiento, disponibilidad, accesibilidad de los servicios enlazados; (b) del mantenimiento de información, o cualquier material existente en los sitios, (c) la prestación o transmisión de los servicios enlazados en los mismos términos y con el mismo alcance recogido en la condición 4.2.
        </p>

        <li id="title">Propiedad intelectual e industrial</li>
        <p>
          GOIKO autoriza al Usuario la utilización de los derechos de propiedad intelectual e industrial y de los secretos empresariales relativos al Software instalado 
          en su equipo informático únicamente para utilizar la Web y/o MEDIO DIGITAL y los Servicios de conformidad con lo establecido en estas Condiciones. 
          GOIKO no concede ninguna otra licencia o autorización de uso de ninguna clase sobre sus derechos de propiedad industrial e intelectual, secretos empresariales o 
          sobre cualquier otra propiedad o derecho relacionado con la Web y/o MEDIO DIGITAL, los Servicios o los Contenidos.
          <br>
          En el caso de que cualquier Usuario o un tercero considere que cualquiera de los Contenidos han sido introducido en la Web y/o MEDIO DIGITAL 
          con violación de sus derechos de propiedad intelectual deberá enviar una notificación a GOIKO en la que se contengan los siguientes extremos: (a) datos personales: nombre, 
          dirección, número de teléfono y dirección de correo electrónico del reclamante; (b) firma auténtica o equivalente, con los datos personales del titular de los derechos de 
          propiedad intelectual supuestamente infringidos o de la persona autorizada para actuar en nombre y por cuenta del titular de los derechos de propiedad intelectual 
          supuestamente infringidos; (c) indicación precisa y completa de los Contenidos protegidos mediante los derechos de propiedad intelectual supuestamente infringidos, 
          así como de su localización en la Web y/o MEDIO DIGITAL; (d) declaración expresa y clara de que la introducción de los Contenidos indicados se ha realizado 
          sin el consentimiento del titular de los derechos de propiedad intelectual supuestamente infringidos; (e) declaración expresa, clara y bajo la responsabilidad del 
          reclamante de que la información proporcionada en la notificación es exacta y de que la introducción de los contenidos constituye una violación de sus derechos de propiedad intelectual.
          <br>
          Estas notificaciones deberán ser enviadas a GOIKO, C/ Maestro Ángel Llorca, 6, 5o, 28003 – Madrid (Madrid)
        </p>

        <li id="title">Ley aplicable y resolución de conflictos</li>
        <p>
          Estas Condiciones se rigen por la ley española.
          <br>
          GOIKO y el Usuario, con renuncia expresa a cualquier otro fuero, se someten al de los Juzgados y Tribunales del domicilio social de GOIKO para cualquier 
          controversia que pudiera derivarse de la prestación de los servicios objeto de estas Condiciones. En el caso de que el Usuario tenga su domicilio fuera de España, 
          igualmente GOIKO y el Usuario se someten, con renuncia expresa a cualquier otro fuero, a los juzgados y tribunales de la ciudad de Madrid.
        </p>
      </ol>
    </div>
  </div>
  <BodyFooter />
</template>

<script>
import BodyFooter from '../../components/BodyFooter.vue'
export default {
  name: 'LegalNoti',
  components: {
    BodyFooter
  },
  mounted() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }
}
</script>

<style scoped lang="scss">

  .container {
    font-size: 26px;
    margin: 60px auto 150px auto;
    max-width: 1200px;
    padding: 0 30px;

    h1 {
      font-size: 70px;
      text-transform: uppercase;
    }
    
    #title {
      font-weight: 900;
    }
  }
</style>