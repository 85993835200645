<template>
  <div @click="goBack" class="back-wrapper" :class="color">
    <svg width="20" height="20" viewBox="0 0 11 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.70832 -4.63764e-08L10.7693 1.25L2.39322 10.0033L10.7693 18.75L9.70832 20L5.58295e-05 10.0033L9.70832 -4.63764e-08Z" fill="black"/>
    </svg>
    <p>Volver</p>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
export default {
  name: 'Back',
  props: {
    color: {
      type: String,
      default: ''
    },
  },
  computed: {
    ...mapState('Cart', ['productPlu'])
  },
  methods: {
    ...mapMutations('Cart', ['WAS_PRODUCT_CHOOSED']),
    goBack() {
      if (!this.productPlu) {
        this.WAS_PRODUCT_CHOOSED(false)
      }
      if (window.location.pathname === '/finalizar-compra') {
        this.$router.push({ name: 'catalog' })
      } else {
        this.$router.go(-1)
      }
    }
  },
}
</script>

<style scoped lang="scss">
.back-wrapper {
  color: $c-white;
  display: flex;
  justify-content: center;
  align-items: center;
  // position: absolute;
  left: 20px;
  cursor: pointer;

  svg {
    margin-right: 6px;
  }

  p {
    font-family: $font__main;
    font-size: $fs-s-small;
    font-weight: $fw-bold;
  }

  &.white {
    svg {
      path {
        fill: $c-white;
      }
    }
  }

  &.black {
    svg {
      path {
        fill: $c-black;
      }
    }
  }

  &.noBack {
    display: none;
  }
}

@include tablet {
  .back-wrapper {
    display: none;
  }
}
</style>