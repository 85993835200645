<template>
  <div class="container">
    <h1>Política de protección de datos</h1>
    <h2>Política de protección de datos de carácter personal</h2>
    <h3>Responsable de tratamiento</h3>
    <p>GOIKO GRILL GROUP, S.L. (En adelante, GOIKO) C/ Maestro Ángel Llorca, 6, 5o, 28003 – Madrid (Madrid) o a través de <a href="mailto:dpo@goiko.com">dpo@goiko.com</a></p>
    <h3>¿Qué datos tratamos y durante cuánto tiempo?</h3>
    <p>GOIKO tratará:</p>
    <ul>
      <li>Los datos de carácter personal facilitados con ocasión del registro, en general; identificativos, características personales, y de contacto electrónico, en particular</li>
      <li>Los datos autorizados en caso de registro por medio del login de redes sociales</li>
      <li>
        Datos derivados de la relación comercial con GOIKO, en general; los procedentes del uso de los distintos servicios, aquellos que facilites en el proceso de reservas, 
        compras y/o contratación, datos para efectuar/ autorizar el pago y facturar los productos y servicios, en particular
      </li>
      <li>
        Los resultantes de procesos informáticos (hábitos de navegación o uso del medio y/o entorno digital), Dirección IP e información recopilada a través de cookies, 
        etiquetas de píxel y otras tecnologías (para más información, consulta nuestra política de cookies)
      </li>
      <li>Aquellos que GOIKO pueda inferir a partir de datos lícitamente obtenidos.</li>
    </ul>
    <p>
      GOIKO tratará y almacenará tus datos mientras seas usuario activo en el medio y/o entorno digital y cliente GOIKO, así como durante el plazo de prescripción
       de obligaciones legales, que pudieran nacer de posibles responsabilidades vinculadas al tratamiento de los datos de cliente.
    </p>
    <h3>¿En qué consiste el usuario cuando facilita sus datos?</h3>
    <p>GOIKO, propietaria del medio y/o entorno digital, almacenará los datos de carácter personal necesarios, enumerados en el apartado precedente, con la finalidad de:</p>
    <ul>
      <li>
        Prestar el servicio que demandes como usuario/Cliente y que ofrece el medio y/o entorno digital, su mantenimiento y mejora, a base de estudios, elaboración de perfiles, 
        segmentaciones sobre tus datos y/o cruces con otros ficheros responsabilidad de GOIKO.
      </li>
      <li>
        Ofrecer promociones ligadas a la condición de usuario/ Cliente, a través de medios de comunicación electrónica o no, referentes a productos o 
        servicios del sector donde GOIKO desarrolla su actividad (fidelización, alimentación, hogar, consumo, ocio, tiempo libre, turismo, entre otros) 
        Puedes autorizar el tratamiento de tus datos con fines promocionales y la recepción de este tipo de envíos, en el proceso de registro y oponerte en 
        un momento posterior, siguiendo el procedimiento para el ejercicio de derechos descrito en el apartado 4.
      </li>
      <li>
        Facilitar el proceso de alta en el medio y/o entorno digital, a través de distintos canales. GOIKO, para efectuar el registro podrá desarrollar 
        un proceso que permita aprovechar los datos ya existentes del Usuario/ Cliente en otros ficheros de GOIKO y que resulten útiles para efectuar el alta; 
        en ese caso, se informará debidamente al Usuario/cliente, para obtener su consentimiento.
      </li>
    </ul>
    <h3>¿Puede el usuario/cliente oponerse a recibir comunicaciones comerciales o al tratamiento con fines promocionales?</h3>
    <p>
      Como Usuario/Cliente puedes oponerte en cualquier momento a recibir comunicaciones y al tratamiento de tus datos para fines comerciales y
      promocionales, contactándonos a través de <a href="mailto:dpo@goiko.com">dpo@goiko.com</a> o haciendo clic en el enlace de baja de cada una de las comunicaciones que recibas.
    </p>
    <h3>¿Los datos son comunicados a terceros?</h3>
    <p>A. Administración Pública, Juzgados y Tribunales</p>
    <p>
      Si las autoridades gubernamentales, tales como los cuerpos de seguridad del estado, Jueces y/o Tribunales, solicitan o requieren información 
      o si es necesario presentarla para cumplir alguna Ley, GOIKO comunicara a dichos terceros los datos que éstos formalmente soliciten.
    </p>
    <h3>¿Cómo puede el cliente ejercer sus derechos de revocación, acceso, supresión, oposición, rectificación, limitación, portabilidad?</h3>
    <p>
      Como usuario/cliente podrás ejercitar tus derechos de acceso, rectificación, supresión, revocar tu consentimiento, oponerte a su tratamiento, 
      limitar el mismo y/o solicitar la portabilidad de tus datos, a través de <a href="mailto:dpo@goiko.com">dpo@goiko.com</a>, adjuntando a la solicitud, copia de tu DNI o documento acreditativo de tu identidad.
    </p>
    <p>
      Si como usuario/ cliente, consideras infringidos tus derechos Protección de datos, tiene derecho asimismo, a presentar una reclamación ante la 
      Agencia Española de Protección de Datos (AEPD), C/ Jorge Juan, 6 28001-Madrid –E-mail: <a href="mailto:ciudadano@agpd.es">ciudadano@agpd.es</a> – <a href="https://www.aepd.es/es">https://www.aepd.es/es</a>
    </p>
    <h3>Medidas de seguridad y confidencialidad de la información</h3>
    <p>
      GOIKO ha adoptado las medidas oportunas de seguridad en sus instalaciones, sistemas y ficheros. Ha establecido todos los medios técnicos a 
      su alcance para evitar la pérdida, mal uso, alteración, acceso no autorizado y robo de los datos que el cliente facilite, velando así por su integridad, 
      disponibilidad y confidencialidad.
    </p>
    <h3>Política de cookies</h3>
    <p>GOIKO utiliza cookies. Toda la información al respecto en la <a href="#">Política de Cookies</a>.</p>
    <h3>Cambios en la política de privacidad</h3>
    <p>
      GOIKO cumple escrupulosamente con las leyes aplicables a la protección de datos y la privacidad de los usuarios. Para ello, se reserva el derecho 
      a modificar unilateralmente, en cualquier momento, las condiciones de la presente Política de Protección de datos de carácter personal, para adecuarla a 
      cualquier cambio normativo o modificación del medio y/o entorno digital.
    </p>
  </div>
  <BodyFooter />
</template>

<script>
import BodyFooter from '../../components/BodyFooter.vue'
export default {
  name: 'LegalNoti',
  components: {
    BodyFooter
  },
  mounted() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }
}
</script>

<style scoped lang="scss">
  .container {
    font-size: 26px;
    margin: 60px auto 150px auto;
    max-width: 1200px;
    padding: 0 30px;
    h1, h2, h3 {
      font-family: $font__headings;
      text-transform: uppercase;
      text-align: center;
    }
    a {
      color: $c-red;
    }
  }
</style>