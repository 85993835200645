<template>
  <span class="empty-catalog">
      <h3>{{ errorTokenMsg }}</h3>
      <span class="link">
        <svg xmlns="http://www.w3.org/2000/svg" width="8" height="14" viewBox="0 0 8 14" fill="none">
            <path d="M6.53579 0.109817L7.21116 0.934203L1.66847 6.5282L7.01308 12.3075L6.30941 13.1078L0.113147 6.50111L6.53579 0.109817Z" fill="black"/>
        </svg>
        <router-link :to="{ name: 'home' }">Volver al inicio</router-link>
      </span>
    </span>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
    name: 'LoginView',

    props: {
      refreshToken: {
        required: true,
        type: String
      }
    },

    data() {
      return {
        errorTokenMsg: ''
      }
    },

    methods: {
      ...mapActions('Auth', ['refresh']),
      ...mapActions('User', ['fetchProfile']),
    },

    computed: {
      ...mapState('Cart', ['orderInfo']),
    },

    mounted() {
      localStorage.setItem('refreshToken', this.$route.params.refreshToken)
      this.refresh()
        .then(() => {
          this.fetchProfile()
            .then(() => {
              if (this.orderInfo.length > 0) {
                this.$router.push({name: 'catalog'})
              } else {
                this.$router.push({name: 'home'})
              }
            })
        })
        .catch(() => this.errorTokenMsg = 'Ha ocurrido un error al intentar acceder Goiko. Por favor, inténtalo de nuevo.')
    }
}
</script>

<style scoped lang="scss">
.empty-catalog {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 81vh;
  padding: 30px;

  h3 {
    text-align: center;
  }
  
  .link {
    cursor: pointer;
    display: flex;
    align-items: center;

    a {
      font-size: $fs-s-small;
      color: $c-black;
      margin-left: 5px;
      text-decoration: underline;
    }
  }
}
</style>
