<template>
  <div class="container" :class="{ disabled: catalog.length === 0}">
		<div class="text-address">
			<p v-if="shippingMethod == 'takeaway'">Recogerás tu pedido en: <br> <strong v-if="getRestaurantInfo">{{getRestaurantInfo.address}}</strong></p>
			<p v-else>Entregaremos tu pedido en: <br> <strong v-if="deliveryAddress">{{deliveryAddress.fullAddress}}</strong></p>
			<button @click="$router.push({ name: 'modal-catalog' })" class="btn black">Cambiar</button>
		</div>
		<div class="content">
			<div @click="toggleCart" class="icon-bag">
				<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 25" fill="none">
					<path d="M23.9745 22.2727L21.8143 7.77162C21.6388 6.60754 20.6519 5.75388 19.4896 5.75388H17.6802C17.6802 2.58315 15.1252 0 11.989 0C8.85285 0 6.29784 2.58315 6.29784 5.75388H4.51043C3.34807 5.75388 2.35019 6.60754 2.1857 7.77162L0.0254633 22.2727C-0.182885 23.714 0.913684 25 2.35019 25H21.6498C23.0863 25 24.1829 23.7029 23.9745 22.2727ZM12 1.74058C14.1931 1.74058 15.9696 3.53659 15.9696 5.75388H8.03042C8.03042 3.53659 9.80686 1.74058 12 1.74058Z" fill="white"/>
				</svg>
				<span>{{cartItemCount}}</span>
			</div>
			<router-link :class="{disable_item: cartItemCount == 0}"  :to="{ name: 'checkout' }" @click="trackCheckoutEvent">
				<button class="btn">
					<span>Finalizar pedido</span>
					<svg xmlns="http://www.w3.org/2000/svg" width="9" height="15" viewBox="0 0 9 15" fill="none" >
						<path d="M0.886667 15L-4.09794e-08 14.0625L7 7.4975L-6.14691e-07 0.9375L0.886666 -3.87574e-08L9 7.4975L0.886667 15Z" fill="white"/>
					</svg>
				</button>
			</router-link>
		</div>
	</div>
	<div v-if="toggleModal">
    <CartModal @click.self="toggleCart" />
  </div>
</template>

<script>
import CartModal from '../components/CartModal'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
	components: {
		CartModal
	},
	data() {
		return {
			isCartVisible: false,
		}
	},
	computed: {
		...mapState('Catalog', ['catalog', 'toggleModal']),
		...mapState('Cart', ['shippingMethod', 'deliveryAddress']),
		...mapGetters('Cart', ['getRestaurantInfo', 'getOrderInfo', 'cartItemCount', 'cartTotalPrice']),
	},
	methods: {
		...mapActions('Catalog', ['toggleModalToModifyProduct']),
		toggleCart() {
			let cartItems = []
			this.getOrderInfo.forEach(order => cartItems.push({
				item_id: order.plu,
				item_name: order.name
			}))
			this.$gtag.event('view_cart', {
				currency: 'EUR',
				value: this.cartTotalPrice.total / 100,
				items: cartItems
			})
			this.isCartVisible = !this.isCartVisible
			if (!this.toggleModal) {
				this.isCartVisible = true
			}
			this.toggleModalToModifyProduct(this.isCartVisible)
		},
		trackCheckoutEvent() {
			this.$gtag.event('go_to_checkout')
		}
	},
}
</script>

<style scoped lang="scss">
.container {
	background-color: $c-black;
	cursor: default;
	display: flex;
	height: 80px;
	position: fixed;
	bottom: 0;
	width: 100%;
	z-index: 10;
	padding: 0 30px;

	&.disabled {
		pointer-events: none;
	}

	.content {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-left: 40px;
		margin-right: 3px;
		width: 100%;
	}

	.icon-bag {
		cursor: pointer;
			height: 30px;
			right: 35px;
			width: 30px;
			position: relative;

			span {
				color: $c-black;
				top: 0;
				left: 0;
				display: block;
				line-height: 40px;
				width: 30px;
				text-align: center;
				position: absolute;
				font-weight: bold;
			}
	}

	.text-address {
		display: none;

		p {
			color: $c-white;
			font-size: $fs-m-small;
			padding-right: 50px;
		}

		button {
			display: block;
			border-radius: 20px;
			background-color: $c-black;
			border: 1px solid $c-white;
			color: $c-white;
			cursor: pointer;
			height: 30px;
			display: flex;
			align-items: center;
			font-size: $fs-m-small;
			padding: 14px 20px;
		}
	}
	
	@include tablet {
		.content {
				justify-content: flex-end
		}

		.text-address {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			font-size: $fs-m-small;
			width: 100%;

			a {
					text-decoration: none;
			}
		}
	}
}
</style>
