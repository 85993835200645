<template>
  <div v-if="order" class="footer">
    <div v-if="order.subTotal < config.minimumOrderFreeDelivery && order.subTotal > 0 || config.serviceCost || coupons.length > 0" class="text">
      <div>Subtotal <span class="iva">(IVA incluido)</span></div>
      <span>{{$filters.formatEUR(Number(order.subTotal), true)}}</span>
    </div>
      <template v-if="shippingMethod === 'delivery'">
        <div v-if="config.hasDeliveryCost || order.config?.hasDeliveryCost" class="text">
          <p>Gastos de envío (pedido inferior a {{$filters.formatEUR(minimunDeliveryPrice(), true)}})</p>
          <span>{{$filters.formatEUR(deliveryPrice(), true)}}</span>
        </div>
        <div v-else class="text">
          <p>Gastos de envío</p>
          <span><del>&nbsp;{{$filters.formatEUR(config.deliveryCost, true)}}&nbsp;</del>&nbsp;<span class="free">¡GRATIS!</span></span>
        </div>
        <span class="text-goikoid" v-if="user && goikoId">Tarifa especial de envío por ser nuestro Friend with Benefits.</span>
      </template>
      <div class="text" v-if="config.serviceCost || order.serviceCost">
        <p>Gastos de servicio</p>
        <span>{{$filters.formatEUR(serviceCost(), true)}}</span>
      </div>
      <template v-for="(coupon) in transformedValidCoupons" :key="coupon.code">
        <div v-if="coupons.length > 0 && coupon.discount" class="text valid-coupon">
          <template v-if="validCoupons.length > 0 && order.subTotal >= coupon.discount">
            <p>Código: {{coupon.code}}</p>
            <span>-{{$filters.formatEUR(coupon.discount, true)}}</span>
          </template>
        </div>
      </template>
    <div class="text">
      <h3 class="footer-text">Total</h3>
      <strong>{{$filters.formatEUR(Number(order.total), true)}}</strong>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'SumPrices',
  props: {
    order: Object,
    totalPrice: Object
  },
  computed: {
    ...mapState('Cart', ['config', 'coupons', 'errorCoupons', 'orderInfo', 'goikoId', 'couponNumber', 'productPlu', 'shippingMethod', 'validCoupons']),
    ...mapState('User', ['user']),
    transformedValidCoupons() {
      const groupedCoupons = {}

      this.validCoupons.forEach(coupon => {
        if (groupedCoupons[coupon.code]) {
          groupedCoupons[coupon.code].discount += coupon.discount
        } else {
          groupedCoupons[coupon.code] = { ...coupon }
        }
      })

      return Object.values(groupedCoupons)
    }
	},
  methods: {
    minimunDeliveryPrice() {
      if (this.config.minimumOrderFreeDelivery && this.config.minimumOrderFreeDelivery >= 0) {
        return this.config.minimumOrderFreeDelivery
      } else {
        return this.order.config.minimumOrderFreeDelivery
      }
    },
    deliveryPrice() {
      if (this.config.deliveryCost && this.config.deliveryCost >= 0) {
        return this.config.deliveryCost
      } else {
        return this.order.deliveryCost
      }
    },
    serviceCost() {
      if (this.config.serviceCost && this.config.serviceCost >= 0) {
        return this.config.serviceCost
      } else {
        return this.order.serviceCost
      }
    },
  },
}
</script>

<style scoped lang="scss">
.footer {
	.text {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin: 0 20px;
    margin-bottom: -4px;
		font-size: $fs-s-small;

		&.valid-coupon, .free {
			color: $c-green;
		}

		&.invalid-coupon {
			color: $c-red;
		}

		&:last-child {
			display: flex;
			align-items: flex-end;
			margin-bottom: 10px;
			margin-top: 20px;
      padding-bottom: 20px;
		}

		.iva {
			color: #5F5F5F;
			font-size: $fs-s-small;
		}

		div {
			margin: 10px 0 5px 1px;
		}

		p {
			margin: 5px 0;
		}

		h3, strong {
			font-family: $font__headings;
			font-size: $fs-s-medium;
      text-transform: uppercase;
			margin: 0;
		}

		&:nth-of-type(4) {
			margin-bottom: -10px;
		}
	}
  .text-goikoid {
    font-size: 10px;
    margin: 0 20px;
  }
}
</style>
