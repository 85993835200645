<template>
<div class="modal" @click.self="closeModal">
	<div class="modal-dialog">
		<span v-if="isLoading" class="spinner"></span>
		<template v-else>
			<span class="close-modal" @click="closeModal">X</span>
			<div class="modal-header__wrapper">
				<Header color="black" colorBg="white" />
			</div>
			<div class="container">
				<div class="container__header">
					<h1>Regístrate</h1>
					<div @click="openModalLogin" class="container__header-login">
							<strong>¿Ya tienes una cuenta?</strong>
							<button class="btn">Identifícate</button>
					</div>
				</div>
				<form @submit.prevent="newUserSubmit">
						<div class="form__field-column">
							<div class="form__field-row">
								<div class="form__field-wrapper">
									<label>Nombre:</label>
									<input required type="text" name="name" v-model="form.name">
									<div class="errors-input" v-show="errors.hasOwnProperty('name')">
											<span v-for="(error, key) in errors.name" :key="key">{{ $t(error) }}</span>
									</div> 
								</div>
								<div class="form__field-wrapper">                     
									<label>Apellidos:</label>
									<input required type="text" name="lastName" v-model="form.lastName">
									<div class="errors-input" v-show="errors.hasOwnProperty('lastName')">
										<span v-for="(error, key) in errors.lastName" :key="key">{{ $t(error) }}</span>
									</div>
								</div>
							</div>
						<div class="form__field-row">
							<div class="form__field-wrapper">                       
								<label>Correo electrónico:</label>
								<input required @input="autocompleteField" type="email" name="email" v-model="v$.form.email.$model">
								<div class="errors-input" v-show="errors.hasOwnProperty('email')">
									<span v-for="(error, key) in errors.email" :key="key">{{ $t(error) }}</span>
								</div>
							</div>
							<div class="form__field-wrapper">                       
								<label>Número de teléfono:</label>
								<input required @input="autocompleteField" type="text" name="phone" v-model="v$.form.phone.$model" @keypress="isNumber($event, form.phone)" placeholder="+34">
								<span v-if="form.phone.length < 9 || form.phone.length > 9">
								<div class="errors-input" v-show="errors.hasOwnProperty('phone')">
									<span v-for="(error, key) in errors.phone" :key="key">{{ $t(error) }}</span>
								</div>
								</span>
							</div>
							<div class="form__field-wrapper">                       
								<label>¿Somos FRIENDS WITH BENEFITS?</label>
								<textarea cols="30" rows="10" v-model="v$.form.goikoId.$model" :placeholder="waitingMessage"></textarea>
								<span v-for="(error, key) in errors.goikoId" :key="key" class="errors-input">GOIKO ID incorrecto</span>
							</div>
						</div>
						<div class="form__field-row">
							<div class="form__field-wrapper">
								<label>
									<input type="checkbox" v-model="form.isAdult" />
									<span>Soy mayor de 18 años</span>
								</label>
								<label>
									<input type="checkbox" v-model="form.salesTerms" />
									<span>Acepto las <a href="https://www.goiko.com/condiciones-venta-web/">condiciones de venta</a></span>
								</label>
								<span class="availability-error" v-if="form.salesTerms == false">(Casilla obligatoria)</span>
								<label>
									<input type="checkbox" v-model="form.privacyPolicy" />
									<span>Acepto la <a href="https://www.goiko.com/politica_de_proteccion_de_datos/">política de protección de datos personales</a></span>
								</label>
								<span class="availability-error" v-if="form.privacyPolicy == false">(Casilla obligatoria)</span>
								<label>
									<input type="checkbox" v-model="form.acceptNotification" />
									<span>Acepto recibir comunicaciones electrónicas comerciales de la marca</span>
								</label>                       
							</div>
						</div>
					</div>
					<span class="container__button-submit">
						<button class="btn footer" type="submit" :class="{button_available: form.privacyPolicy && form.salesTerms == true}">Crea tu cuenta ahora</button>
					</span>
				</form>
			</div>
		</template>
	</div>
  </div>
</template>
<script>

import Header from '../components/Header'
import { mapActions } from 'vuex'
import { email, required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'

export default {
	name: 'Register',
	components: {
		Header
	},
	setup () {
		return { v$: useVuelidate() }
  },

	data() {
		return {
			form: {
				name: '',
				lastName: '',
				email: '',
				phone: '',
				isAdult: false,
				acceptNotification: false,
				salesTerms: false,
				privacyPolicy: false,
				goikoId: ''
			} ,
			errors: [],
			isLoading: false,
			waitingMessage: 'GOIKO ID'
		}
	},

	validations() {
		return {
			form: {
				email: {
					required,
					email
				},
				phone: {
					required
				},
				goikoId: {}
			}
		}
	},

	methods: {
		...mapActions('Auth', ['signup', 'getGoikoId']),
		...mapActions('User', ['fetchProfile']),

		closeModal() {
				if (this.$route.name == 'register-checkout') {
						this.$router.push({name: 'checkout'})
				} else {
						this.$router.push({name: 'home'})
				}
		},

		openModalLogin() {
			if (this.$route.name == 'register-checkout') {
					this.$router.push({name: 'login-checkout'})
			} else {
					this.$router.push({name: 'login'})
			}
		},

		clearErrors() {
			this.errors = []
		},

		async newUserSubmit() {
			this.isLoading = true
			this.signup(this.form)
				.then(() => {
					if (this.$route.name == 'register-checkout') {
						this.$router.push({name: 'register-confirmation-checkout'})
					} else {
						this.$router.push({name: 'register-confirmation'})
					}
					this.$gtag.event('sign_up')
				})
				.catch(errors => {
					console.log(errors)
					this.errors = errors
				})
				.finally(() => this.isLoading = false)
		},

		async autocompleteField() {
			if (!this.v$.form.email.$invalid && !this.v$.form.phone.$invalid && this.form.phone.length === 9) {
				const neededDataGoikoId = {
					email: this.form.email,
					phone: this.form.phone
				}
					try {
						this.waitingMessage = 'BUSCANDO...'
						const response = await this.getGoikoId(neededDataGoikoId)
						if (response.goikoId) {
							this.form.goikoId = response.goikoId
							this.waitingMessage = 'GOIKO ID'
						} else {
							this.waitingMessage = 'GOIKO ID'
						}
					} catch (error) {
						this.waitingMessage = ''
						throw error
					}
			}
		},

		isNumber(e) {
			e = (e) ? e : window.e
			let charCode = (e.which) ? e.which : e.keyCode
			if ((charCode > 31) && (charCode < 48 || charCode > 57) && charCode !== 46) {
				e.preventDefault()
			} else {
				return true
			}
		},
	},
}
</script>

<style scoped lang="scss">
.modal {
	.modal-dialog {
		.container {
			background-color: #FFF;
			height: calc(100vh - 60px);
			overflow-y: auto;
			padding: 20px 20px 0 20px;

			.container__header {
				text-align: center;
				h1 {
					font-family: $font__headings;
					text-transform: uppercase;
				}

				.container__header-login {
					display: flex;
					flex-direction: column;
					align-items: center;
					width: 200px;
					margin: 0 auto;
					cursor: pointer;
					
					strong {
						font-size: $fs-m-small;
						display: block;
						margin: 2px;
						margin-bottom: 5px;
					}

					.btn {
						padding: 5px 30px;
						font-size: 16px;
						background-color: $c-black;
						width: 100%;
					}
				}
			}

			.form__field-column {
				display: flex;
				flex-direction: column;
				font-size: $fs-s-small;
				margin-top: 20px;

				.form__field-row:last-child {
					margin-left: 20px;
					margin-top: -20px;

					input {
						margin: 5px;
					}
				}
						
				.form__field-wrapper {
					display: flex;
					flex-direction: column;
					margin: 10px 10px;

					.errors-input {
						color: $c-red;
						margin: 0px;
					}

					a {
							color: $c-black;
					}

					.availability-error {
						float: right;
						display: block;
						font-size: $fs-s-small;
						margin: 2px;
						margin-left: 3px;
						text-align: start;
						color: rgba($c-notice, .7);
					}

					textarea {
						height: 44px;
					}
				}
			}
				.container__button-submit {
					display: flex;
					justify-content: center;
					margin-bottom: 20px;

					.footer {
						pointer-events: none;
						opacity: .5;
						display: flex;
						justify-content: center;
						margin-top: 10px;

						&.button_available {
							pointer-events: all;
							opacity: 1;
						}
					}
				}
		}
	}

	@include tablet {
		.modal-header__wrapper {
			display: none;
		}

		.modal-dialog {
			.container {
				padding: 20px 40px;
				height: 520px;

				.container__header {
					display: flex;
					align-items: flex-end;
					justify-content: space-between;
					padding: 10px;

					.container__header-login {
						display: flex;
						flex-direction: column;
						align-items: flex-end;
						margin: 0;
					}
				}
			}

			.form__field-column {
				margin-top: 20px;

				.form__field-row {
					display: flex;
					justify-content: space-around;
					align-items: center;

					.form__field-wrapper {
						width: 100%;
					}
				}
			}
		}

		.container__button-submit {
			margin-top: 15px;
			margin-bottom: 20px;
		}
	}
}
</style>
