import { createWebHistory, createRouter } from "vue-router";
import StartModal from '../components/StartModal'
import TimeChange from '../components/TimeChange'
import DeliveryHome from '../views/DeliveryHome'
import TakeawayHome from '../views/TakeawayHome'
import Catalog from '../views/Catalog'
import Checkout from '../views/Checkout'
import Confirmation from '../views/Confirmation'
import FailedPayment from '../views/FailedPayment'
import LegalNoti from '../views/Info/LegalNoti'
import DataProtection from '../views/Info/DataProtection'
import CookiesPolicy from '../views/Info/CookiesPolicy'
import TermsSales from '../views/Info/TermsSales'
import StartOrderByRestCode from '../views/StartOrderByRestCode'
import ProductDetail from '../components/ProductDetail'
import Login from '../components/Login'
import Register from '../components/Register'
import RegisterConfirmation from '../components/RegisterConfirmation'
import LoginView from '../views/LoginView'
import UpsellingModal from '../components/UpsellingModal'

const routes = [
  {
    path: '/delivery',
    name: 'home',
    component: DeliveryHome,
    children: [
      {
        path: '/delivery/iniciar-pedido',
        name: 'modal-delivery',
        props: true,
        component: StartModal
      },
      {
        path: '/delivery/login',
        name: 'login',
        component: Login
      },
      {
        path: '/delivery/registro',
        name: 'modal-register',
        component: Register,
      },
      {
        path: '/delivery/confirmacion',
        name: 'register-confirmation',
        component: RegisterConfirmation
      },
    ]
  },
  {
    path: '/takeaway',
    name: 'takeaway-home',
    component: TakeawayHome,
    children: [
      {
        path: '/takeaway/iniciar-pedido',
        name: 'modal-takeaway',
        props: true,
        component: StartModal
      },
    ]
  },
  {
    path: '/delivery/catalogo',
    name: 'catalog',
    component: Catalog,
    children: [
      {
        path: '/delivery/catalogo/:productDetail',
        name: 'product-detail',
        component: ProductDetail,
        props: true
      },
      {
        path: '/delivery/catalogo/datos-entrega',
        name: 'modal-catalog',
        props: true,
        component: StartModal
      },
    ]
  },
  {
    path: '/delivery/finalizar-compra',
    name: 'checkout',
    component: Checkout,
    children: [
      {
        path: '/delivery/complemento',
        name: 'upselling',
        component: UpsellingModal
      },
      {
        path: 'login',
        name: 'login-checkout',
        component: Login
      },
      {
        path: 'registro',
        name: 'register-checkout',
        component: Register,
      },
      {
        path: 'confirmacion',
        name: 'register-confirmation-checkout',
        component: RegisterConfirmation
      },
      {
        path: '/delivery/finalizar-compra/:productDetail',
        name: 'product-detail-checkout',
        component: ProductDetail,
        props: true
      },
      {
        path: '/delivery/finalizar-compra/datos-entrega',
        name: 'modal-checkout',
        props: true,
        component: StartModal
      },
      {
        path: 'hora-entrega',
        name: 'modal-timechange',
        props: true,
        component: TimeChange
      }
    ]
  },
  {
    path: '/delivery/pago-exitoso',
    name: 'confirmation',
    query: 'token',
    component: Confirmation,
  },
  {
    path: '/delivery/pago-erroneo',
    name: 'failed-payment',
    component: FailedPayment,
  },
  {
    path: '/delivery/login/:refreshToken',
    name: 'login-view',
    component: LoginView,
    props: true
  },
  {
    path: '/delivery/aviso_legal',
    name: 'legal-noti',
    component: LegalNoti
  },
  {
    path: '/delivery/politica_proteccion_de_datos',
    name: 'data-protection',
    component: DataProtection
  },
  {
    path: '/delivery/condiciones-venta-web',
    name: 'terms-sales',
    component: TermsSales
  }, 
  {
    path: '/delivery/politica_de_cookies',
    name: 'cookies-policy',
    component: CookiesPolicy
  },
  {
    path: '/delivery/iniciar-pedido/:code',
    name: 'catalog-rest',
    component: StartOrderByRestCode,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.VUE_APP_BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    if(to.name == 'checkout' && from.name != 'modal-timechange' && from.name !== 'modal-checkout') {
      return {
        top: 0,
        //behavior: 'smooth'
      }
    }
  }
});

router.beforeEach((to, from) => {
  document.body.classList.remove('route-' + from.name)
  document.body.classList.add('route-' + to.name)
  fbq('track', 'PageView')
})

export default router;
