<template>
  <span v-if="isCatalogLoading">
    <span class="spinner"></span>
  </span>
  <span v-else-if="catalog.length === 0" class="empty-catalog">
    <h3>En este momento este restaurante no tiene el catálogo disponible.</h3>
    <span class="link">
      <svg xmlns="http://www.w3.org/2000/svg" width="8" height="14" viewBox="0 0 8 14" fill="none">
        <path d="M6.53579 0.109817L7.21116 0.934203L1.66847 6.5282L7.01308 12.3075L6.30941 13.1078L0.113147 6.50111L6.53579 0.109817Z" fill="black"/>
      </svg>
      <router-link :to="{ name: 'modal-delivery' }">Buscar un nuevo restaurante</router-link>
    </span>
  </span>
  <ul v-else class="catalog-menu">
    <template v-for="(category, idx) in catalog" :key="category.id">
      <li :class="{[category.id]: true, active: idx == 0}" @click="goToCategory(category.id)" v-if="category.products.length > 0">{{category.name}}</li>
    </template>
  </ul>
  <div class="catalog-background">
    <template v-for="category in catalog" :key="category.id">
      <h2 :id="category.id" class="catalog-title nav" v-if="category.products.length > 0">{{category.name}}</h2>
      <div class="catalog-product" v-if="category.products.length > 0">
        <div v-for="(eachProduct) in category.products" :key="eachProduct.id" class="catalog-product-box" @click="openProduct(eachProduct.plu)">
          <img :src="eachProduct.imageUrl" alt="product-image" />
            <div class="text">
              <h3>{{eachProduct.name}}</h3>
              <p class="overflow-ellipsis">{{eachProduct.description}}</p>
              <div class="footer-text">
                <strong>{{$filters.formatEUR(eachProduct.price)}}</strong>
                  <svg v-if="!isLoadingProductToCart || product && eachProduct.plu !== product.plu" class="sum-icon" xmlns="http://www.w3.org/2000/svg" width="14" height="13" viewBox="0 0 14 13" fill="none" >
                    <path d="M7.00469 12.256C6.01269 12.256 5.51669 11.752 5.51669 10.744V7.528H2.37269C1.41269 7.528 0.932688 7.056 0.932688 6.112C0.932688 5.168 1.41269 4.696 2.37269 4.696H5.51669V1.6C5.51669 0.608 6.01269 0.112 7.00469 0.112C7.99669 0.112 8.49269 0.608 8.49269 1.6V4.696H11.6367C12.5967 4.696 13.0767 5.168 13.0767 6.112C13.0767 7.056 12.5967 7.528 11.6367 7.528H8.49269V10.744C8.49269 11.752 7.99669 12.256 7.00469 12.256Z" fill="white" />
                  </svg>
                  <svg v-if="isLoadingProductToCart && eachProduct.plu === product.plu" class="sum-icon" fill="white" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" width="405.272px" height="405.272px" viewBox="0 0 405.272 405.272" style="enable-background:new 0 0 405.272 405.272;" xml:space="preserve">
                  <g><path d="M393.401,124.425L179.603,338.208c-15.832,15.835-41.514,15.835-57.361,0L11.878,227.836   c-15.838-15.835-15.838-41.52,0-57.358c15.841-15.841,41.521-15.841,57.355-0.006l81.698,81.699L336.037,67.064   c15.841-15.841,41.523-15.829,57.358,0C409.23,82.902,409.23,108.578,393.401,124.425z"/>
                  </g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></svg>
              </div>
            </div>
        </div>
      </div>
    </template>
    <router-view></router-view>
  </div>
  <Footer />
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import Footer from "../components/Footer.vue";

export default {
  name: "Catalog",
  components: {
    Footer,
  },

  data() {
    return {
      isCatalogLoading: false,
      currentSection: 0,
      isLoadingProductToCart: false,
      waitingProductToCart: false,
      downloadTimer: 0
    }
  },

  methods: {
    ...mapActions('Catalog', ['restaurant', 'products']),
    ...mapActions('User', ['fetchProfile']),
    ...mapActions('Cart', ['getConfig', 'saveConfig', 'checkUnavailableProducts', 'checkProductsCart', 'addProductToCart']),
    
    goToCategory(id) {
      let offsetTop = document.getElementById(id).offsetTop - 140
    
      if(offsetTop) {
        scroll({
          top: offsetTop,
          behavior: "smooth"
        });
      }
    },

    openProduct(productPlu) {
      clearTimeout(this.downloadTimer)
      this.products(productPlu)
        .then(data => {
          if (data.modifiersGroups.length === 0 && data.bundles.length === 0) {
            this.isLoadingProductToCart = true
            this.downloadTimer = setTimeout(() => {
              this.isLoadingProductToCart = false
              clearTimeout(this.downloadTimer)
            }, 5000)
            this.addProductToCart({
              id: data.id,
              imageUrl: data.imageUrl,
              name: data.name,
              plu: data.plu,
              price: data.price,
              modifiers: [],
              quantity: 1,
              isProductAvailable: true,
              hasUnavailableModifiers: [],
              hasUnavailableProducts: [],
              isAlcohol: false,
              hasComboProducts: []
            })
            this.$gtag.event('add_to_cart', {
              currency: 'EUR',
              value: data.price / 100,
              items: [{
                item_id: data.plu,
                item_name: data.name
              }]
            })
            this.checkProductsCart(true)
          } else {
            this.$router.push({ name: 'product-detail', params: { productDetail: productPlu } })
          }
        })
        .catch(err => {
          throw err
        })
        .finally(() => this.isLoading = false)
    },
  },

  computed: {
    ...mapState('Catalog', ['catalog', 'product']),
    ...mapGetters('Cart', ['getDeliveryAddress', 'getRestaurantInfo']),
  },

  beforeUpdate() {
    if (this.waitingProductToCart) {
      this.restaurant()
        .then(catalog => catalog.forEach(category => category.products.forEach((product) => {
          this.waitingProductToCart = false;
          if (this.$router.options.history.state.back === `/catalogo/${product.plu}`) {
            this.isLoadingProductToCart = true 
            this.downloadTimer = setTimeout(() => {
              this.isLoadingProductToCart = false
              clearTimeout(this.downloadTimer)
            }, 5000)
          }
          this.isCatalogLoading = false
        })))
        .catch(() => this.isCatalogLoading = false)
    }
    this.waitingProductToCart = false
  },

  mounted() {
    this.isCatalogLoading = true
    this.restaurant()
      .then(response => {
        let itemList = []
        response.forEach(articles => articles.products.forEach(product => {
          itemList.push({
            item_id: product.plu,
            item_name: product.name
          })
        }))
        this.$gtag.event('view_item_list', { items: itemList })
        this.checkProductsCart()
        this.isCatalogLoading = false
      })
      .catch((err) => {
        this.isCatalogLoading = false
      })

    if (this.getDeliveryAddress.isAdult === null)  {
      this.getDeliveryAddress.isAdult = false
    }
    this.fetchProfile()
      
    window.addEventListener('scroll', () => {
      let sections = document.querySelectorAll('.nav')
      let ulist = document.querySelector('.catalog-menu')
      let list = document.querySelectorAll('.catalog-menu li')
      let scrollY = window.pageYOffset + (window.innerHeight * 0.6)
      let current = ''

      sections.forEach((section, idx) => {
        if (scrollY >= section.offsetTop) {
          current = idx
        }
      })
      
      if(window.pageYOffset == 0) {
        current = 0
      }

      if(current != this.currentSection) {
        this.currentSection = current
        ulist.scrollTo({left: list[current].offsetLeft - 20, behavior: 'smooth'})
      }
      
      list.forEach((li, idx) => {
        li.classList.remove('active')
        if (idx == current) {
          li.classList.add('active')
        }
      })
    })
  },
}
</script>

<style scoped lang="scss">
.empty-catalog {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 84vh;
  padding: 30px;

  h3 {
    text-align: center;
  }

  .link {
    cursor: pointer;
    display: flex;
    align-items: center;

    a {
      font-size: $fs-s-small;
      color: $c-black;
      margin-left: 5px;
      text-decoration: underline;
    }
  }

}

.catalog-background {
  background-image: url("../assets/bg-texture.jpg");
  background-repeat: repeat;
  background-size: contain;
  padding: 10px;
  cursor: default;
}

.catalog-menu {
  background-color: $c-white;
  display: flex;
  position: fixed;
  overflow-x: scroll;
  width: 100%;
  margin: 0;
  text-transform: uppercase;
  padding: 0;
  padding-top: 14px;
  li {
    display: block;
    line-height: 55px;
    font-size: $fs-l-small;
    margin-right: 16px;
    margin-left: 16px;
    font-family: $font__main;
    font-weight: $fw-heavy;
    white-space: nowrap;
    cursor: pointer;
  }

  .active {
    position: relative;
    &::after {
      content: " ";
      position: absolute;
      bottom: 10px;
      left: 0;
      right: 0;
      height: 3px;
      background-color: $c-red;
    }
  }

  @include tablet {
    padding-top: 18px;
  }
  @include desktop {
    padding-top: 0;
  }
  @include large-desktop {
    justify-content: center;
  }
}

::-webkit-scrollbar {display: none;}

h2 {
  font-size: $fs-l-meduim;
  font-family: $font__headings;
  text-align: center;
  text-transform: uppercase;
  // margin: 20px 0;

  &:not(:first-child) {
    margin-top: 35px;
  }

  &:first-child {
    margin-top: 85px;
  }

  @include tablet {
    font-size: $fs-l-large;

    br {
      display: none;
    }
  }

  @include desktop {
    &:first-child {
      margin-top: 103px;
    }
  }
}

.catalog-product {
  cursor: default;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0 22px;
  gap: 36px 36px;
  margin-bottom: 80px;
  font-size: 0;

  &:last-child {
    margin-bottom: 90px;
  }

  @include tablet {
    //justify-content: center;
  }
}

.catalog-product-box {
  background-color: $c-white;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  column-gap: 1rem;
  width: 100%;
  // height: 150px;
  
  img {
    border-radius: 8px 0 0 8px;;
    object-fit: cover;
    width: 150px;
  }

  .text {
    width: 100%;
    display: flex;
    padding: 15px 10px 10px;
    flex-direction: column;

    a {
      color: $c-black;
      cursor: pointer;
      text-decoration: none;
    }

    p {
      font-size: $fs-s-small;
      font-family: $font__main;
      margin-top: 10px;
      margin-right: 10px;
      flex-grow: 2;
      max-height: 4.5em;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      @supports (-webkit-line-clamp: 3) {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: initial;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
    }

    h3 {
      font-family: $font__main_bold;
      font-size: $fs-l-small;
      margin: 0;
      text-transform: uppercase;
    }
  }

  .footer-text {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    strong {
      font-size: $fs-xs-medium;
      font-family: $font__headings;
    }
  }

  .sum-icon {
    background-color: red;
    border-radius: 50%;
    height: 35px;
    padding: 12px;
    transition: all 0.5s;
    width: 35px;


    &:hover {
      background: black;
    }
  }

  @include tablet {
    width: calc(50% - 18px);

    h3 {
      font-size: $fs-s-medium;
    }
  }
  @include desktop {
    width: calc(33.33% - 24px);

    p {
      font-size: $fs-m-small;
    }
  }
  @include large-desktop {
    width: calc(25% - 27px);
  }
}
</style>
