<template>
	<div class="cart-wrapper">
		<span :class="{'checkout-header': $route.name === 'checkout'}">
			<svg v-if="$route.name === 'checkout'" @click="$emit('toggleCart')"  class="icon" width="27" height="27" xmlns="http://www.w3.org/2000/svg" fill="black" stroke="black" viewBox="0 0 14 8">
				<path d="M0.8125 1.43969L1.625 0.750061L7.31467 6.19451L13 0.750061L13.8125 1.43969L7.31467 7.75006L0.8125 1.43969Z" fill="white"/>
			</svg>
			<h3>Tu pedido:</h3>
		</span>
		<div class="container">
			<div class="warnings" v-if="$route.name !== 'confirmation' && (!restaurant.isOpen || restaurant.isBusyMode)">
				<span v-if="!restaurant.isOpen">El restaurante no está abierto, por favor, elige otra hora.</span>
				<span v-if="restaurant.isBusyMode">El restaurante ahora mismo no admite más pedidos.</span>
			</div>
			<div v-if="orderInfo.length === 0" class="empty-cart">
				Aún no tienes productos en tu carrito
				<router-link :to="{ name: 'catalog' }">Ve al catálogo</router-link>
			</div>
			<div v-else v-for="(item, idx) in orderInfo" :key="idx" :set="modifierIdx = idx" class="wrapper-cart">
				<div class="product-info" :class="{disabled: !item.isProductAvailable || item.hasUnavailableModifiers.length > 0 || item.hasUnavailableProducts.length > 0}">
					<img :src="item.imageUrl" alt="burger">
					<div class="description">
						<h2>{{item.name}}</h2>
						<span class="modifiers" v-if="item.hasComboProducts.length > 0">
							<template v-for="(product, index) in item.hasComboProducts" :key="index">
								{{product.name}}<span v-if="index !== item.hasComboProducts.length - 1">, </span>
							</template>
						</span>
						<div class="modifiers">
							<template v-for="(modifier, index) in item.modifiers" :key="index" >
								{{modifier.name}}<span v-if="index !== item.modifiers.length - 1">, </span>
							</template>
						</div>
					</div>
					<div v-if="orderInfo[idx].isProductAvailable" class="calc">
					<div class="quantity">
						<span
							v-if="$route.name !== 'confirmation'"
							@click="removeQuantity(idx)"
							:class="{disabled_quantity_button: orderInfo[idx].isProductAvailable === false || item.hasUnavailableModifiers.length > 0 || item.hasUnavailableProducts.length > 0}">
							-
						</span>
						<strong>{{item.quantity}}</strong>
						<span
							v-if="$route.name !== 'confirmation'"
							@click="addQuantity(idx)" 
							:class="{disabled_quantity_button: orderInfo[idx].isProductAvailable === false || item.hasUnavailableModifiers.length || item.hasUnavailableProducts.length > 0} && 
							{hidden_button: $route.name == 'confirmation'}">
							+
						</span>
					</div>
						<span v-if="orderInfo[idx].isProductAvailable === true && !item.hasUnavailableModifiers.length" class="price">
							{{$filters.formatEUR((item.price * item.quantity) + (calculateModifierPrice(idx) * item.quantity), true)}}
						</span>
						<span class="price" v-else>{{$filters.formatEUR(0, true)}}</span>
						<svg v-if="orderInfo[idx].modifiers.length" @click="modifierProduct(orderInfo[idx].plu, idx)"
							xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
							class="edit">
							<path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 
								21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 
								18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.8 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 
								241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32
								14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 
								0 32-14.3 32-32s-14.3-32-32-32H96z"/>
						</svg>
					</div>
					<span v-else @click="removeProductFromCart(idx)" class="delete">Eliminar</span>
				</div>
				<div class="availabilityErrors">
					<p v-if="!item.isProductAvailable && item.hasComboProducts.length === 0">Este producto no está disponible ahora mismo</p>
					<p v-if="item.hasUnavailableModifiers.length">Este producto incluye modificadores que no están disponibles:
					<span v-for="(modifier, index) in item.hasUnavailableModifiers" :key="modifier">
						{{ modifier }}
						<span v-if="index !== item.hasUnavailableModifiers.length - 1">, </span>
					</span>
					</p>
				</div>
				<div class="availabilityErrors">
					<template v-if="item.hasUnavailableProducts.length > 0">
						<p>Este producto no está disponible ahora mismo: 
							<span v-for="(product, index) in item.hasUnavailableProducts" :key="product.id">
								{{product}}<span v-if="index !== item.hasUnavailableProducts.length - 1">, </span>
							</span>
						</p>
					</template>
				</div>
			</div>
		</div>
		<hr>
		<div class="link" :class="{link_hidden: cartItemCount === 0}">
			<template>
				<svg xmlns="http://www.w3.org/2000/svg" width="8" height="14" viewBox="0 0 8 14" fill="none">
					<path d="M6.53579 0.109817L7.21116 0.934203L1.66847 6.5282L7.01308 12.3075L6.30941 13.1078L0.113147 6.50111L6.53579 0.109817Z" fill="black"/>
				</svg>
				<router-link :to="{ name: 'catalog' }">Volver a la carta y modificar pedido</router-link>
			</template>
		</div>
		<SumPrices :order="cartTotalPrice" />
	</div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import SumPrices from '../components/SumPrices.vue'

export default {
	name: 'Cart',
	components: {
		SumPrices
	},
	computed: {
		...mapGetters('Cart', ['cartItemCount', 'cartTotalPrice', 'getProductIndexPosition']),
		...mapState('Cart', ['config', 'orderInfo', 'restaurant', 'cart', 'coupons', 'errorCoupons']),
	},
	methods: {
		...mapActions('Cart', ['checkProductsCart', 'getOrderByToken', 'removeProductFromCart']),
		...mapActions('Catalog', ['toggleModalToModifyProduct']),
		...mapMutations('Cart', {
			addQuantity: 'ADD_QUANTITY',
			removeQuantity: 'REMOVE_QUANTITY',
		}),
		calculateModifierPrice(idx) {
			let total = 0
			this.orderInfo.length === 0 ? total : this.orderInfo[idx].modifiers.forEach(modifier => total += modifier.price)
			return total
		},
		modifierProduct(plu, idx) {
			this.toggleModalToModifyProduct(false)
				this.$router.push({ 
				name: window.location.pathname === '/catalogo' ? 'product-detail' : 'product-detail-checkout', 
				params: { productDetail: plu, productPosition: Number(idx) }
			})
		}
	},
	mounted() {
		if (this.$route.name === 'confirmation') {
			this.getOrderByToken(this.$route.query.token)
		}
	}
}
</script>

<style scoped lang="scss">
.cart-wrapper {
	background-color: $c-white;
	padding: 10px 20px;
	transition: all .3s;

	.checkout-header {
		display: flex;
		justify-content: flex-start;
		gap: 15px;
		align-items: center;
	}

	.icon {
		cursor: pointer;
		border: 1px solid $c-black;
		border-radius: 50%;
		padding: 6px;
	}

	@include tablet {
		.icon {
			display: none;
		}
	}

	hr {
		background: $c-black;
		border: 0;
		height: 1px;
	}

	&.close_modal {
		display: none;
	}
	
	h3 {
		font-family: $font__headings;
		font-size: $fs-s-medium;
		text-transform: uppercase;
		margin-top: 30px;
		margin-bottom: 30px
	}

	.container {
		max-height: 47vh;
		min-height: 35vh;
		overflow: auto;
		position: relative;
		&::after {
			content: " ";
			display: block;
			position: sticky;
			bottom: 0;
			left: 0;
			right: 0;
			height: 30px;
			background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,.7) 50%, rgba(255,255,255,0) 100%);
		}
		&>div:last-child {
			margin-bottom: -20px;
		}

		.empty-cart {
			display: flex;
			flex-direction: column;
			font-family: $font__headings;
			text-align: center;
			padding: 130px 0;

			a {
				font-size: $fs-m-small;
				margin: 10px;
				color: $c-black;
			}
		}

		.warnings {
			color: $c-red;
			font-size: $fs-m-small;
			padding-bottom: 20px;
			text-align: center;
		}

		@include tablet {
			min-height: 40vh;

			.empty-cart {
				overflow-x: scroll;
			}
		}
	}

	.link {
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 15px 0;

		a {
			font-size: $fs-s-small;
			color: $c-black;
			margin-left: 5px;
			text-decoration: underline;
		}

		&.link_hidden {
			display: none;
		}

		.back__order-detail {
			cursor: pointer;
			display: flex;
			align-items: center;
		}

		@include tablet {
			display: none;
		}
	}
		
	::-webkit-scrollbar {display: none;}
	.wrapper-cart {
		margin: 0 0 20px;
		.product-info {
			padding: 5px 0;
			display: flex;
			justify-content: space-around;

			&.disabled {
				opacity: .5;
				position: relative;
			}
	
			img {
				border-radius: 6px;
				width: 70px;
				height: 70px;
				object-fit: cover;
				flex-shrink: 0;
			}

			.description {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				margin: 0 15px;
				width: 100%;

				.modifiers {
					font-size: $fs-s-small;
				}

				h2 {
					font-size: $fs-m-small;
					font-family: $font__headings;
					text-transform: uppercase;
					margin-top: 0;
				}
			}

			.calc {
				display: flex;
				flex-direction: column;
				align-items: center;
				margin-left: 10px;

				.quantity {
					display: flex;
					justify-content: space-between;
					width: 100%;

					span {
						text-align: center;
						border-radius: 50%;
						border: 1px solid $c-black;
						cursor: pointer;
						height: 25px;
						line-height: 25px;
						width: 25px;
						transition: all .3s;

						&:hover {
							background-color: $c-black;
							color: $c-white;
						}

						&.hidden_button {
							display: none;
						}
					}

					strong {
						margin: 2px 8px;
						}
					}

					.price {
						color: #5F5F5F;
						font-size: $fs-s-small;
						margin-top: 10px;
					}
			}
		}
		.availabilityErrors {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			margin: 10px 0;
			margin-left: 86px;
			p {
				margin: 0;
				font-size: $fs-xs-small;
				text-align: center;
				color: $c-red;
			}
		}
		.delete {
			border: 1px solid;
			border-radius: 10px;
			cursor: pointer;
			display: flex;
			justify-content: center;
			align-items: center;
			height: 16px;
			font-size: $fs-s-small;
			font-weight: bold;
			margin-top: 30px;
			padding: 10px 8px;
		}
		.edit {
			cursor: pointer;
			margin-top: 10px;
			width: 20px;
		}
	}


	.calc {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: -3px;

		.quantity {
			display: flex;
			justify-content: space-between;
			width: 100%;

			span {
				text-align: center;
				border-radius: 50%;
				border: 1px solid $c-black;
				cursor: pointer;
				height: 25px;
				line-height: 25px;
				width: 25px;
				transition: all .3s;

				&:hover {
					background-color: $c-black;
					color: $c-white;
				}
			}

			strong {
				margin: 2px 8px;
			}
		}

		.iva {
			font-size: $fs-s-small;
			opacity: 0.3;
			.price {
				color: #5F5F5F;
				font-size: $fs-s-small;
				margin-top: 10px;
			}
		}
	}
}
</style>
