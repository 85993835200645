<template>
  <div class="container">
    <h1>Condiciones de venta</h1>
    <p>
      En cumplimiento de las obligaciones, que en este sentido, se imponen al vendedor por la Ley 34/2002 de 11 de julio de Servicios de 
      la Sociedad de la Información y comercio electrónico y por la Ley General para la Defensa de los Consumidores y Usuarios y otras leyes 
      complementarias se pone en conocimiento de los CLIENTES la siguiente información:
    </p>
    <p>
      <strong>Denominación:</strong> GOIKO GOURMET, S.L. ( en adelante, "<strong>GOIKO</strong>")
    </p>
    <p>
      <strong>NIF:</strong> B-86590841
    </p>
    <p>
      <strong>DOMICILIO:</strong> CALLE MAESTRO ÁNGEL LLORCA 6, 5º, 28003-MADRID (MADRID)
    </p>
    <p>
      <strong>Tlfno:</strong> 910609911
    </p>
    <p>
      <strong>Mail:</strong> dimedime@goiko.com
    </p>
    <p>
      <strong>Registro mercantil de madrid:</strong> T 30456, F 100, H M 548.070, I 1/A (15.12.12)
    </p>
    <strong>Códigos de conducta:</strong>
    <p>
      El usuario, en virtud de la presente, queda plenamente informado a los efectos descritos en la citada Ley 34/2002, de 11 de julio, 
      de Servicios de la Sociedad de la Información y de Comercio Electrónico pudiendo contactar con <strong>GOIKO</strong> de forma gratuita, rápida y 
      directa a través de cualquiera de los medios arriba indicados.
    </p>
    <p>
      Las presentes condiciones regularán expresamente las relaciones surgidas entre <strong>GOIKO</strong> y los Usuarios que contraten o accedan a los servicios 
      o productos comercializados por <strong>GOIKO</strong> a través de su portal.
    </p>
    <p>
      Dichas Condiciones han sido elaboradas de conformidad con lo establecido en la Ley 34/2002, de servicios de la sociedad de la información y de comercio electrónico, 
      la Ley 7/1998 sobre condiciones Generales de Contratación, y el Real Decreto Legislativo 1/2007, de 16 de noviembre, por el que se aprueba el Texto Refundido de la 
      Ley general para la Defensa de los consumidores y Usuarios y otras leyes complementarias y la demás normativa aplicable en materia de comercio electrónico.
    </p>
    <p>
      <strong>GOIKO</strong>  se reserva el derecho a modificar, total o parcialmente, estas condiciones generales, siendo de aplicación las nuevas que acuerde desde el momento de su publicación 
      en la página.  En cualquier caso, las citadas modificaciones no tendrán efectos retroactivos sobre los bienes o servicios previamente contratados por los CLIENTES.
    </p>
    <p>
      <strong>Idiomas:</strong> El contenido de la página web estará disponible en español.
    </p>
    <strong>Cómo realizar un pedido:</strong>
    <p>Antes de realizar el pedido si es Ud. alérgico podrá revisar en el apartado ALÉRGENOS los componentes de cada uno de los productos.</p>
    <p>Para realizar un pedido online accederemos a la web www.goiko.com donde podremos visualizar los distintos productos y sus características.</p>
    <p>En primer lugar, accederemos en la parte superior derecha de la página al apartado HACER PEDIDO dentro del cual se nos ofrecen dos alternativas: </p>
    <p>
      Si deseamos realizar el pedido online y recogerlo en el establecimiento seleccionado pulsaremos en RECOGER EN EL LOCAL. Si deseamos realizar el pedido
      online y recibirlo a domicilio pulsaremos REPARTO A DOMICILIO.
    </p>
    <ul id="shipping-method">
      <li>Te lo llevamos a casa (reparto a domicilio): </li>
    </ul>
    <p>El CLIENTE puede decidir si el servicio de entrega del Pedido lo realiza GOIKO o un tercero seleccionado (UBER EATS, GLOVO, JUST EAT).</p>
    <p>
      En el caso de seleccionar a GOIKO el servicio de entrega se regirá por las presentes condiciones. En el caso de seleccionar a un tercero 
      para el servicio, las condiciones se regirán por las condiciones generales del mismo.
    </p>
    <p>
      Tras seleccionar el restaurante GOIKO que prepare el pedido, procedemos a seleccionar el/los producto/s deseado/s y personalizarlo/s a
      nuestro gusto, por ejemplo en el caso de las hamburguesas podremos elegir el punto de la carne, o añadirle diferentes extras.
    </p>
    <p>Si accedemos a la CESTA podremos visualizar los productos añadidos, el precio de cada uno de ellos y el total del pedido.</p>
    <p>Si deseamos suprimir alguno de estos productos de la lista de la compra podremos hacerlo en cualquier momento.</p>
    <p>
      Por el contrario, si pretendemos continuar con la compra pulsaremos en HACER PEDIDO será necesario aportar una serie de datos personales y 
      el lugar de entrega del pedido necesarios para llevar a cabo la gestión del pedido solicitado, que se tratarán de acuerdo con la <a href="#">Política de Privacidad</a> del sitio, 
      a efectos de tramitación del mismo.
    </p>
    <p>Para continuar con la compra una vez aportados dichos datos y revisada y aceptada la <a href="#">Política de Privacidad</a> pulsaremos en CONTINUAR CON EL PAGO.</p>
    <p>
      El pedido se confirmará mediante la realización del pago que se hará efectivo a través del siguiente MEDIO DE PAGO admitido: <strong>Tarjeta de crédito/débito: 
      mediante TPV virtual de PAYCOMET facilitado durante el proceso de compra.
      </strong>
    </p>
    <p>
      GOIKO no será responsable del error que pueda cometer el USUARIO en la inclusión de la dirección de entrega. El plazo de entrega será orientativo 
      y en ningún caso será vinculante para el Servicio. GOIKO no se hace responsable de cualquier retraso motivado por causas ajenas a GOIKO.
    </p>
    <ul id="shipping-method">
      <li>Recógelo en GOIKO (recoger en el local): </li>
    </ul>
    <p>
      Tras seleccionar el restaurante GOIKO preferido procedemos a seleccionar el/los producto/s deseado/s y personalizarlo/s a nuestro gusto, por ejemplo en 
      el caso de las hamburguesas podremos elegir el punto de la carne, o añadirle diferentes extras.
    </p>
    <p>Si accedemos a la CESTA podremos visualizar los productos añadidos, el precio de cada uno de ellos y el total del pedido.</p>
    <p>Si deseamos suprimir alguno de estos productos de la lista de la compra podremos hacerlo en cualquier momento.</p>
    <p>
      or el contrario, si pretendemos continuar con la compra pulsaremos en HACER PEDIDO, entonces debemos seleccionar la hora de entrega y 
      aportar una serie de datos personales necesarios para llevar a cabo la gestión del pedido solicitado, que se tratarán de acuerdo con la 
      <a href="#">Política de Privacidad</a> del sitio, a efectos de tramitación del mismo.
    </p>
    <p>Para continuar con la compra una vez aportados dichos datos y revisada y aceptada la <a href="#">Política de Privacidad</a> pulsaremos en FINALIZAR COMPRA.</p>
    <p>
      A continuación, podremos revisar los datos introducidos y las condiciones de venta, si estamos conformes pulsaremos en PAGAR CON TARJETA donde procederemos
      al pago del pedido a través de una plataforma segura.
    </p>
    <p>
      El pedido se confirmará mediante la realización del pago que se hará efectivo a través del siguiente <strong>MEDIO DE PAGO</strong> admitido: T-A: <strong>Tarjeta de crédito/débito: mediante 
      TPV virtual de MOLLIE facilitado durante el proceso de compra.</strong>
    </p>
    <p>Los productos solicitados por parte del CLIENTE a través del portal de <strong>GOIKO</strong> serán puestos a disposición del mismo en la hora y en el establecimiento indicados al realizar el pedido. </p>
    <strong>Productos y precio:</strong>
    <p>
      <strong>PRODUCTOS</strong>: Independientemente de la clase de productos que desee, <strong>GOIKO</strong> pone a disposición del CLIENTE, la información pertinente en cada caso de acuerdo con la legislación vigente. a
      Podrá encontrarla en la ficha de cada producto.
    </p>
    <p>
      Si necesitase más información al respecto o le surge alguna duda puede ponerse en contacto con GOIKO a través de la siguiente dirección mail <a href="mailto:dimedime@goiko.com">dimedime@goiko.com</a> o 
      llamando al número de teléfono indicado en el encabezamiento de este documento.
    </p> 
    <p>
      <strong>PRECIO:</strong> El precio de los productos de <strong>GOIKO</strong> estará disponible en todo momento para el CLIENTE de la web y figurará expresado en euros.
    </p>
    <p>
      En cualquier caso, el CLIENTE, con carácter previo a la formalización del contrato tendrá acceso a la información sobre los precios de los productos, que incluyen el IVA 
      (Impuesto sobre el Valor Añadido).
    </p>
    <p>
      El precio del producto podrá ser modificado de forma unilateral y en cualquier momento por parte de <strong>GOIKO</strong> procediéndose a publicar la actualización del mismo en la 
      sección de productos y servicios del portal.
    </p>
    <p>Los precios vigentes para cada proceso de compra serán los que figuren en ese momento publicados en la sección relativa al mismo.</p>
    <strong>Pagos y medios de pago:</strong>
    <p>El pedido se confirmará mediante la realización del pago que se hará efectivo a través de los MEDIOS DE PAGO admitidos.</p>
    <p>
      Antes de dicha confirmación el cliente podrá abandonar la compra en cualquier momento. Una vez confirmado el pedido se estará a lo dispuesto en los apartados 
      “Cancelación” y “desistimiento”.
    </p>
    <p>Una vez realizado el pago el CLIENTE recibirá un mensaje de correo electrónico con la confirmación del pedido.</p>
    <p>Durante el proceso de compra, antes de realizar el pago, el CLIENTE podrá modificar los datos de su pedido.</p>
    <p>
      Asimismo, podrá cancelar cualquier solicitud de pedido antes de confirmarlo definitivamente. Si el pedido ya se hubiese confirmado se estará a los dispuesto 
      en los apartados “Cancelación” y “Desistimiento”.
    </p>
    <p><strong>Tícket de compra:</strong> Se entregará con su pedido a domicilio o en su caso, en el establecimiento al recoger el pedido y se podrá solicitar factura a través del portal facturas.goiko.com.</p>
    <strong>Cancelación de pedido</strong>
    <p>Si deseas cancelar tu pedido, podrás hacerlo siempre y cuando no se haya comenzado con la preparación del mismo. </p>
    <p>Para ello, será necesario que te pongas en contacto con el local competente para su preparación y entrega, a través del número de teléfono que se indica en nuestra página web. </p>
    <p>Te informaremos del local que lleva a cabo tu pedido desde el primer momento en el que comencemos con la gestión de la misma. </p>
    <strong>Derecho desistimiento</strong>
    <p>
      No se contempla el derecho de desistimiento, previsto con carácter general en el Real Decreto Legislativo 1/2007, de 16 de noviembre, por la naturaleza perecedera de 
      los productos suministrados ya que pueden sufrir un rápido deterioro o caducar con rapidez.
    </p>
    <strong>Garantía legal</strong>
    <p>
      La propia naturaleza de los bienes comercializados por <strong>GOIKO GOURMET, S.L.</strong>, toda vez que son productos perecederos, limita la aplicación del plazo de garantía establecido en 
      la Ley, que de otro modo sería de 2 años.
    </p>
    <p><strong>GOIKO GOURMET, S.L.</strong> responderá, por tanto, de las faltas de conformidad existentes en el momento de la entrega del producto en la medida que la naturaleza de los mismos lo permita.</p>
    <p>En estos casos se procederá a la sustitución del producto sin que ello suponga coste alguno para el CLIENTE.</p>
    <ul>
      <p>Se entiende que los productos son conformes con el contrato siempre que: </p>
      <li>Se ajusten a la descripción realizada y posean las cualidades que se hayan presentado en esta página web,</li> 
      <li>Sean aptos para los usos a que ordinariamente se destinan los productos del mismo tipo</li>
      <li>Presenten la calidad y prestaciones habituales de un producto del mismo tipo que sean fundadamente esperables.</li>
    </ul>
    <strong>Nulidad parcial</strong>
    <p>
      Si alguna de las presentes Condiciones o alguna disposición de un contrato fuera declarada nula y sin efecto por resolución firme dictada por autoridad competente, 
      los restantes términos y condiciones permanecerán en vigor, sin que queden afectados por dicha declaración de nulidad.
    </p>
    <strong>Ley aplicable y fuero</strong>
    <p>
      Las presentes condiciones generales y todos los conflictos derivados en relación con las presentes condiciones generales, 
      incluyendo la validez de las mismas, o con el uso del Sitio web o con cualquier compra en el Sitio web estarán regidos por las leyes españolas.
    </p>
    <p>Para las controversias y cuestiones que se susciten en relación al contrato serán competentes los Juzgados y Tribunales de su lugar de domicilio.</p>
    <strong>Comentarios, sugerencias y reclamaciones</strong>
    <p>Podrá trasladarnos cualquier comentario, sugerencia, consulta y reclamación que estime oportuna a través de la siguiente dirección de correo electrónico: dimedime@goiko.com</p>
    <p>Una vez formalizada se facilitará al CLIENTE una clave identificativa y un justificante escrito de la misma al correo electrónico facilitado y se tramitará a la mayor brevedad posible.</p>
  </div>
  <BodyFooter />
</template>

<script>
import BodyFooter from '../../components/BodyFooter.vue'
export default {
  name: 'LegalNoti',
  components: {
    BodyFooter
  },
  mounted() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }
}
</script >

<style scoped lang="scss">
  .container {
    font-size: 26px;
    margin: 60px auto 150px auto;
    max-width: 1200px;
    padding: 0 30px;

    #shipping-method {
      font-size: 18px;
      text-transform: uppercase;
      font-weight: 900;
    }
    h1 {
      font-family: $font__headings;
      text-transform: uppercase;
      text-align: center;
    }
    a {
      color: $c-red;
    }
    strong {
      text-transform: uppercase;
    }
  }
</style>